import React, { useEffect, useState } from 'react'
import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField } from '@mui/material';
import { setSelectedCourseBuddyMatchQuestion,selectedCourseBuddyMatchQuestionSelector } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { useGetCourseBuddyMatchQuestionQuery, useDeleteCourseBuddyMatchQuestionOptionMutation,useUpdateCourseBuddyMatchQuestionMutation, useGetAllCourseBuddyMatchQuestionsQuery, useAddCourseBuddyMatchQuestionMutation, BLUE_PRINTS } from 'app/rkt_query/storeApis';
import { useSelector, useDispatch } from 'react-redux';
import DZTextField from '../../../@jumbo/dz-components/textboxes/DZTextField';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar, { NoHideSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { DzNoBackgroundTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import DZDropdownMultiple from '@jumbo/dz-components/dropdowns/DZDropdownMultiple';
import DZDropdown from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DZDeleteDialog from './../../../@jumbo/dz-components/dialog/DZDeleteDialog';
import { dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
const AddCourseBuddyQuestion = ({ onClose, course_id, buddy_match_types }) => {


    const dispatch = useDispatch();
    const [index1, setIndex] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const selectedCourse = useSelector(selectedCourseBuddyMatchQuestionSelector);
    const [deleteBuddyMatchQuestionOption, responseOptionDelete,] = useDeleteCourseBuddyMatchQuestionOptionMutation();
   
    //const { data: res_get_all, isLoading: isLoadingUserCourse, error: errorUserCourse } = useGetCourseBuddyMatchQuestionQuery(id);
    const { data: res_get, isLoading: isLoadingCourse, error: errorCourse } = useGetCourseBuddyMatchQuestionQuery(selectedCourse?.id);
   
    console.log('res_get',res_get);
    console.log('errorCourse',errorCourse)

    const [addBuddyQuestion, responseAdd,] = useAddCourseBuddyMatchQuestionMutation();
    const [updateBuddyQuestion, responseUpdate,] = useUpdateCourseBuddyMatchQuestionMutation();
    const bluePrint = BLUE_PRINTS.CourseBuddyMatchQuestion;
    //const [teamUserState, setUserCourseState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);
    const [courseBuddyMatchQuestionState, setCourseBuddyMatchQuestionState] = useState(null);
    const [courseBuddyMatchQuestionTypesState, setCourseBuddyMatchQuestionTypesState] = useState(null);

    
    const [isTypeMcqs, setIsTypeMcqs] = useState(false);
    const [typeOptions, setTypeOptions] = useState([{ id: "", title: LANGUAGES.defaultObject }])


    const handleClose = () => {
        setOpen(false);
      
    };
      //this code is use to control infinity loops and backend required error. 
      const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
    // const countZero = () => {
    //     setCount(true)
    // }

    // if (responseAdd?.error?.data?.errors && count)
    // {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }

    // if (responseUpdate?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseUpdate?.error?.data?.errors)
    // }
  

    const onTypeChange = (value) => {
        console.log('onTypeChange',value);
        if (value === "mcqs")
        {
            setIsTypeMcqs(true);
        }
        else
        {
            setIsTypeMcqs(false);
        }
    }
    const handleClickOpen = (index) => {
        setOpen(true);
        setIndex(index)
        setOpenSnackbar(false)
    };
    const onAddOption = () => {
        setTypeOptions([...typeOptions, { id: "", title: LANGUAGES.defaultObject }]);
        //console.log('mcqOptionIndex', mcqOptionIndex);
        // let index = mcqOptionIndex + 1;
        //console.log('index+1', index);
        // setMcqOptionindex(index);
        //console.log('mcqOptionIndex+1', mcqOptionIndex);
    }

    const removeOption = (index) => {
        let data = [...typeOptions];
        data.splice(index, 1)
        setTypeOptions(data)
    }
//{ index: 0, title: LANGUAGES.defaultObject }
    const inputOptionValue = (index,event) => { 
        console.log('event', event.target.name);
        // console.log('option', option);
        // console.log('index', index);
        setTypeOptions(typeOptions?.map((element, i) => i === index ? {
            ...element,
            [event?.target?.name]: (
                element?.[event?.target?.name]?.en === undefined ? event?.target?.value
                    : { ...element?.[event?.target?.name], [selectedLanguage]: event?.target?.value }
            )
        } : element));
        // setTypeOptions(typeOptions?.map((element, i) => i === index ? {
            
        //     ...element,
        //     title: { ...element.title, [selectedLanguage]: event.target.value }
        // } : element));
    }

    const addUpdateBudddyMatchQuestions = (action) => {
        const formData = new FormData();
        formData.append('id', res_get?.data?.buddyMatchQuestion?.id);
        formData.append('course_id', courseBuddyMatchQuestionState?.course_id);
        formData.append('type', courseBuddyMatchQuestionState?.type);
        formData.append('title', JSON.stringify(courseBuddyMatchQuestionState?.title));
        formData.append('max_options', courseBuddyMatchQuestionState?.max_options);
        formData.append('is_type_mcqs', isTypeMcqs);
        formData.append('options', JSON.stringify(typeOptions));

        action === "add" ? addBuddyQuestion(formData) : updateBuddyQuestion(formData);
    }
//buddy_match_types
    useEffect(() => {
        console.log('res_get', res_get);
        setCourseBuddyMatchQuestionState(selectedCourse ? res_get?.data?.buddyMatchQuestion : { ...bluePrint, course_id: course_id ?? '' });
        // setCourseBuddyMatchQuestionState(selectedCourse ? res_get?.data?.buddyMatchQuestion : bluePrint);
        // setCourseBuddyMatchQuestionState(x => ({ ...x, course_id: course_id }));
        setCourseBuddyMatchQuestionTypesState(res_get?.data?.BudddyMatchQuestionTypesArr);
        
        setTypeOptions(res_get?.data?.buddyMatchQuestion?.options ?? [{ id: "", title: LANGUAGES.defaultObject }]);
        onTypeChange(res_get?.data?.buddyMatchQuestion?.type);
    }, [res_get])



    const deleteBuddyMatchOption = (id, index) => {

        deleteBuddyMatchQuestionOption(id);
        removeOption(index)
        setOpenSnackbar(true)
        handleClose()
    }


    return (
        <div>
        
            {/* {JSON.stringify(unRegUsers)} */}
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />
         
            {openSnackbar ? <NoHideSnackbar responseAdd={responseOptionDelete?.isSuccess === true ? responseOptionDelete : ''} /> : ""}
            {courseBuddyMatchQuestionState ?
                <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25 }}>

                    <DZDropdown placeholder={'Select Type'} style={{ marginTop: "20px" }} value={courseBuddyMatchQuestionState?.type} data={courseBuddyMatchQuestionTypesState??buddy_match_types}
                        onChange={(e) => { onTypeChange(e.target.value); setCourseBuddyMatchQuestionState(x => ({ ...x, type: e.target.value, })); }} />

                    {/* <DZTextValidation style={{ marginTop: "20px" }} label="Question" variant="outlined" value={courseBuddyMatchQuestionState?.title?.[selectedLanguage]}
                        onChange={(e) => { setCourseBuddyMatchQuestionState(x => ({ ...x, title: { ...x.title, [selectedLanguage]: e.target.value } })) }} />

                    <div style={{ display: "flex" }}>
                        <TextField style={{ display: "inline-block" }} id="outlined-basic" fullWidth label="Question" type="text" inputProps={{ min: 1 }} variant="outlined" noOfRows={1} value={courseBuddyMatchQuestionState?.title?.[selectedLanguage]}
                            onChange={(e) => { setCourseBuddyMatchQuestionState(x => ({ ...x, title: { ...x.title, [selectedLanguage]: e.target.value } })) }} ></TextField>
                        <Button style={{ display: "inline-block", marginLeft: "10px" }} variant="contained" onClick={() => { hideDialog() }}>+</Button>
                    </div> */}

                    {isTypeMcqs   ?
                        <>
                            <div style={{ display: "flex" }}>
                                <TextField style={{ display: "inline-block" }} id="outlined-basic" fullWidth label="Question" type="text" inputProps={{ min: 1 }} variant="outlined" noOfRows={1} value={courseBuddyMatchQuestionState?.title[selectedLanguage]}
                                    onChange={(e) => { setCourseBuddyMatchQuestionState(x => ({ ...x, title: { ...x.title, [selectedLanguage]: e.target.value } })) }} >
                                    </TextField>
                                
                            </div>
                            {typeOptions.map((option, index) => (
                                <div style={{ display: "flex" }}>

                                {index1 === index ? <DZDeleteDialog title={"Are you sure about deleting this Option?"}
                                                                            description={" You won't be able to recover this option later"}
                                                                            openBox={open}
                                                                            handleClose={()=>handleClose()}
                                                                            handleDeleteFunctionCall={()=>deleteBuddyMatchOption(option?.id, index)}

                                        /> : ""}
                                <input type="hidden" name='id' value={option?.id} onChange={event => inputOptionValue(index, event)} />
                                    <DZTextField label={`Option-${index + 1}`} name='title' variant="outlined" noOfRows={1} value={option?.title?.[selectedLanguage]}
                                    onChange={(e) => { inputOptionValue(index,e) }}/>
       {
                                             option?.id ?

                                                    <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => handleClickOpen(index)} >
                                                        Delete
                                                    </Button>

                                                    : <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ color: 'red' }} onClick={() => removeOption(index)} >
                                                        Remove
                                                    </Button>
                                            }
                                    {/* <Button style={{ display: "inline-block", marginLeft: "5px" }} variant="outlined" startIcon={<DeleteIcon />} sx={{ color: 'red' }} onClick={() => removeOption(index)} >
                                        Remove
                                    </Button> */}
                                </div>
                            ))}
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', }} >
                                <Button size="small" variant="outlined" sx={{ mt: '10px' }} startIcon={<ControlPointOutlinedIcon />} onClick={() => onAddOption()} >
                                    Add Options
                                </Button>
                            </div>
                        </>
                        : <TextField style={{ display: "inline-block" }} id="outlined-basic" fullWidth label="Question" type="text" inputProps={{ min: 1 }} variant="outlined" noOfRows={1} value={courseBuddyMatchQuestionState?.title[selectedLanguage]}
                            onChange={(e) => { setCourseBuddyMatchQuestionState(x => ({ ...x, title: { ...x.title, [selectedLanguage]: e.target.value } })) }} >
                        </TextField>
                    }

                    <TextField id="outlined-basic" fullWidth label="Max Options" type="number" inputProps={{ min: 1 }} variant="outlined" noOfRows={1} value={courseBuddyMatchQuestionState?.max_options}
                        onChange={(e) => { setCourseBuddyMatchQuestionState(x => ({ ...x, max_options: e.target.value })) }} ></TextField>


<div style={dialogButtonsStyle}>

<Button variant="outlined" onClick={() => { hideDialog();  dispatch(setSelectedCourseBuddyMatchQuestion(null)); }}>Cancel</Button>
{
    selectedCourse ? <Button variant="contained" onClick={() => { addUpdateBudddyMatchQuestions(); setShowBackDrop(true); resetCount();  }}>Update</Button>
        : <Button variant="contained" onClick={() => { addUpdateBudddyMatchQuestions("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedCourseBuddyMatchQuestion(null)); }}>Add</Button>
}
</div>



                </div>
                : <div>Loading....</div>
            }

        </div>
    )
}

export default AddCourseBuddyQuestion