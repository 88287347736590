import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField, Fab, Box } from '@mui/material';
import { selectedTopicSelector, setSelectedTopic, selectedModuleSelector, setSelectedModule, selectedCourseSelector, } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddTopicMutation, useGetTopicQuery, useUpdateTopicMutation,  useGetAllModulesQuery, useGetModuleQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField from '../../../@jumbo/dz-components/textboxes/DZTextField';
import ComboBox from '../../../@jumbo/dz-components/autocomplete-cb/ComboBox';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { DzNoBackgroundTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';

const AppUpdateTopic = ({ onClose, selectedId, moduleRow, module }) => {

  console.log("Topic row", selectedId, module, moduleRow)
  const dispatch = useDispatch();
  const selectedCourse = useSelector(selectedCourseSelector);
  const selectedTopic = useSelector(selectedTopicSelector);
  const selectedModule = useSelector(selectedModuleSelector);
  const { data: res_get_all, isLoading: isLoadingModules, error: errorModules } = useGetAllModulesQuery();
  const { data: res_get, isLoading: isLoadingTopic, error: errorTopic } = useGetTopicQuery(selectedId ?? selectedTopic?.id);
  const { data: res_get1, isLoading: isLoadingModule, error: errorModule } = useGetModuleQuery(module?.id ?? selectedModule?.id);

  const [updateTopic, responseUpdate,] = useUpdateTopicMutation();
  const [addTopic, responseAdd,] = useAddTopicMutation();
  const bluePrint = BLUE_PRINTS.topic;
  const [topicState, setTopicState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  // const [error, setError] = useState([]);
  // const [count, setCount] = useState(true);
  const [disable, setDisabled] = useState([true]);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.topic?.image);

  const addUpdateTopicData = (action) => {
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', res_get?.data?.topic?.id);
    formData.append('name', JSON.stringify(topicState.name));
    formData.append('module_id', topicState?.module_id);
    formData.append('description', JSON.stringify(topicState.description));
    action === "add" ? addTopic(formData) : updateTopic(formData);

  }
  //this code is use to control infinity loops and backend required error. 
  const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
  // const countZero = () => {
  //   setCount(true)
  // }
  // console.log(responseUpdate?.error?.data?.errors)
  // if (responseAdd?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseAdd?.error?.data?.errors)
  // }

  // if (responseUpdate?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseUpdate?.error?.data?.errors)
  // }

  console.log(responseAdd?.isSuccess)
  useEffect(() => {

    res_get?.data?.topic?.image && setSelectedFile(res_get?.data?.topic?.image)
    // console.log("selectedCourse", selectedCourse)
    // console.log('transformed result :: ', res_get)
    // setTopicState((selectedTopic || selectedId) ? {...res_get?.data?.topic, course_id: selectedCourse?.id}  : { ...bluePrint, module_id: res_get1?.data?.module?.id ?? '' });
    setTopicState((selectedTopic || selectedId) ? { ...res_get?.data?.topic, course_id: selectedCourse?.id } : { ...bluePrint, module_id: res_get1?.data?.module?.id ?? '' });
    // dispatch(setSelectedModule(res_get1?.data?.module?.id))
    // console.log("res get 1", res_get)
    setDisabled((selectedTopic || selectedId) ? false : true);
    if (res_get1?.data?.module?.id) {
      //dispatch(dispatch(setSelectedTopic(null)));
      console.log("Checking")
    }
    // console.log('topic', topicState);
  }, [res_get, res_get1])

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {

      setSelectedFile(e.target.files[0]);
      console.log("Checking", selectedFile)
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);

    }



  };

  const modules = res_get_all?.data?.modules.map((module) => (BLUE_PRINTS.course.translate(module, selectedLanguage)))

  const moduleFields = [
    { label: 'Name ', key: 'name', multilingual: true, errorKey:["0"] },
    { label: 'Description', key: 'description', multilingual: true, rows:3 },
  ];
  
  const renderTopicFields = () => {
    return moduleFields.map((field) => (
      <DZTextValidation
        key={field.label}
        label={field.label}
        variant="outlined"
        noOfRows={field?.rows}
        value={field.multilingual ? topicState?.[field.key]?.[selectedLanguage] : topicState?.[field.key]}
        error={error?.[field?.errorKey]}
        onChange={(e) => {
          if (field.multilingual) {
            const updatedData = {
              ...topicState[field.key],
              [selectedLanguage]: e.target.value,
            };
            setTopicState((prevState) => ({
              ...prevState,
              [field.key]: updatedData,
            }));
          } else {
            setTopicState((prevState) => ({
              ...prevState,
              [field.key]: e.target.value,
            }));
          }
        }}
      />
    ));
  };

  return (
    <div>

      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
      <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />


      {topicState ?
        <div style={addUpdateDivStyleGlobal}>
          {/* <LanguageSwitcher /> */}
          {/* <DzNoBackgroundTranslatable title={"Module"} children={res_get1?.data?.module?.name} /> */}
          {imagePreview ? (
            <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
          ) : (

            <DZImageCircle image={selectedFile} link={Config.AWSTopicOriginalImg} style={{
              height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            />

          )}

          <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
            <label htmlFor="upload-photo">
              <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>

            </label>
          </Box>
          {module?.id ? <DzNoBackgroundTranslatable title={"Module"} children={res_get1?.data?.module?.name} /> : <DzNoBackgroundTranslatable title={"Module"} children={res_get?.data?.topic?.module?.name} />}
          {/* <DZDropdown error={error?.module_id} disabled={disable} placeholder={'Select Module'} value={topicState?.module_id} data={modules}
            onChange={(e) => { setTopicState(x => ({ ...x, module_id: e.target.value, })) }} /> */}
            {renderTopicFields()}
          {/* <DZTextValidation error={error?.["0"]} label="Name" variant="outlined" value={topicState?.name?.[selectedLanguage]}
            onChange={(e) => { setTopicState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })) }}
          />
          <DZTextField label="Description" variant="outlined" noOfRows={3} value={topicState?.description?.[selectedLanguage]}
            onChange={(e) => { setTopicState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
          /> */}
          <div style={dialogButtonsStyle} >
            <Button variant="outlined" onClick={() => { hideDialog(); dispatch(setSelectedTopic(null)); }}>Cancel</Button>
            {
              (selectedTopic || selectedId) ? <Button variant="contained" onClick={() => { addUpdateTopicData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                : <Button variant="contained" onClick={() => { addUpdateTopicData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedTopic(null)); }}>Add</Button>
            }

          </div>
          {/* dispatch(setSelectedTopic(null)); */}
          {/* <div style={{ width: '10%' }}>{JSON.stringify(topicState)}</div> */}
        </div>
        : <div>Loading....</div>
      }
      {/* {!selectedTopic &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}


    </div>
  )
}

export default AppUpdateTopic