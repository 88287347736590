import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllCoursesQuery, BLUE_PRINTS, useGetCourseQuery, useDeleteCourseMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector,TITLE } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Course from './AddUpdateCourse';
import { setSelectedCourse } from 'app/rkt_query/SelectedStuffReducer';
import ViewCourse from './ViewCourse';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import Module from './../module/AddUpdateModule';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { Box } from '@mui/material';
import { IconButton, Button, Typography } from '@mui/material';
import CoursesTeamsList from '../courses-teams';
import useFetchCompany from '@jumbo/dz-components/custom-hooks/useFetchCompany';



const CourseList = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteCourse, responseDelete] = useDeleteCourseMutation();
  const { data: res_get_all, isLoading: isLoadingCourses, error: errorCourses } = useGetAllCoursesQuery();
  const res_get_all_companies=useFetchCompany();

  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.course
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCourse(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCourse(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCourse(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedCourse(row)); }
  bluePrint.handleDelete = (id) => { deleteCourse(id); }

  const [companiesList, setCompaniesList] = useState();


  const [courses, setCourses] = useState(res_get_all?.data?.courses)
  useEffect(() => {
  
    var sources = courses && courses.filter(function (item) {
      if (!item?.modules[0]) {
        return false; // skip
      }
      return true;
    }).map(function (item) { return item?.modules[0] });
    console.log("Sources", sources);
  }, [res_get_all])

  const setDDList = (data) => {
    setCompaniesList(data)
  }
  const handleBack = () => {

    //console.log("in navigation",   res_get?.data?.learning_journey?.topic?.module?.course)
    navigate("/companies", {

    });
  }
  //const check = modules.map((item) => item?.modules[0] ? item?.modules : null)

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"Courses"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd={TITLE.COURSE}
              relationalObject={location?.state?.relationalObject}
              listData={res_get_all_companies?.data?.companies}
              companyName={location?.state?.company}
              translate={true}
              ddTranslate={false}
              data={res_get_all?.data?.courses}
              bluePrint={bluePrint} ViewModel={() => <ViewCourse />}
              ModalContent={() => <Course onClose={hideDialog} />}
              heirarchyId={location?.state?.obj?.id}
              RelationalModalContent={() => <Module onClose={hideDialog} />}
              relationalTitle="Module"
              ddName="Companies"
              showAddButton={true}
              showButton3={false}
              viewTitle3="Assign To Teams"
              showButton5={false}
              viewTitle5="Buddy Questions"
              isBtnTitleAdd5={true}
            />
            {/* <DataTable btnTitleAdd="course" data={res_get_all?.data?.courses} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowCourse/> : <Course onClose={hideDialog} />} /> */}
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default CourseList