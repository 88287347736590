import React from 'react'
import { TextField } from '@mui/material';

const DZTextField = ({ label, noOfRows = 1, sx = '', error, variant = "outlined",name, value = "", type = "text", onChange = (e) => { } }) => {
  return (
    <TextField fullWidth id="outlined-basic" sx={sx} name={name} type={type} label={label} {...(error && { error: true, helperText: error })} variant={variant} value={value} onChange={onChange} multiline maxRows={3} minRows={noOfRows} />
  )
}
// fullWidth
export default DZTextField
export const DZDateField = ({ label, noOfRows = 1, sx = '', error, variant = "outlined", name, value = "", type = "date", onChange = (e) => { } }) => {
  return (
    <TextField fullWidth id="outlined-basic" sx={sx} type={type} name={name} label={label} {...(error && { error: true, helperText: error })} variant={variant} value={value} onChange={onChange} multiline maxRows={3} minRows={noOfRows} />
  )
}