import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
// import { useGetAllTeamsQuery, BLUE_PRINTS, useGetTeamQuery, useDeleteTeamMutation } from 'app/rkt_query/storeApis';
import { useGetCourseBuddyMatchQuestionQuery, useDeleteCourseBuddyMatchQuestionMutation, useGetAllCourseBuddyMatchQuestionsQuery, useAddCourseBuddyMatchQuestionMutation, useGetCourseQuery, BLUE_PRINTS } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import AddCourseBuddyQuestion from './AddCourseBuddyQuestion';
import AddUpdate from '../team/AddUpdate';

import { setSelectedCourseBuddyMatchQuestion } from 'app/rkt_query/SelectedStuffReducer';
//import ShowCompany from './ShowCompany';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { reload } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';



import { AddOutlined, ControlPointDuplicateRounded, PhotoCamera } from '@mui/icons-material';
import ShowCompany from '../company/ShowCompany';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import ViewCourse from '../course/ViewCourse';
import ShowCourseBuddyQuestion from './ShowCourseBuddyQuestion';
import DzTable from '@jumbo/dz-components/table/DzTable';

const CourseBuddyMatchQuestionList = () => {

    const location = useLocation();
    const navigate = useNavigate();

    //console.log("from team", location?.state?.relationalObject);
    //console.log("from compnay id", location?.state?.company_id);

    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteTeam, responseDelete] = useDeleteCourseBuddyMatchQuestionMutation();
    const { data: res_get_course } = useGetCourseQuery(location?.state?.relationalObject?.id);
    const { data: res_get_all, isLoading: isLoadingTeams, error: errorTeams } = useGetAllCourseBuddyMatchQuestionsQuery(location?.state?.relationalObject?.id);
    
   
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const [courseBuddyMatchQuestionTypesState, setCourseBuddyMatchQuestionTypesState] = useState(null);
    const [courseState, setCourseState] = useState(null);

    //console.log("from team ass", res_get_all);
    const [showMode, setShowMode] = useState(false);

    const bluePrint = BLUE_PRINTS.CourseBuddyMatchQuestion;
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCourseBuddyMatchQuestion(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCourseBuddyMatchQuestion(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCourseBuddyMatchQuestion(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedCourseBuddyMatchQuestion(row)); }
    bluePrint.handleDelete = (id) => { deleteTeam(id); }
    const size = "xl";

    useEffect(() => {
        setCourseBuddyMatchQuestionTypesState(res_get_all?.data?.BudddyMatchQuestionTypesArr);
        setCourseState(res_get_course?.data?.course);
 
    }, [res_get_all])

    const showAddDialog = () => {
     
        showDialog({
            title: "Add Buddy Match Question",
            content: <AddCourseBuddyQuestion course_id={location?.state?.relationalObject?.id} buddy_match_types={res_get_all?.data?.BudddyMatchQuestionTypesArr} />
        });
    }

    const handleCourseView = () => {
        showDialog({
            title: 'Course Detail',
            size,
            content: <ViewCourse selectId={location?.state?.relationalObject?.id} />
        });
    }
    const handleHideDialog = () => {
        hideDialog();
    }
    const handleCompanyTeamAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
   
    const columns = ['Id', 'Course'];
    const rows = [
      { id: courseState?.id, name: courseState?.name[selectedLanguage] },
    ];
    const buttons = [
        { label: 'Add Buddy Match Question', align:'center',icon: <AddOutlined />, onClick: showAddDialog },
        { label: 'Action',align:'right', icon: <VisibilityOutlinedIcon />, onClick: handleCourseView },
      
      ];

    return (
        <>
          
            <span>{showMode}</span>
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

          
            <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Course"}  />

            
            <br />
            <JumboDemoCard
                title="Course Buddy Match Questions"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        btnTitleAdd="Course Buddy Match Question"
                        data={res_get_all?.data?.buddyMatchQuestions}
                        bluePrint={bluePrint}
                        ModalContent={() => <AddCourseBuddyQuestion onClose={hideDialog} />}
                        ViewModel={() => <ShowCourseBuddyQuestion  modalTitle="Course Buddy Match Question Detail"  />}
                 
                    />
                </div>

               

            </JumboDemoCard>
        </>
    )
}

export default CourseBuddyMatchQuestionList
