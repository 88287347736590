import React, { useState } from 'react';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { Table, TableBody, TableHead, TableCell, TableRow, } from "@mui/material";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import TableContainer from "@mui/material/TableContainer";
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useSelector } from 'react-redux';
import OrderingData from './OrderingData';
import { BLUE_PRINTS, useOrderActivityMutation, useOrderModuleMutation, useOrderTopicMutation, useOrderTopicExerciseMutation, useOrderLearningJourneyMutation,useOrderTopicSurveyMutation } from 'app/rkt_query/storeApis';
import { Button,  } from '@mui/material';
import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';

const Data = SortableContainer(({ datalists, table,modalBluePrint }) => {
    var bluePrint = modalBluePrint;
    switch (table) {
        case 'activity':
            bluePrint = BLUE_PRINTS.activity;
            break;
        case 'module':
            bluePrint = BLUE_PRINTS.module;
            break;
        case 'topic':
            bluePrint = BLUE_PRINTS.topic;
            break;
        case 'learningJourney':
            bluePrint = BLUE_PRINTS.learningJourney;
            break;
        case 'TopicExercise':
            bluePrint = BLUE_PRINTS.topicExercise;
            break;
        case 'TopicSurvey':
            bluePrint = BLUE_PRINTS.topicSurvey;
            break;
        default:
            console.error('No Order Match');
            break;
    }
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { showDialog, hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [updateOrderActivities, responseUpdateOrder,] = useOrderActivityMutation();
    const [updateOrderModules, responseUpdateModuleOrder,] = useOrderModuleMutation();
    const [updateOrderTopics, responseUpdateTopicOrder,] = useOrderTopicMutation();
    const [updateOrderTopicExercises, responseUpdateTopicExercisesOrder,] = useOrderTopicExerciseMutation();
    const [updateOrderLearningJourney, responseUpdateLearningJourneyOrder,] = useOrderLearningJourneyMutation();
    const [updateOrderTopicSurvey, responseUpdateTopicSurveyOrder,] = useOrderTopicSurveyMutation();


    const updateOrders = (action) => {

        const formData = new FormData();

        formData.append('orders', JSON.stringify(datalists));
        switch (action) {
            case 'activity':
                updateOrderActivities(formData);
                break;
            case 'module':
                updateOrderModules(formData);
                break;
            case 'topic':
                updateOrderTopics(formData);
                break;
            case 'learningJourney':
                updateOrderLearningJourney(formData);
                break;
            case 'TopicExercise':
                updateOrderTopicExercises(formData);
                break;
            case 'TopicSurvey':
                updateOrderTopicSurvey(formData);
                break;
            default:
                console.error('No Order Match');
                break;
        }
    }
    return (
        <TableContainer>
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateOrder} />}
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateModuleOrder} />}
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateTopicOrder} />}
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateLearningJourneyOrder} />}
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateTopicExercisesOrder} />}
            {showBackDrop && <SimpleBackdrop responseAdd={responseUpdateTopicSurveyOrder} />}
            <Snackbar responseAdd={responseUpdateOrder?.isSuccess === true && responseUpdateOrder} />
            <Snackbar responseAdd={responseUpdateModuleOrder?.isSuccess === true && responseUpdateModuleOrder} />
            <Snackbar responseAdd={responseUpdateTopicOrder?.isSuccess === true && responseUpdateTopicOrder} />
            <Snackbar responseAdd={responseUpdateTopicExercisesOrder?.isSuccess === true && responseUpdateTopicExercisesOrder} />
            <Snackbar responseAdd={responseUpdateLearningJourneyOrder?.isSuccess === true && responseUpdateLearningJourneyOrder} />
            <Snackbar responseAdd={responseUpdateTopicSurveyOrder?.isSuccess === true && responseUpdateTopicSurveyOrder} />
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow >

                        <TableCell width={"3%"} sx={{ pl: 3 }}>
                            Drag
                        </TableCell>
                        <TableCell width={"10%"} sx={{ pl: 3 }}>
                            Id
                        </TableCell>
                        <TableCell width={"20%"}>
                            Name
                        </TableCell>
                        <TableCell width={"30%"}>
                            Description
                        </TableCell>
                        <TableCell width={"15%"}>
                            Order
                        </TableCell>

                    </TableRow>

                </TableHead>
                <TableBody>
                    {/* rows={rows?.map((obj) => bluePrint.translate(obj, selectedLanguage))} */}
                    {datalists?.map((item, index) => (
                        <OrderingData key={index} index={index} contact={bluePrint.translate(item, selectedLanguage)} />
                    ))}
                </TableBody>
            </Table>
            <div style={dialogButtonsStyle}>
                <Button variant="outlined" onClick={() => { hideDialog(); }}>Cancel</Button>
                <Button variant="contained" onClick={() => { updateOrders(table); setShowBackDrop(true); }}>Save</Button>
            </div>
        </TableContainer>
    );
});

const Ordering = ({ dataList, table }) => {

    const [datalist, setDataList] = useState(dataList);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setDataList(arrayMove(datalist, oldIndex, newIndex));
    };
    console.log(datalist, 'datalist')
    return (
        <JumboDemoCard
            title={"Drag and drop"}

            wrapperSx={{
                p: 0,
                backgroundColor: 'background.paper',

                '&:last-child': {
                    pb: 0
                }
            }}
        >
            <Data datalists={datalist} table={table} onSortEnd={onSortEnd} useDragHandle={true} />
        </JumboDemoCard>
    );
};

export default Ordering;
