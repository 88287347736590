import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedAdminSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetAdminQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

const ViewAdmin = ({ selectId }) => {
    const selectedAdmin = useSelector(selectedAdminSelector);
    const [AdminId, setAdminId] = useState(selectId);
    console.log(selectId)
    useEffect(() => {
        setAdminId(selectedAdmin ? selectedAdmin?.id : selectId)
    }, [])
    const { data: res_get, isLoading: isLoadingAdmin, error: errorAdmin } = useGetAdminQuery(AdminId);
    
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const bluePrint = BLUE_PRINTS.admin;
    const { hideDialog } = useJumboDialog();
    console.log("selected admin", res_get?.data?.admin);
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorAdmin ? <div>{JSON.stringify(errorAdmin)}</div>
                    : isLoadingAdmin ? <div>Loading Single Admin.....</div>
                        : res_get?.data?.admin ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                        <label style={{ fontSize: "1em", fontWeight: 500 }}>Name</label>
                                        <label style={{ fontSize: "1em" }}>{res_get?.data?.admin?.name}</label>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                    <label style={{ fontSize: "1em", fontWeight: 500 }}>Email</label>
                                    <label style={{ fontSize: "1em" }}>{res_get?.data?.admin?.email}</label>
                                </div>
                                
                                <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                    <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                </div>
                                {/* {JSON.stringify(bluePrint.translate(res_get?.data?.admin, selectedLanguage))}
                 */}
                            </div>
                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewAdmin