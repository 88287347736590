import React, { useEffect, useState } from 'react'
import { selectedLearningJourneySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetLearningJourneyQuery } from 'app/rkt_query/storeApis';
import { useSelector } from 'react-redux';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
const ShowLearningJourney = ({ learning_journey_id }) => {
    const selectedLearningJourney = useSelector(selectedLearningJourneySelector);

console.warn(selectedLearningJourney?.id);
    const [selectedId, setSelectedId] = useState(selectedLearningJourney?.id)
    const { data: res_get, isLoading: isLoadingLearningJourney, error: errorLearningJourney } = useGetLearningJourneyQuery(selectedId);
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.learning_journey?.image);
    useEffect(() => {
        res_get?.data?.learning_journey?.image && setSelectedFile(res_get?.data?.learning_journey?.image)
        setSelectedId(selectedLearningJourney ? selectedLearningJourney?.id : selectedId)
    }, [res_get])
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const learningJourneyArray =
        [
            { label: 'Topic ', data: res_get?.data?.learning_journey?.topic?.name[selectedLanguage] },
            { label: 'Name', data: res_get?.data?.learning_journey?.name[selectedLanguage] },
            { label: 'Duration', data: res_get?.data?.learning_journey?.duration },
            { label: 'Description', data: res_get?.data?.learning_journey?.description[selectedLanguage] },
        ]

    return (
        <div>
            {
                errorLearningJourney ? <div>{JSON.stringify(errorLearningJourney)}</div>
                    : isLoadingLearningJourney ? <div>Loading Single LearningJourney.....</div>
                        : res_get?.data?.learning_journey ? <DZGenericView obj={learningJourneyArray} imagePath={Config.AWSLearningJourneyOriginalImg} image={selectedFile} checkImage={true} closeBtn={true} /> : <div>Bad Code</div>
            }
        </div>
    )
}

export default ShowLearningJourney