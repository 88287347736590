import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React from 'react'

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { Button } from '@mui/material';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { useGetAllCompanyUsersQuery, BLUE_PRINTS, useGetUserQuery, useDeleteUserMutation } from 'app/rkt_query/storeApis';
import { TITLE } from 'app/rkt_query/SettingsReducer';



const CompanyUserLists = ({company}) => {
    const { data: res_get_all, isLoading: isLoadingUsers, error: errorUsers } = useGetAllCompanyUsersQuery(company?.id);

    const bluePrint = BLUE_PRINTS.user
 const { showDialog, hideDialog } = useJumboDialog();

    
    return (
        <>
      
         
       
            <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>
            </div>
                                     
        
           
            <JumboDemoCard
                // title="Company User Lists"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        btnTitleAdd={TITLE.COMPANY_USER}
                        data={res_get_all?.data?.users}
                        bluePrint={bluePrint}
                        userView={true}
                        onlyDeleteIcon={true}
                        // actionColumn={true}
                    />

                </div>
              
            </JumboDemoCard>
         
        </>
    )
}

export default CompanyUserLists