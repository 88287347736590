import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState,useRef } from 'react'
import { useGetTeamUsersQuery, useDeleteCourseTeamMutation, useGetAllCourseTeamsQuery,useAddCourseTeamMutation, BLUE_PRINTS } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector, TITLE } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar, { NoHideSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { reload } from 'firebase/auth';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
//import Course from '../course/AddUpdateCourse';
import { setSelectedCourseTeam } from 'app/rkt_query/SelectedStuffReducer';
import AddCoursesTeams from './AddCoursesTeams';

import { Box, IconButton,TextField, Button, Typography } from '@mui/material';
import ViewCourse from '../course/ViewCourse';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { AddOutlined, ControlPointDuplicateRounded, PhotoCamera } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DZDropdownMultiple from '@jumbo/dz-components/dropdowns/DZDropdownMultiple';
import DzTable from '@jumbo/dz-components/table/DzTable';




const CoursesTeamsList = ({course}) => {


  
console.log('navigate',course)
    const [open, setOpen] = React.useState(false);
    const [courseTeamState, setCourseTeamState] = useState(null);
    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteCourseTeam, responseDelete] = useDeleteCourseTeamMutation();
    const { data: res_get_all, isLoading: isLoadingTeamUser, error: errorTeamUser } = useGetAllCourseTeamsQuery(course?.id);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    console.log('useGetAllCourseUsersQuery: ', res_get_all);
    const [showMode, setShowMode] = useState(false);

    const handleHideDialog = () => {
        hideDialog();

    }
    const bluePrint = BLUE_PRINTS.courseTeam
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCourseTeam(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCourseTeam(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCourseTeam(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedCourseTeam(row)); }
    bluePrint.handleDelete = (id) => { deleteCourseTeam(id); }
    const handleTeamUserAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
    const [addCourseTeam, responseAdd,] = useAddCourseTeamMutation();

   const addCourseTeams=(courseTeamState)=>{
    addCourseTeam(courseTeamState);
    setOpenSnackbar(true)
    handleClose()
   }
    const size = "xl";
 
    const handleClickOpen = () => {
        setOpen(true);
        setOpenSnackbar(false)
    };
    const dialogRef = useRef();
    useEffect(() => {
        
        setCourseTeamState({ ...bluePrint, course_id:course?.id });
        
    }, [res_get_all])
    

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick") 
        return;
 setOpen(false);

    };

    const courseTeams = res_get_all?.data?.course_teams?.teams?.map((e) => ({ ...courseTeamState, team_id: e?.id }))
    const unRegTeams = res_get_all?.data?.course_company_teams?.teams?.filter(u => !courseTeams.some(c => (c.team_id == u.id)));
 
    const columns = ['Id', 'Course', 'Order Type'];
    const rows = [
      { id: course?.id, name: course?.name, order_type: course?.order_type },
    ];
    const buttons = [
        { label: 'Assign Teams To Course',align:'center', icon: <AddOutlined />, onClick: handleClickOpen },
      
      ];
    return (
        <>
      
            {/* {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />} */}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            {openSnackbar ? <NoHideSnackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : ''} /> : ""}
            <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>
            </div>
                                       <Dialog 
                                    
                                                fullWidth={true}
                                                maxWidth="lg"
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                               
                                            >
                                             <DialogTitle>Assign Teams To Course</DialogTitle>
                                             <DialogContent style={{marginTop:'20px'}}>
                                             <DZDropdownMultiple style={{marginTop:"20px"}}  placeholder={'Select Team'} value={courseTeamState?.team_id} data={unRegTeams} 
                                             onChange={(e) => { setCourseTeamState(x => ({ ...x, team_id: e.target.value, })) }} />
                                              {/* {<AddCoursesTeams/>} */}
                                              {res_get_all?.data?.course_teams?.order_type === "order-seq" ?
                                              <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                                               <TextField label="Start Date" type="date" InputLabelProps={{ shrink: true }} variant="outlined" value={courseTeamState?.date}
                                                onChange={(e) => { setCourseTeamState(x => ({ ...x, date: e.target.value })) }}
                                                      />
                                                         </Div>
                        </Grid>
                                                       : ''}
                                              </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button variant='contained' onClick={() => addCourseTeams(courseTeamState)} autoFocus>
                                                        Add
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                         
                                                <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Course"}  />
                                           
                                            
           
            <br />
           
            <JumboDemoCard
                title="Course Teams"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        btnTitleAdd={TITLE.COURSE_TEAMS}
                        relationalId={course?.id}
                        onlyDeleteIcon={true}
                        data={res_get_all?.data?.course_teams_date}
                        bluePrint={bluePrint}
                        ModalContent={() => <AddCoursesTeams onClose={hideDialog} />}
                        showButton3={true}
                        showButton2={false}
                        viewTitle2="Generate Buddies Randomly"
                        showButton1={false}
                        viewTitle1="Generate Buddies"
                    />

                </div>
              
            </JumboDemoCard>
         
        </>
    )
}

export default CoursesTeamsList