/* eslint-disable no-lone-blocks */
import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { Button, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetAllModulesQuery, BLUE_PRINTS, useGetModuleQuery, useDeleteModuleMutation, useDeleteTopicMutation, useDeleteLearningJourneyMutation,
  useGetAllCoursesQuery, useGetAllTopicsQuery, useGetAllLearningJourneysQuery,
   useGetCompanyQuery, useGetCourseQuery
} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Module from './AddUpdateModule';
import { setSelectedModule, setSelectedTopic,setSelectedLearningJourney, selectedCourseSelector, setSelectedCourse, selectedModuleSelector } from 'app/rkt_query/SelectedStuffReducer';
import ViewModule from './ViewModule';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import AppUpdateTopic from './../topic/AppUpdateTopic';
import { Box, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ComboBox from '@jumbo/dz-components/autocomplete-cb/ComboBox';
import ShowCompany from '../company/ShowCompany';
import ViewCourse from './../course/ViewCourse';
import LearningJourney from '../learning-journey/LearningJourney';
import ViewTopic from './../topic/ViewTopic';
import { AddOutlined } from '@mui/icons-material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Ordering from './../../../@jumbo/dz-components/drag-drop-ordering/Ordering';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';
import ShowLearningJourney from './../learning-journey/ShowLearningJourney';
import DzTable from '@jumbo/dz-components/table/DzTable';


const ModuleList = () => {

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteModule, responseDelete] = useDeleteModuleMutation();
  const [deleteTopic, responseDeleteTopic] = useDeleteTopicMutation();
  const [deleteLearningJourney, responseDeleteLearningJourney] = useDeleteLearningJourneyMutation();

  const { data: course, isLoading: isLoadingCourse, error: errorCourse } = useGetCourseQuery(location?.state?.relationalObject && location?.state?.relationalObject?.id);


  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.module
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedModule(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedModule(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedModule(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedModule(row)); }
  bluePrint.handleDelete = (id) => { deleteModule(id); }


  const bluePrintTopic = BLUE_PRINTS.topic
  bluePrintTopic.addRecord = () => { setShowMode(false); dispatch(setSelectedTopic(null)); }
  bluePrintTopic.showRecord = (row) => { setShowMode(true); dispatch(setSelectedTopic(row)); }
  bluePrintTopic.editRecord = (row) => { setShowMode(false); dispatch(setSelectedTopic(row)); }
  bluePrintTopic.deleteRecord = (row) => { dispatch(setSelectedTopic(row)); }
  bluePrintTopic.handleDelete = (id) => { deleteTopic(id); }

  const bluePrintLearningJourney = BLUE_PRINTS.learningJourney
  bluePrintLearningJourney.addRecord = () => { setShowMode(false); dispatch(setSelectedLearningJourney(null)); }
  bluePrintLearningJourney.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLearningJourney(row)); }
  bluePrintLearningJourney.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLearningJourney(row)); }
  bluePrintLearningJourney.deleteRecord = (row) => { dispatch(setSelectedLearningJourney(row)); }
  bluePrintLearningJourney.handleDelete = (id) => { deleteLearningJourney(id); }

  const [learningJourDD, setLearningJourDD] = useState(null);
  const [lCourse, setLCourse] = useState(null);
  const [allModules, setAllModules] = useState(null);
  const [allTopics, setAllTopics] = useState(null);
  const [selectedModuleDD, setSelectedModuleDD] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState(null);
  const [selectedLearningJouneys, setSelectedLearningJouneys] = useState(null);
  const [count, setCount] = useState(false);
  const [input, setInput] = useState(null)
  const [inputTopic, setInputTopic] = useState(null);

  const relationalObj = location?.state?.relationalObject;

  console.log(" from learningJourney", location?.state?.module)
  console.log(" from learningJourney", location?.state?.module ? true : false)

  useEffect(() => {

    //filtering data when click on topic view and navigating from activity screen to back module screen(where course, modules and topics code)
    let topicDat = location?.state?.relationalObjectTopic;
    let obj = course?.data && course?.data?.course?.modules;
    let filterredTopics = (obj && topicDat) && obj.filter((item) => item?.id == topicDat?.module_id)
    console.log("chekkkkkk", filterredTopics)

    dispatch(setSelectedCourse(course?.data?.course))
    setLCourse(course?.data?.course)
    setAllModules(course?.data?.course?.modules)
    setAllTopics(course?.data?.course?.modules?.topics);
    setSelectedModuleDD(course?.data?.course?.modules?.[0]);
    setSelectedTopics(filterredTopics?.[0] ? filterredTopics?.[0]?.topics : course?.data?.course?.modules?.[0]?.topics);
    setSelectedLearningJouneys(filterredTopics?.[0] ? filterredTopics?.[0]?.topics?.[0]?.learning_journeys : course?.data?.course?.modules?.[0]?.topics?.[0]?.learning_journeys);

  }, [course])



  const updateSelectedModule = (obj) => {

 

    if (obj) {
   
      const tModule = allModules.find((module) => module?.id == obj?.id);
      setInput(obj?.name)
      setSelectedTopics(tModule?.topics);
      setSelectedModuleDD(tModule)
      setInputTopic(null)
      // select first Learning journey of topic if any

      // select first Learning journey of topic if any
      setSelectedLearningJouneys(tModule?.topics?.[0]?.learning_journeys);
   
    }
    else {
      setInput(null)
      setInputTopic(null)
      setSelectedTopics(course?.data?.course?.modules?.[0]?.topics);
      setSelectedModuleDD(course?.data?.course?.modules?.[0]);
      setSelectedLearningJouneys(course?.data?.course?.modules?.[0]?.topics?.[0]?.learning_journeys);

    
    }

  }

  const updateSelectedTopic = (obj) => {


    if (obj) {
      // console.log("input topic on change", obj)
      setInputTopic(obj?.name)
      const tTopic = selectedModuleDD?.topics.find((topic) => topic.id == obj?.id);
      setSelectedLearningJouneys(tTopic?.learning_journeys);
      // select first Learning journey of topic if any
      // select first Learning journey of topic if any
    }
    else {
  
      setInputTopic(null)
      selectedTopics?.[0] && setSelectedLearningJouneys(course?.data?.course?.modules?.[0]?.topics?.[0]?.learning_journeys);
    }

  }

  const size = "xl";
  const handleCompanyView = () => {
    showDialog({
      title: 'Company Detail',
      size,
      content: <ShowCompany company={course?.data?.course?.company?.id} modalTitle="Company Detail" />
    });
  }

  const handleCourseView = () => {
    {
      showDialog({
        title: 'Course Detail',
        size,
        content: <ViewCourse selectId={relationalObj?.id} modalTitle="Company Detail" />
      });
    }
  }

  const modulesDD = allModules && allModules?.map((translatableObj) => {
    let obj = bluePrint.translate(translatableObj, selectedLanguage)
    return { name: obj.name, id: obj.id }
  })

  const topicsDD = selectedModuleDD && selectedModuleDD?.topics?.map((translatableObj) => {
    let obj = bluePrint.translate(translatableObj, selectedLanguage)
    return { name: obj.name, id: obj.id }
  })

  const learnJDD = selectedTopics && learningJourDD?.map((translatableObj) => {
    let obj = bluePrint.translate(translatableObj, selectedLanguage)
    return { name: obj.name, id: obj.id }
  })


  const handleCourseAdd = React.useCallback(() => {
    hideDialog();
  }, [hideDialog]);

  const handleHideDialog = () => {
    hideDialog();
    setCount(count ? false : true)
    

  }

  const showAddDialog = React.useCallback(() => {
    showDialog({
      title: "Add Module",
      size,
      content: <Module onClose={() => handleHideDialog()} onSave={handleCourseAdd} course={relationalObj} />
    });
  }, [handleCourseAdd, showDialog]);


  const handleModuleRowClick = (params) => {
    updateSelectedModule(params?.row)
  }

  const handleTopicRowClick = (params) => {
    updateSelectedTopic(params?.row)
  }



  const module = allModules?.map((m) => m);//.sort((a, b) => (a.order > b.order ? 1 : -1));
  const d = module?.sort((a, b) => (a.order > b.order ? 1 : -1))
  // selectedTopics?.sort(function (a, b) { return console.log(a.order - b.order) })

  console.warn(" from selectedTopics", d)
  const OrderTable = (dialogName) => {


    const size = "xl";
    switch (dialogName) {

      case 'Module':
        showDialog({
          title: 'Module List',
          size,
          content: <Ordering dataList={allModules} table={"module"} />
        });
        break;
      case 'Topic':
        showDialog({
          title: 'Topic List',
          size,
          content: <Ordering dataList={selectedTopics} table={"topic"} />
        });
        break;
      case 'LearningJourney':
        showDialog({
          title: 'Learning Journey List',
          size,
          content: <Ordering dataList={selectedLearningJouneys} table={"learningJourney"} />
        });
        break;
       
      default:
        console.error("No case Found");
        break;

    }
  }
  const columns = ['Course', 'Company'];
  const rows = [
    { name: lCourse?.name,company:lCourse?.company?.name },
  ];
  const buttons = [
    { label: 'View Company',align:'center', icon: <VisibilityOutlinedIcon />, onClick: handleCompanyView },
      { label: 'Add Module', align:'center',icon: <AddOutlined />, onClick: showAddDialog },
      { label: 'View Course',align:'center', icon: <VisibilityOutlinedIcon />, onClick: handleCourseView },
    
    ];
  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>

      {responseDelete?.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
   
      {responseDeleteTopic.isLoading === true && <SimpleBackdrop responseAdd={responseDeleteTopic} />}
      {responseDeleteTopic?.isSuccess === true && <Snackbar responseAdd={responseDeleteTopic} />}
      {responseDeleteLearningJourney.isLoading === true && <SimpleBackdrop responseAdd={responseDeleteLearningJourney} />}
      {responseDeleteLearningJourney?.isSuccess === true && <Snackbar responseAdd={responseDeleteLearningJourney} />}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <span>{showMode}</span>
        <Snackbar responseAdd={responseDelete} />
        <Snackbar responseAdd={responseDeleteTopic} />
        <Snackbar responseAdd={responseDeleteLearningJourney} />

   
        {/* Courses datatable */}
        
         <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Course"}  />

       


        {/* DataTables */}

        {<SimpleButtons title={"Module Ordering"} onClick={() => OrderTable("Module")} />}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Modules</Typography>
          </AccordionSummary>
          <AccordionDetails>

            {/* Modules datatable */}
         
            {
              allModules &&
              <DataTable
                onRow={handleModuleRowClick}
                heirarchyId={location?.state?.obj?.id}
                translate={true}
                btnTitleAdd="Module"
                companyName={location?.state?.company}
                data={allModules}
                bluePrint={bluePrint}
                ViewModel={() => <ViewModule />}
                ModalContent={() => <Module onClose={hideDialog} />}
                relationalTitle="Topic"
                RelationalModalContent={() => <AppUpdateTopic
                  onClose={hideDialog} />}
                  showButton1={false}
                  viewTitle1="Add Topic"
              />
            }
          


          </AccordionDetails>
        </Accordion>
        {<SimpleButtons title={"Topic Ordering"} onClick={() => OrderTable("Topic")} />}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Topics</Typography>
          </AccordionSummary>
          <AccordionDetails>
          

            <div style={{ marginLeft: '65%', width: '30%', marginTop: '0px', }}>
              {
                allModules &&
                <ComboBox
                  btnTitleAdd={"Topic"}
                  data={modulesDD}
                  onChange={(event, value) => { updateSelectedModule(value) }}
                  showFirst={true}
                  defValue={input}
                 
                  value={"Modules"}

                />
              }
            </div>
            <div style={{ width: '100%' }}>
              {
                selectedTopics &&
                <DataTable
                  onRow={handleTopicRowClick}
                  companyName={location?.state?.company}
                  btnTitleAdd="Topic"
                  data={selectedTopics}
                  bluePrint={bluePrintTopic}
                  ViewModel={() => <ViewTopic />}
                  ModalContent={() => <AppUpdateTopic onClose={hideDialog} />}
                  relationalTitle="Learning Journey"
                  ddName="Modules"
                  RelationalModalContent={() => <LearningJourney onClose={hideDialog} />}
                  showButton1={false}
                  viewTitle1="Add Learning Journey"
                  showButton6={false}
                  viewTitle6={"Topic Exercise"}
                  showButton5={false}
                  viewTitle5="Topic Surveys"
                  isBtnTitleAdd5={true}


                />
              }
            </div>
         

          </AccordionDetails>
        </Accordion>
        {<SimpleButtons title={"LearningJourney Ordering"} onClick={() => OrderTable("LearningJourney")} />}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Learning Journeys</Typography>
          </AccordionSummary>
          <AccordionDetails>

            {topicsDD && <div style={{ marginLeft: '65%', width: '30%', marginTop: '0px', }}>
              <ComboBox
                btnTitleAdd={"LearningJourneys"}
                data={topicsDD}
                onChange={(event, value) => { updateSelectedTopic(value) }}
                showFirst={true}
                defValue={inputTopic}
                inputChange={(e, val, reason) => console.log("input change value ", val)}
                value={"Topics"}
           
              />
            </div>}
            <div style={{ width: '100%' }}>
              {
                selectedLearningJouneys &&
                <DataTable
                  showAddButton={true}
                  btnTitleAdd="learningJourney"
                  data={selectedLearningJouneys}
                  bluePrint={bluePrintLearningJourney}
                  ModalContent={() => <LearningJourney onClose={hideDialog} />}
                  ViewModel={() => <ShowLearningJourney />}
                  relationalTitle="Activity"
                  showButton6={false}
                  viewTitle6={"Activities"}

                />
              }
            </div>
        


          </AccordionDetails>
        </Accordion>


      </Box>
    </>
  )
}


export default ModuleList

