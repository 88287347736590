
import { Button, TextField, Fab, Box } from '@mui/material';
import { selectedModuleSelector, setSelectedModule, selectedCourseSelector, setSelectedCourse, } from 'app/rkt_query/SelectedStuffReducer';
import {  selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddModuleMutation, useGetModuleQuery, useUpdateModuleMutation,  useGetAllCoursesQuery, useGetCourseQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField from '../../../@jumbo/dz-components/textboxes/DZTextField';
import ComboBox from '../../../@jumbo/dz-components/autocomplete-cb/ComboBox';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { Validate } from 'app/validation/Validations';
import { DzNoBackgroundTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import useResponseError from './../../../@jumbo/dz-components/custom-hooks/useResponseError';


const Module = ({ onClose, selectedId, course, check }) => {
  ////console.log("module data", selectedId, course)
  const dispatch = useDispatch();
  const selectedModule = useSelector(selectedModuleSelector);
  const selectedCourse = useSelector(selectedCourseSelector);
  const [disable, setDisabled] = useState([true]);
  const [checktrue, setCheck] = useState(false);

  console.log("selected course", selectedCourse,  course)

  const { data: res_get_all, isLoading: isLoadingCourses, error: errorCourses } = useGetAllCoursesQuery();
  const { data: res_get, isLoading: isLoadingModule, error: errorModule } = useGetModuleQuery(selectedId ?? selectedModule?.id);
  // const { data: res_get1, isLoading: isLoadingCourse, error: errorCourse } = useGetCourseQuery(selectedCourse?.id);
  const { data: res_get1, isLoading: isLoadingCourse, error: errorCourse } = useGetCourseQuery(course?.id ?? res_get?.data?.module.course_id);


  const [updateModule, responseUpdate,] = useUpdateModuleMutation();
  const [addModule, responseAdd,] = useAddModuleMutation();
  const bluePrint = BLUE_PRINTS.module;
  const [moduleState, setModuleState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.module?.image);

  const addUpdateModuleData = (action) => {
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', res_get?.data?.module?.id);
    formData.append('name', JSON.stringify(moduleState.name));
    formData.append('course_id', moduleState?.course_id);
    formData.append('description', JSON.stringify(moduleState.description));
    action === "add" ? addModule(formData) : updateModule(formData);

  }
  //this code is use to control infinity loops and backend required error. 
  const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
  // const [error, setError] = useState([]);
  // const [count, setCount] = useState(true);
  // const countZero = () => {
  //   setCount(true)
  // }
  // if (responseAdd?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseAdd?.error?.data?.errors)
  // }

  // if (responseUpdate?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseUpdate?.error?.data?.errors)
  // }


  useEffect(() => {
   
    res_get?.data?.module?.image && setSelectedFile(res_get?.data?.module?.image)
    setModuleState((selectedModule || selectedId) ? { ...res_get?.data?.module, course_id: selectedCourse?.id } : { ...bluePrint, course_id: res_get1?.data?.course?.id ?? '' });
    setCheck((selectedModule || selectedId) ? true : false);
 
    if (res_get1?.data?.course?.id) {
      dispatch(setSelectedModule(null));
   
    }
   
  }, [res_get, res_get1, selectedCourse])

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {

      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);

    }
  }
  const courses = res_get_all?.data?.courses.map((course) => (BLUE_PRINTS.course.translate(course, selectedLanguage)))

  const moduleFields = [
    { label: 'Name ', key: 'name', multilingual: true, errorKey:["0"] },
    { label: 'Description', key: 'description', multilingual: true, rows:3 },
  ];
  
  const renderModuleFields = () => {
    return moduleFields.map((field) => (
      <DZTextValidation
        key={field.label}
        label={field.label}
        variant="outlined"
        noOfRows={field?.rows}
        value={field.multilingual ? moduleState?.[field.key]?.[selectedLanguage] : moduleState?.[field.key]}
        error={error?.[field?.errorKey]}
        onChange={(e) => {
          if (field.multilingual) {
            const updatedData = {
              ...moduleState[field.key],
              [selectedLanguage]: e.target.value,
            };
            setModuleState((prevState) => ({
              ...prevState,
              [field.key]: updatedData,
            }));
          } else {
            setModuleState((prevState) => ({
              ...prevState,
              [field.key]: e.target.value,
            }));
          }
        }}
      />
    ));
  };

  return (
    <div>

      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
      <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />

      {/* {res_get1?.data?.course?.id ? <>
            <input type='hidden' value={res_get1?.data?.course?.id} />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
              <label style={{ fontSize: "1em", fontWeight: 500 }}>Course</label>
              <label style={{ fontSize: "1em" }}>{res_get1?.data?.course?.name[selectedLanguage]}</label>
            </div>
            {/* <label style={{ fontSize: "1em", fontWeight: 500 }}>{res_get1?.data?.company?.name}</label> */}

      {
        moduleState ?
          <div style={addUpdateDivStyleGlobal}>
            {imagePreview ? (
              <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
            ) : (

              <DZImageCircle image={selectedFile} link={Config.AWSModuleOriginalImg} style={{
                height: 100, width: 100, borderRadius: 50, alignSelf: "center",
              }}
              />

            )}
            <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
              <label htmlFor="upload-photo">
                <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
                />
                <Fab
                  color="primary"
                  size="small"
                  component="span"
                  aria-label="add"
                  variant="extended"
                >
                  Upload photo
                </Fab>

              </label>
            </Box>
            <DzNoBackgroundTranslatable title={"Course"} children={res_get1?.data?.course?.name} />
                {renderModuleFields()}

            {/* <DZTextValidation error={error?.["0"]} label="Name" variant="outlined" value={moduleState?.name?.[selectedLanguage] ?? ''}
              onChange={(e) => { setModuleState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })); }}
              

            />
            <DZTextField label="Description" variant="outlined" noOfRows={3} value={moduleState?.description?.[selectedLanguage] ?? ''}
              onChange={(e) => { setModuleState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
            /> */}
            <div style={dialogButtonsStyle} >
              <Button variant="outlined" onClick={() => { hideDialog(); dispatch(setSelectedModule(null)); }}>Cancel</Button>
              {

                checktrue ? <Button variant="contained" onClick={() => { addUpdateModuleData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                  : <Button variant="contained" onClick={() => { addUpdateModuleData("add"); setShowBackDrop(true); resetCount(); }}>Add</Button>

              }

            </div>
            {/* <div style={{ width: '10%' }}>{JSON.stringify(moduleState)}</div> */}
          </div>
          : <div>Loading....</div>
      }
      {/* dispatch(setSelectedModule(null)); */}
      {/* {!selectedModule &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}


    </div>
  )
}

export default Module