import React, { useEffect, useState } from 'react'
import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField } from '@mui/material';
import { selectedCompanySelector, setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetAllTeamUsersQuery, useAddTeamUserMutation, useGetCourseQuery } from 'app/rkt_query/storeApis';
import { useSelector, useDispatch } from 'react-redux';
import DZTextField from '../../../@jumbo/dz-components/textboxes/DZTextField';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { DzNoBackgroundTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import DZDropdownMultiple from '@jumbo/dz-components/dropdowns/DZDropdownMultiple';
import DZDropdown from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';


const AddTeamUsers = ({ selectedId }) => {

    // const selectedCompany = useSelector(selectedCompanySelector);
    const { data: res_get_all, isLoading: isLoadingUserCourse, error: errorUserCourse } = useGetAllTeamUsersQuery(selectedId);
    // const { data: res_get, isLoading: isLoadingCourse, error: errorCourse } = useGetCourseQuery();



    console.log(res_get_all);
    const [addTeamUsers, responseAdd,] = useAddTeamUserMutation();
    const bluePrint = BLUE_PRINTS.teamUser;
    const [teamUserState, setUserCourseState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);

      //this code is use to control infinity loops and backend required error. 
      const {error,resetCount}= useResponseError(responseAdd);
    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);
    // const countZero = () => {
    //     setCount(true)
    // }

    // if (responseAdd?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }

    useEffect(() => {


        setUserCourseState({ ...bluePrint, team_id: res_get_all?.data?.team?.id });

    }, [res_get_all])



    // const course_company_teams = res_get_all?.data?.course_company_teams?.map((team) => (team))
    // const users = res_get_all?.data?.users?.map((user) => (user))
    const teamUser = res_get_all?.data?.team_users?.map((e) => ({ ...teamUserState, user_id: e?.user_id }))
    console.log(teamUser)
    // const unRegUsers = res_get_all?.data?.users?.filter((el) => !teamUser.includes(c => el.id === c.user_id));
    // const unRegUsers = res_get_all?.data?.users?.filter(obj => !users1.includes(c => obj.id === c.user_id));
    const unRegUsers = res_get_all?.data?.users?.filter(u => !teamUser.some(c => (c.user_id == u.id)));
    console.log(unRegUsers, "unRegUsers");
    console.log(res_get_all?.data?.users, "users")
    console.log(teamUserState, "teamUser")

    return (
        <div>
            {/* {JSON.stringify(unRegUsers)} */}
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : ""} />

            {teamUserState ?
                <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25 }}>



                    {/* <DzNoBackgroundTranslatable title={"Team"} children={res_get_all?.data?.team?.name} /> */}
                    {/* <input type="hidden" value={teamUser?.course_id} onChange={(e) => { setUserCourseState(x => ({ ...x, course_id: e.target.value, })) }} /> */}
                    {/* <DZDropdown placeholder={'Select Team'} value={teamUserState?.team_id} data={course_company_teams}
                        onChange={(e) => { setUserCourseState(x => ({ ...x, team_id: e.target.value, })) }} /> */}
                    <DZDropdownMultiple placeholder={'Select User'} value={teamUserState?.user_id} data={unRegUsers}
                        onChange={(e) => { setUserCourseState(x => ({ ...x, user_id: e.target.value, })) }} />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }} >
                        <Button variant="outlined" onClick={() => { hideDialog() }}>Cancel</Button>
                        <Button variant="contained" onClick={() => { addTeamUsers(teamUserState); setShowBackDrop(true); resetCount(); }}>Add</Button>


                    </div>

                </div>
                : <div>Loading....</div>
            }


        </div>
    )
}

export default AddTeamUsers