import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Box, Button, TextField, Fab } from '@mui/material';
import { selectedActivitySelector, setSelectedActivity, selectedLearningJourneySelector } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddActivityMutation, useGetActivityQuery, useUpdateActivityMutation,useDeleteEngagementQuestionOptionMutation, useDeleteEngagementMutation, useGetAllLearningJourneysQuery, useGetLearningJourneyQuery, useGetAllActivityTypesQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown, { DZSimpleDropDown } from './../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';
import ComboBox from '../../../@jumbo/dz-components/autocomplete-cb/ComboBox';
import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar, { NoHideSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import Grid from "@mui/material/Grid";
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZLabel, { DzNoBackgroundTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { blue } from '@mui/material/colors';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEngagementDialog } from '@jumbo/components/JumboDialog/hooks/useEngagementDialog';
import DZDeleteDialog from '@jumbo/dz-components/dialog/DZDeleteDialog';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';

// import { AddCircle } from "@material-ui/icons";

const Activity = ({ learning_journey_id }) => {

    const { showDialog, hideDialog } = useJumboDialog();
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [index1, setIndex] = React.useState(null);
    const [optionIndex, setOptionIndex] = React.useState(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openOptionSnackbar, setOpenOptionSnackbar] = React.useState(false);
    const dispatch = useDispatch();
    const selectedActivity = useSelector(selectedActivitySelector);
    const { data: res_get_all_activity_type, isLoading: isLoadingActivityTypes, error: errorActivityTypes } = useGetAllActivityTypesQuery();
    const { data: res_get, isLoading: isLoadingActivity, error: errorActivity } = useGetActivityQuery(selectedActivity?.id);
    const { data: res_get1, isLoading: isLoadingLearningJourney, error: errorLearningJourney } = useGetLearningJourneyQuery(learning_journey_id ?? res_get?.data?.activity?.learning_journey_id);
    const reply_types =res_get1?.data?.learning_journey?.topic?.module?.course?.buddy_feature==1?['smileys', 'scale', 'note','mcq', 'buddy','redirect_activity', 'redirect_exercise']:['smileys', 'scale', 'note','mcq', 'redirect_activity', 'redirect_exercise'];
  
    const [updateActivity, responseUpdate,] = useUpdateActivityMutation();
    const [addActivity, responseAdd,] = useAddActivityMutation();
    const [deleteEngagement, responseDelete,] = useDeleteEngagementMutation();
    const [deleteEngagementOption, responseOptionDelete,] = useDeleteEngagementQuestionOptionMutation();
    const bluePrint = BLUE_PRINTS.activity;
    const [inputFields, setInputFields] = useState([
        { id: "", reply_type: 'smileys', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', max_options:'',  options: [{ id:'', title: LANGUAGES.defaultObject }] }
    ])
    const [activityState, setActivityState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [imagePreview, setImagePreview] = useState("");
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.activity?.image);
    const handleClickOpen = (index) => {
        setOpen(true);
        setIndex(index)
        setOpenSnackbar(false)
    };
    const handleClickOpen1 = (nestedIndex) => {
        setOpen1(true);
        setOptionIndex(nestedIndex)
        setOpenOptionSnackbar(false)
    };
    const handleClose = () => {
        setOpen(false);
        setOpen1(false);

    };
      //this code is use to control infinity loops and backend required error. 
    const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
    // const countZero = () => {
    //     setCount(true)
    // }
    // if (responseAdd?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }
    // if (responseUpdate?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseUpdate?.error?.data?.errors)
    // }


    useEffect(() => {

        res_get?.data?.activity?.image && setSelectedFile(res_get?.data?.activity?.image)
        res_get?.data?.activity?.image && console.log("selectedFile", res_get?.data?.activity?.image);
        setActivityState(selectedActivity ? res_get?.data?.activity : { ...bluePrint, learning_journey_id: learning_journey_id ?? ''});
        console.log(activityState);
       if (res_get?.data?.activity?.engagements) {
        const engagements = res_get?.data?.activity?.engagements?.map(engagement => {
            const options = engagement?.options.map(option => option);
            console.log("engagement option", options)

            return {
                ...engagement,
                options: options
            };
        });
        setInputFields(engagements);
       }else{
        
        setInputFields(res_get?.data?.activity?.engagements ?? [{ id: "", reply_type: 'smileys', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', max_options:'', options: [{ title: LANGUAGES.defaultObject}]}])
    }       
        if (res_get1?.data?.learning_journey?.id) {
            dispatch(setSelectedActivity(null));
         
        }

    }, [res_get, res_get1])

 

    const addUpdateActivityData = (action) => {

        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('id', res_get?.data?.activity?.id);
        formData.append('name', JSON.stringify(activityState.name));
        formData.append('type_id', activityState.type_id);
        formData.append('learning_journey_id', res_get1?.data?.learning_journey?.id);
        formData.append('video', activityState.video);
        // formData.append('unit_of_duration', activityState.unit_of_duration);
        // formData.append('unit_of_reminder', activityState.unit_of_reminder);
        formData.append('order', activityState.order);
        // formData.append('duration_days', activityState.duration_days);
        formData.append('duration_minutes', activityState.duration_minutes);
        formData.append('reminder_days', activityState.reminder_days);
        // formData.append('exercise', activityState.exercise);
        formData.append('description', JSON.stringify(activityState.description));
        formData.append('engagements', JSON.stringify(inputFields));
        console.log(formData.get('name'))
        action === "add" ? addActivity(formData) : updateActivity(formData);

    }

    const handleFormChange = (index, event,nestedIndex) => {
     
        if (nestedIndex === undefined) {
        setInputFields(inputFields?.map((eng, i) => i === index ? {
            ...eng,
            [event.target.name]: (
                eng?.[event.target.name].en === undefined ? event.target.value
                    : { ...eng?.[event.target.name], [selectedLanguage]: event.target.value }
            )
        } : eng));
    }
    else {
        const newInputFields = [...inputFields];
        newInputFields[index].options = newInputFields[index].options.map((option, i) =>
          i === nestedIndex
            ? {
                ...option,
                [event.target.name]:
                (
                    option?.[event.target.name].en === undefined ? event.target.value
                        : { ...option?.[event.target.name], [selectedLanguage]: event.target.value }
                )
                
              }
            : option
        );
        setInputFields(newInputFields);
      }
    //for normal without multi language
    //   else {
    //     const newInputFields = [...inputFields];
    //     newInputFields[index].options = newInputFields[index].options.map((option, i) =>
    //       i === nestedIndex
    //         ?   {
    //                ...option,
    //                  title: {
    //                  ...option.title,
    //                   [selectedLanguage]: event.target.value,
    //                  },
    //               }
    //         : option
    //     );
    //     setInputFields(newInputFields);
    //   }

    }
   
    const addFields = () => {
        setInputFields([...inputFields, { id: "", reply_type: 'smileys', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '',max_options:'', options: [{ title: '' }] }])
    }
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const deleteEngagementActivity = (id, index) => {

        deleteEngagement(id);
        removeFields(index)
        setOpenSnackbar(true)
        handleClose()
    }
    const deleteEngagementQuestionOption = (id, nestedIndex,index) => {

        deleteEngagementOption(id);
        handleRemoveNestedField(index,nestedIndex)
        setOpenOptionSnackbar(true)
        handleClose()



    }


    const filehandle = (e) => {
      
        if (e.target.files.length !== 0) {

            setSelectedFile(e.target.files[0]);
          
            console.log("Checking", selectedFile)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);

        }



    };
      
    const handleAddNestedField = (index) => {
        
        const newFields = [...inputFields];
        newFields[index].options.push({ title: LANGUAGES.defaultObject });
        setInputFields(newFields);
      };
      const handleRemoveNestedField = (index, nestedIndex) => {
        const newFields = [...inputFields];
        newFields[index].options.splice(nestedIndex, 1);
        setInputFields(newFields);
      };
//question option
// const addMCQOption = () => {
//     setMCQOptions([...mcqOptions, '']);
//     // setMCQInputFields([...mcqInputFields, <input key={mcqInputFields.length} type="text" value={mcqOptions[mcqOptions.length]} onChange={(e) => updateMCQOption(e.target.value, mcqOptions.length)} />]);
//   };
  
//   const updateMCQOption = (value, index) => {
//     console.log("sss", value, index)
//     const updatedOptions = [...mcqOptions];
//     updatedOptions[index] = value;
//     setMCQOptions(updatedOptions);
  
//     // const updatedInputFields = [...mcqInputFields];
//     // updatedInputFields[index] = <input key={index} type="text" value={value} onChange={(e) => updateMCQOption(e.target.value, index)} />;
//     // setMCQInputFields(updatedInputFields);
//   };
    //const learning_journeys = res_get_all?.data?.learning_journeys.map((learningJourney) => (BLUE_PRINTS.learningJourney.translate(learningJourney, selectedLanguage)))

    const activity_types = res_get_all_activity_type?.data?.activity_types.map((activityType) => (BLUE_PRINTS.activityType.translate(activityType, selectedLanguage)))


    //console.log('activityState: ', res_get_all_activity_type?.data?.activity_types);
    //if(activityState?.image) setImagePreview(Config.AWSActivityLargeImg + activityState?.image);
    //console.log('imagePreview: ', imagePreview);
    const handleDurationChange = (event) => {
        const minutes = parseInt(event.target.value);
        const seconds = minutes * 60;
        setActivityState((prevState) => ({
          ...prevState,
          duration_minutes: seconds,
        }));
      };

      const activityFields = [
        { label: "Name", key: "name", multilingual: true, errorKey: ["0"] },
        { label: "Order", key: "order", type: "number" },
        { label: "Video", key: "video", multilingual: false, errorKey: "video" },
        { label: "Duration in Minutes", key: "duration_minutes", type: "number" },
        { label: "Reminder Days", key: "reminder_days", type: "number" },

        
      ];
    
      const renderActivityFields = () => {
        return activityFields.map((field) => {
          if (field?.type === "number") {
            return (
                <Grid item xs={12} md={6} lg={6}>
                <Div sx={{ mb: 0.5, mt: 1 }}>
              <TextField
                key={field.label}
                type="number"
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 1 }}
                fullWidth
                {...(error?.[field.key] && {
                  error: true,
                  helperText: error?.[field.key],
                })}
                label={field.label}
                variant="outlined"
                value={activityState?.[field.key]}
                onChange={(e) => {
                  setActivityState((prevState) => ({
                    ...prevState,
                    [field.key]: e.target.value,
                  }));
                }}
              />
              </Div>
              </Grid>
            );
          } else {
            return (
                <Grid item xs={12} md={6} lg={6}>
                <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZTextValidation
                key={field.label}
                label={field.label}
                variant="outlined"
                noOfRows={field?.rows}
                value={
                  field.multilingual
                    ? activityState?.[field.key]?.[selectedLanguage]
                    : activityState?.[field.key]
                }
                error={error?.[field?.errorKey]}
                onChange={(e) => {
                  if (field?.multilingual) {
                    const updatedData = {
                      ...activityState[field.key],
                      [selectedLanguage]: e.target.value,
                    };
                    setActivityState((prevState) => ({
                      ...prevState,
                      [field.key]: updatedData,
                    }));
                  } else {
                    setActivityState((prevState) => ({
                      ...prevState,
                      [field.key]: e.target.value,
                    }));
                  }
                }}
              />
              </Div>
              </Grid>
            );
          }
        });
      };
    return (
        <div>
            {/* {JSON.stringify(activityState)} */}

            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />
            {openSnackbar ? <NoHideSnackbar responseAdd={responseDelete?.isSuccess === true ? responseDelete : ''} /> : ""}
            {openOptionSnackbar ? <NoHideSnackbar responseAdd={responseOptionDelete?.isSuccess === true ? responseOptionDelete : ''} /> : ""}


            {activityState ?
                <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25, marginBottom: 60  }}>

                    {imagePreview ? (
                        <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
                    ) : (

                        <DZImageCircle activity="activity" image={selectedFile} link={Config.AWSActivityOriginalImg} style={{
                            height: 100, width: 100, borderRadius: 50, alignSelf: "center",
                        }}
                        />

                    )}

                    <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
                        <label htmlFor="upload-photo">
                            <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
                            />
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                            >
                                Upload photo
                            </Fab>

                        </label>
                    </Box>

                    <Grid container spacing={3.75}>
                        <Grid item xs={12} md={8} lg={8}>

                            <DzNoBackgroundTranslatable title={"Learning Journey"} children={res_get1?.data?.learning_journey?.name} />

                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                                 <DZDropdown error={error?.type_id} value={activityState?.type_id} data={activity_types}
                                    onChange={(e) => { setActivityState(x => ({ ...x, type_id: e.target.value, })) }} placeholder="Select Activity Type" />
                                
                            </Div>
                        </Grid>
                        {renderActivityFields()}
                        {/* <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                                <DZTextValidation
                                    error={error?.["0"]} label="Name" variant="outlined" value={activityState?.name[selectedLanguage]}
                                    onChange={(e) => { setActivityState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })) }}
                                />
                            </Div>
                        </Grid>
                   
                    

                        <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>

                                <TextField fullWidth label="Order" type="number" inputProps={{ min: 1 }} variant="outlined" noOfRows={1} value={activityState?.order}
                                    onChange={(e) => { setActivityState(x => ({ ...x, order: e.target.value })) }}
                                />
                            </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                                <DZTextValidation error={error?.video} label="Video Link" variant="outlined" noOfRows={1} value={activityState?.video}
                                    onChange={(e) => { setActivityState(x => ({ ...x, video: e.target.value })) }}
                                />
                            </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>

                                <TextField fullWidth label="Duration in Minutes" type='number' variant="outlined" noOfRows={1} value={activityState?.duration_minutes}
                                      onChange={(e) => { setActivityState(x => ({ ...x, duration_minutes: e.target.value })) }}
                                />
                            </Div>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>

                                <TextField fullWidth label="Reminder Days" type='number' variant="outlined" noOfRows={1} value={activityState?.reminder_days}
                                    onChange={(e) => { setActivityState(x => ({ ...x, reminder_days: e.target.value })) }}
                                />
                            </Div>
                        </Grid> */}
                    {/* <DZTextValidation

                        error={error?.["0"]} label="Name" variant="outlined" value={activityState?.name[selectedLanguage]}
                        onChange={(e) => { setActivityState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })) }}
                    /> */}
                        {/* <DZDropdown sx={{
                                width: { md: 338 },

                            }} error={error?.learning_journey_id} disabled={disable} value={activityState?.learning_journey_id} data={learning_journeys}
                                onChange={(e) => { setActivityState(x => ({ ...x, learning_journey_id: e.target.value, })) }} placeholder="Select Learning Journey" /> */}
                        {/* <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>

                                <DZTextField label="Unit of Duration" error={error?.unit_of_duration} variant="outlined" noOfRows={1} value={activityState?.unit_of_duration}
                                    onChange={(e) => { setActivityState(x => ({ ...x, unit_of_duration: e.target.value })) }}
                                />
                            </Div>
                        </Grid> */}
{/* 
                        <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>

                                <DZTextField label="Unit of Reminder" error={error?.unit_of_reminder} variant="outlined" noOfRows={1} value={activityState?.unit_of_reminder}
                                    onChange={(e) => { setActivityState(x => ({ ...x, unit_of_reminder: e.target.value })) }}
                                />
                            </Div>
                        </Grid> */}
                        {/* <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>

                                <TextField fullWidth label="Duration Days" type='number' variant="outlined" noOfRows={1} value={activityState?.duration_days}
                                    onChange={(e) => { setActivityState(x => ({ ...x, duration_days: e.target.value })) }}
                                />
                            </Div>
                        </Grid> */}


                    </Grid>

                 
                    <DZTextField label="Description" variant="outlined" noOfRows={3} value={activityState?.description[selectedLanguage]}
                        onChange={(e) => { setActivityState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
                    />

               
                    <div style={{ width: '100%' }}>

                        <DZLabel title={"Activity Engagements"} />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', }} >
                            <Button size="small" variant="outlined" startIcon={<ControlPointOutlinedIcon />} onClick={addFields} >
                                Add More
                            </Button>
                        </div>
                    </div>

                  

                    <form>
                        {inputFields?.map((input, index) => {
                            return (
                                <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                                    <CardContent>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%' }}>

                                        {index1 === index ? <DZDeleteDialog title={"Are you sure about deleting this Engagement?"}
                                                                            description={" You won't be able to recover this engagement later"}
                                                                            openBox={open}
                                                                            handleClose={()=>handleClose()}
                                                                            handleDeleteFunctionCall={()=>deleteEngagementActivity(input?.id, index)}

                                        /> : ""}
                                            {/* {index1 === index ? <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                index={index1}
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    {" Are you sure about deleting this Engagement?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        You won't be able to recover this engagement later
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>No</Button>
                                                    <Button onClick={() => deleteEngagementActivity(input?.id, index)} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog> : ""} */}
                                            {
                                             input?.id ?

                                                    <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => handleClickOpen(index)} >
                                                        Delete
                                                    </Button>

                                                    : <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ color: 'red' }} onClick={() => removeFields(index)} >
                                                        Remove
                                                    </Button>
                                            }
                                        </div>
                                        <div key={index} >

                                            <Grid item xs={12} md={6} lg={6}>
                                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                                    <DZSimpleDropDown value={input?.reply_type} data={reply_types} name="reply_type"
                                                        placeholder="Select Type" onChange={event => handleFormChange(index, event)} />
                                                </Div>
                                            </Grid>
                                            
                                            {
                                                input?.reply_type === 'redirect_exercise'
                                                    &&
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Div sx={{ mb: 0.5, mt: 1 }}>
                                                            <TextField required fullWidth type='number' label="Redirect Exercise" name='reply_exercise_id' variant="outlined" noOfRows={1} value={input?.reply_exercise_id}
                                                                onChange={event => handleFormChange(index, event)}
                                                            />
                                                        </Div>
                                                    </Grid>
                                            } 
                                            {
                                                input?.reply_type === 'redirect_activity'
                                                    &&
                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Div sx={{ mb: 0.5, mt: 1 }}>
                                                            <TextField required fullWidth label="Redirect Activity" type='number' name='reply_activity_id' variant="outlined" noOfRows={1} value={input?.reply_activity_id}
                                                                onChange={event => handleFormChange(index, event)}
                                                            />
                                                        </Div>
                                                    </Grid> 
                                            }
                                            <Grid item xs={12} md={6} lg={6}>
                                                <Div sx={{ mb: 0.5, mt: 1 }}>

                                                    <input type="hidden" name='id' value={input?.id} onChange={event => handleFormChange(index, event)} />
                                                    <TextField fullWidth label="Question" type='text' name='question' variant="outlined" noOfRows={1} value={input.question[selectedLanguage]}
                                                        onChange={event => handleFormChange(index, event)}
                                                    />
                                                </Div>
                                            </Grid>
                                            {input?.reply_type === 'mcq' && (
                                                         <div>
                                                         <TextField  label="Maximum Option" type='text' name='max_options' variant="outlined" noOfRows={1} value={input.max_options}
                                                        onChange={event => handleFormChange(index, event)} />
                                                         {input?.options?.map((option, nestedIndex) => (
                                                            <div key={`${index}-${nestedIndex}`}>
                                                            <input type="hidden" name='id' value={option?.id} onChange={event => handleFormChange(index, event,nestedIndex)} />
                                                            <TextField  label={`Option-${nestedIndex+1}`} type='text'  InputLabelProps={{ shrink: true }} name='title'  sx={{ mt:'10px',width: '40ch' }} variant="outlined" noOfRows={1} value={option.title[selectedLanguage]}
                                                            onChange={event => handleFormChange(index,event,nestedIndex)}  />
                                                           {/* delete dialog */}
                                                           {/* {optionIndex === nestedIndex &&  <DZDeleteDialog title= {" Are you sure about deleting this Option?"}
                                                                            description={" You won't be able to recover this option later"}
                                                                            openBox={open1}
                                                                            handleClose={()=>handleClose()}
                                                                            handleDeleteFunctionCall={()=>deleteEngagementQuestionOption(option?.id, nestedIndex,index)}/> } */}
                                                           {optionIndex === nestedIndex ? <Dialog
                                                open={open1}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                index={optionIndex}
                                            >

                                                <DialogTitle id="alert-dialog-title">
                                                    {" Are you sure about deleting this Option?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        You won't be able to recover this option later
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>No</Button>
                                                    <Button onClick={() => deleteEngagementQuestionOption(option?.id, nestedIndex,index)} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog> : ""}
                                                           {
                                                     option?.id ?
                                                    <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ ml:'3px',mt:'10px' }} onClick={() => handleClickOpen1(nestedIndex)} >
                                                        Delete
                                                    </Button>
                                                    : <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ color: 'red',ml:'3px',mt:'10px' }} onClick={() => handleRemoveNestedField(index, nestedIndex)} >
                                                        Remove
                                                    </Button>
                                            }
                                                            
                                                            </div>
                                                            ))}                                                          
                                                       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', }} >
                                                    <Button size="small" variant="outlined" sx={{ mt:'10px' }} startIcon={<ControlPointOutlinedIcon />} onClick={() => handleAddNestedField(index)} >
                                                        Add Options
                                                    </Button>
                                                </div>
                                                            </div>
                                                        )}
                                                     {
                                                  input?.reply_type !== 'mcq'&&   <Grid item xs={12} md={6} lg={6}>
                                                    <Div sx={{ mb: 0.5, mt: 1 }}>
                                                    <TextField fullWidth label="Reply" type='text' name='reply' variant="outlined" noOfRows={1} value={input.reply[selectedLanguage]}
                                                        onChange={event => handleFormChange(index, event)}
                                                    />
                                                </Div>
                                            </Grid>}
                                        </div>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </form>

          
                    <div style={dialogButtonsStyle}>

                        <Button variant="outlined" onClick={() => { hideDialog(); dispatch(setSelectedActivity(null)); }}>Cancel</Button>
                        {
                            res_get?.data?.activity?.id ? <Button variant="contained" onClick={() => { addUpdateActivityData(); setShowBackDrop(true); resetCount(); dispatch(setSelectedActivity(null)); }}>Update</Button>
                                : <Button variant="contained" onClick={() => { addUpdateActivityData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedActivity(null)); }}>Add</Button>
                        }
                    </div>
                    {/* dispatch(setSelectedActivity(null)); */}

                </div>
                : <div>Loading....</div>
            }



        </div>
    )
}

export default Activity

