import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllActivityTypesQuery, BLUE_PRINTS, useGetActivityTypeQuery, useDeleteActivityTypeMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import AddUpdateActivityType from './AddUpdateActivityType';
import { setSelectedActivityType } from 'app/rkt_query/SelectedStuffReducer';
import ViewActivityType from './ViewActivityType';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { reload } from 'firebase/auth';
// import StoreTokenCheck from './../storetoken/StoreTokenCheck';

const ActivityTypeList = () => {
  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteActivityType, responseDelete] = useDeleteActivityTypeMutation();
  const { data: res_get_all, isLoading: isLoadingActivityTypes, error: errorActivityTypes } = useGetAllActivityTypesQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.activityType
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedActivityType(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedActivityType(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedActivityType(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedActivityType(row)); }
  bluePrint.handleDelete = (id) => { deleteActivityType(id); }
  console.log("tesssst", res_get_all)

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title="Activity Types"
        wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
        <div style={{ width: '100%' }}>
          <DataTable btnTitleAdd="Activity Type" data={res_get_all?.data?.activity_types} bluePrint={bluePrint} ViewModel={() => <ViewActivityType />} ModalContent={() => <AddUpdateActivityType onClose={hideDialog} />} showAddButton={true} />
          {/* <DataTable btnTitleAdd="activityType" data={res_get_all?.data?.activityTypes} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowActivityType/> : <ActivityType onClose={hideDialog} />} /> */}

        </div>
      </JumboDemoCard>
    </>
  )
}


export default ActivityTypeList