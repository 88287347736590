import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedActivityTypeSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetActivityTypeQuery } from 'app/rkt_query/storeApis';
import React from 'react'
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';

const ViewActivityType = () => {
    const selectedActivityType = useSelector(selectedActivityTypeSelector);
    const { data: res_get, isLoading: isLoadingActivityType, error: errorActivityType } = useGetActivityTypeQuery(selectedActivityType?.id);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const bluePrint = BLUE_PRINTS.activityType;
    const { hideDialog } = useJumboDialog();
    console.log("selected activityType", res_get?.data?.activity_type);
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorActivityType ? <div>{JSON.stringify(errorActivityType)}</div>
                    : isLoadingActivityType ? <div>Loading Single ActivityType.....</div>
                        : res_get?.data?.activity_type ?
                            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >

                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                        <label style={{ fontSize: "1em", fontWeight: 500 }}>Name</label>
                                        <label style={{ fontSize: "1em" }}>{res_get?.data?.activity_type?.name[selectedLanguage]}</label>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                        <label style={{ fontSize: "1em", fontWeight: 500 }}>Icon</label>
                                        <label style={{ fontSize: "1em", width: "50%" }}>{res_get?.data?.activity_type?.icon}</label>
                                    </div>

                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                    <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                </div>
                                {/* {JSON.stringify(bluePrint.translate(res_get?.data?.activityType, selectedLanguage))}
                 */}
                            </div>
                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewActivityType