import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllLearningJourneysQuery, BLUE_PRINTS, useGetLearningJourneyQuery, useDeleteLearningJourneyMutation, useGetAllTopicsQuery } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import LearningJourney from './LearningJourney';
import { setSelectedLearningJourney } from 'app/rkt_query/SelectedStuffReducer';

import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { reload } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
import Activity from './../activity/Activity';
// import StoreTokenCheck from './../storetoken/StoreTokenCheck';
import ShowLearningJourney from './ShowLearningJourney';

const LearningJourneyList = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteLearningJourney, responseDelete] = useDeleteLearningJourneyMutation();
    const { data: res_get_all, isLoading: isLoadingLearningJourneys, error: errorLearningJourneys } = useGetAllLearningJourneysQuery();
    const { data: res_get_all_topics, isLoading: isLoadingtopics, error: errortopics } = useGetAllTopicsQuery();
    const [showMode, setShowMode] = useState(false);
    const bluePrint = BLUE_PRINTS.learningJourney
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLearningJourney(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLearningJourney(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLearningJourney(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedLearningJourney(row)); }
    bluePrint.handleDelete = (id) => { deleteLearningJourney(id); }
    console.log("tesssst", res_get_all)

    return (
        <>
            {/* <StoreTokenCheck /> */}
            <span>{showMode}</span>
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            <JumboDemoCard
                title="Learning Journeys"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable heirarchyId={location?.state?.obj?.id} listData={res_get_all_topics?.data?.topics} ddTranslate={true} translate={true} relationalObject={location?.state?.relationalObject} btnTitleAdd="learningJourney" data={res_get_all?.data?.learning_journeys} bluePrint={bluePrint} ViewModel={() => <ShowLearningJourney />}  ModalContent={() => <LearningJourney onClose={hideDialog} />}  RelationalModalContent={() => <Activity onClose={hideDialog} />} relationalTitle="Activity" />
                    {/* <DataTable btnTitleAdd="learningJourney" data={res_get_all?.data?.learningJourneys} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowLearningJourney/> : <LearningJourney onClose={hideDialog} />} /> */}

                </div>
            </JumboDemoCard>
        </>
    )
}


export default LearningJourneyList