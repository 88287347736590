import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedTopicSurveySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetTopicSurveyQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZLabel, { DZDataLabel, DZLabelDescription, DZTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewTopicSurvey = () => {


    const selectedTopicSurvey = useSelector(selectedTopicSurveySelector);
    const { data: res_get, isLoading: isLoadingTopicSurvey, error: errorTopicSurvey } = useGetTopicSurveyQuery(selectedTopicSurvey?.id);
    const selectedLanguage = useSelector(selectedLanguageSelector);

  

    const topicSurveyArray =
        [
            { label: 'Topic', data: res_get?.data?.topic_survey?.topic?.name[selectedLanguage] },
            { label: 'Type', data: res_get?.data?.topic_survey?.type },
            { label: 'Title', data: res_get?.data?.topic_survey?.title[selectedLanguage] },
            // { label: 'Min Options', data: res_get?.data?.topic_survey?.min_value },
            // { label: 'Max Options', data: res_get?.data?.topic_survey?.max_value },
            { label: 'Order', data: res_get?.data?.topic_survey?.order },
           
        ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorTopicSurvey ? <div>{JSON.stringify(errorTopicSurvey)}</div>
                    : isLoadingTopicSurvey ? <div>Loading Single TopicSurvey.....</div>
                        : res_get?.data?.topic_survey ?
                            <>
                                <DZGenericView obj={topicSurveyArray} closeBtn={true}  />


                         
                            </>

                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewTopicSurvey