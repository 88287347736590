import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedTeamSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetTeamQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Company from '../company/Company';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { DZText } from 'app/DZcomponents/DZText';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewTeam = () => {
    const selectedTeam = useSelector(selectedTeamSelector);
    const { data: res_get, isLoading: isLoadingTeam, error: errorTeam } = useGetTeamQuery(selectedTeam?.id);


    const companyArray =
        [
            { label: 'Company', data: res_get?.data?.team?.company?.name },
            { label: 'Team', data: res_get?.data?.team?.name },


        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>
            {res_get?.data && <DZGenericView obj={companyArray} closeBtn={true} />}
        </div>
    )
}

export default ViewTeam