import React from "react";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import TypeSpecimenOutlinedIcon from '@mui/icons-material/TypeSpecimenOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

const menus = [
    {

        label: 'home',
        type: "section",
        children: [
            {
                uri: "/",
                label: 'Dashboard',
                type: "nav-item",
                icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
            },
        ]

    },

    {
        label: 'modules',
        type: "section",
        children: [
            {
                uri: "/companies",
                label: 'Company',
                type: "nav-item",
                icon: <ApartmentOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/courses",
                label: 'Course',
                type: "nav-item",
                icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            },
            // {
            //     uri: "/teams",
            //     label: 'Team',
            //     type: "nav-item",
            //     icon: <ApartmentOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/get-course-users",
            //     label: 'User Courses',
            //     type: "nav-item",
            //     icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/activity-types",
            //     label: 'Activity Type',
            //     type: "nav-item",

            //     icon: <TypeSpecimenOutlinedIcon sx={{ fontSize: 20 }}


            //     />
            // },
            // {
            //     uri: "/activities/:id",
            //     label: 'Activity',
            //     type: "nav-item",
            //     target: "_blank",
            //     icon: <LocalActivityOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/modules",
            //     label: 'Module',
            //     type: "nav-item",
            //     icon: <ViewModuleOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/topics",
            //     label: 'Topic',
            //     type: "nav-item",
            //     icon: <LibraryBooksOutlinedIcon sx={{ fontSize: 20 }} />
            // },
            // {
            //     uri: "/learning-journeys",
            //     label: 'Learning Journey',
            //     type: "nav-item",
            //     icon: <CastForEducationOutlinedIcon sx={{ fontSize: 20 }} />
            // }, {
            //     uri: "/activities",
            //     label: 'Activity',
            //     type: "nav-item",
            //     icon: <LocalActivityOutlinedIcon sx={{ fontSize: 20 }} />
            // },

        ]

    },
    {

        label: 'authPages',
        type: "section",
        children: [
            {
                uri: "/global-settings",
                label: 'Global Settings',
                type: "nav-item",
                icon: <SettingsIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/admins",
                label: 'Admin',
                type: "nav-item",
                icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />
            },
            {
                uri: "/users",
                label: 'User',
                type: "nav-item",
                icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />
            },
        ]

    },

];

export default menus;
