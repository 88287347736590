/* eslint-disable default-case */

import { BLUE_PRINTS, useDeleteCourseTeamMutation,useAddAutoGeneratedBuddiesMutation,useAddGenerateBuddiesRandomlyMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { selectedLanguageSelector,TITLE } from '../../../app/rkt_query/SettingsReducer';
import {  Delete, Edit, Visibility } from '@mui/icons-material';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';
import { useJumboDialog } from '../../components/JumboDialog/hooks/useJumboDialog';
import './app.css';
import { useNavigate } from 'react-router-dom';
import ComboBox from '../autocomplete-cb/ComboBox';
import { useLocation } from 'react-router-dom';
import { resetSettingsReducer, selectedIdSelector, } from 'app/rkt_query/GlobalReducer';
import { boxSX, filterNavigationData, handleDDData, handleDDOptionChange,handleVisibilityAndAddIcon, } from './GlobalFunctions';
import HeirarchyFlow from './HeirarchyFlow';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import { AddOutlined } from '@mui/icons-material';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ChatIcon from '@mui/icons-material/Chat';
import StoreTokenCheck from './../../../app/pages/storetoken/StoreTokenCheck';
import CoursesTeamsList from 'app/pages/courses-teams';
import { NoHideSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import CompanyUserLists from './../../../app/pages/company-users/index';
import DZDeleteDialog from './../dialog/DZDeleteDialog';
import DZConfirmationDialog from '../dialog/DZConfirmationDialog';
const DataTable = ({
  onRow = () => { },
  viewTitle1,
  viewTitle2,
  viewTitle3,
  viewTitle5,
  viewTitle4,
  showButton1=true,
  showButton2=true,
  showButton3 = true,
  showButton4 = true,
  showButton5 = true,
  isBtnTitleAdd5=false,
  ddName,
  getAllData,
  showAutoComplete,
  heirarchyId,
  relationalObject,
  listData,
  viewTitle6,
  showButton6=true,
  actionColumn=false,
  userView=false,
  ddTranslate,
  btnTitleAdd,
  relationalTitle,
  relationalId,
  teamCompanyId,
  data,
  bluePrint = BLUE_PRINTS.course,
onlyDeleteIcon=false,
  ModalContent = () => { },
  ViewModel = () => { } }) => {

  const [open, setOpen] = useState(false);
  const [teamId, setTeamId] = useState(null);
  const [openBuddies, setOpenBuddies] = useState(false);
  const [openBuddiesRandomly, setOpenBuddiesRandomly] = useState(false);
  const [courseTeamId, setCourseTeamId] = useState(null);
  const [deleteCourseTeam, responseDelete] = useDeleteCourseTeamMutation();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [generateBuddies, responseAddBuddies] =useAddAutoGeneratedBuddiesMutation();
  const [generateBuddiesRandomly, responseAddBuddiesRandomly] =useAddGenerateBuddiesRandomlyMutation();
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const location = useLocation();
  const [undo, setUndo] = useState(location?.state?.relationalObject?.name ? true : false)
  const selectedId = useSelector(selectedIdSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showDialog, hideDialog } = useJumboDialog();
  const [rows, setRows] = useState(data);
 
  const [input, setInput] = useState(relationalObject?.name ?? null)
  const [pageSize, setPageSize] = React.useState(10);
  
   //for rendering column if mention properties exists
  const isBtnTitleAdd = btnTitleAdd === TITLE.COMPANY || btnTitleAdd ===TITLE.TEAM || btnTitleAdd === TITLE.COURSE || btnTitleAdd === "BuddyQuestion";

  const columns = [...bluePrint.getCols(selectedLanguage),
  {
    field: viewTitle1, headerName: viewTitle1, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showButton1,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
      
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      
          {
            btnTitleAdd === TITLE.COMPANY || btnTitleAdd === TITLE.TEAM ?
              (
                (params?.row?.courses?.[0] || btnTitleAdd === TITLE.COURSE) &&
                <ImportContactsIcon sx={boxSX} onClick={() => handleFunction1(thisRow)} />
              )
              :
              <AddOutlined sx={boxSX} onClick={() => handleFunction1(thisRow)} />
          }
        </div>
      )
    }
  },
  {
    field: viewTitle2, headerName: viewTitle2, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showButton2,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
      
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      
          {
          
              <AddOutlined sx={boxSX} onClick={() => handleFunction2(thisRow)} />
          }
        </div>
      )
    }
  },
  {
    field: viewTitle3, headerName: viewTitle3, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showButton3,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
         
          {
            (isBtnTitleAdd) && <Diversity3OutlinedIcon sx={boxSX} onClick={() => handleFunction3(thisRow,'ViewTeam')} />

          }
        </div>
      )
    }
    },
  {
    field: viewTitle4, headerName: viewTitle4, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showButton4,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
 
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
         
          {
            (isBtnTitleAdd) && <Diversity3OutlinedIcon sx={boxSX} onClick={() => handleFunction(thisRow, 'user')} />

          }
        </div>
      )
    }
    },
  
    {
      field: viewTitle5, headerName: viewTitle5, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showButton5,
      renderCell: (params) => {
        const api = params.api;
        const thisRow = {};
  
        api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
        
        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/* <ControlPointOutlinedIcon /> */}
            {
              (isBtnTitleAdd5) && <ChatIcon sx={boxSX} onClick={() => handleFunction(thisRow, 'ViewBuddyQuestion')} />
  
            }
          </div>
        )
      }
    },
    
  {
    field: viewTitle6, headerName: viewTitle6, sortable: false, flex: 1, align: 'center', headerAlign: 'center', hide: showButton6,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
      // console.log("this row ", params?.row?.courses?.[0])
      return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
         
          {
            <HistoryEduIcon sx={boxSX} onClick={() => handleFunction3(thisRow)} />

          }
        </div>
      )
    }
  },
  {

    field: "action", headerName: "Actions", sortable: false, flex: 1, align: 'center', headerAlign: 'center',hide: actionColumn,
    renderCell: (params) => {
      const api = params.api;
      const thisRow = {};

      api.getAllColumns().filter((c) => c.field !== "__check__" && !!c).forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

      const showRecord = (e) => { e.stopPropagation(); bluePrint.showRecord(thisRow); showAddDialog(); };
      const editRecord = (e) => { e.stopPropagation(); bluePrint.editRecord(thisRow); showAddDialog(); };
      //   const deleteRecord = (e) => { e.stopPropagation(); bluePrint.deleteRecord(thisRow); deleteDialog(thisRow.id); };

      return (
        // (btnTitleAdd === TITLE.COURSE_USERS || btnTitleAdd === "Team Users" ||btnTitleAdd === TITLE.COURSE_TEAMS)
        onlyDeleteIcon ?  userView? <Visibility sx={boxSX} onClick={() => handleUserProfile({ title: "View", action: "view", row: thisRow })} />: <DeleteIcon onClick={() => deleteCourseUser(thisRow)} /> : <Box sx={{ display: 'flex', gap: 2 }}>
          <Visibility sx={boxSX} onClick={() => handleItemAction({ title: "View", action: "view", row: thisRow })} />
          <Edit sx={boxSX} onClick={() => handleItemAction({ title: "Edit", action: "edit", row: thisRow })} />
          <Delete sx={boxSX} onClick={() => handleItemAction({ title: "Delete", action: "delete", row: thisRow,open:'true' })} />
        </Box>



      )
    }
  },
  ];

  useEffect(() => {
    setRows(data);
    setRows(filterNavigationData(relationalObject, data, btnTitleAdd))
    
  }, [data])

  const handleUpdateAllRows = (e) => {

    var ch = handleDDOptionChange(btnTitleAdd, e, ddTranslate, data, selectedLanguage, getAllData)
    setRows(ch)
    { !e && dispatch(resetSettingsReducer()) }
  };

  const list = handleDDData(ddTranslate, listData, bluePrint, selectedLanguage)

  const handleFunction1 = (menuItem) => {
 (btnTitleAdd==TITLE.COURSE_TEAMS)?handleClickOpenBuddies(menuItem): handleVisibilityAndAddIcon(menuItem, navigate, dispatch, btnTitleAdd, showDialog, relationalTitle)
  }
  const handleFunction2=(menuItem)=>{
    
    handleClickOpenBuddiesRandom(menuItem);
  }
  const handleFunction = (menuItem,clickRowAttribute) => {


    if (viewTitle4 === TITLE.COMPANY_USER)
    {
      showDialog({
        title: btnTitleAdd + ' Users List ',
     
        content: <CompanyUserLists company={menuItem} />
      });

    }else  if (viewTitle5 === TITLE.TOPIC_SURVEYS) {
    
      bluePrint.showRecord(menuItem);
      navigate(`/topic-surveys`, {
        state: {
          relationalObject: menuItem,
        },
      });
    
    }   else if (clickRowAttribute === 'ViewBuddyQuestion')
    {
      navigate("/course-buddy-match-question", {
        state: {
          relationalObject: menuItem,
        },
      });
    }
  }
  const handleFunction3 = (menuItem, clickRowAttribute) => {

  
    if (btnTitleAdd === TITLE.COMPANY) {
      navigate("/teams", {
        state: {
          relationalObject: menuItem,
        },
      });
    } else 
   
    if (btnTitleAdd ===TITLE.COURSE) {
      bluePrint.showRecord(menuItem.row);

      const size = "xl";

      if (clickRowAttribute === TITLE.VIEW_TEAM)
      {
        showDialog({
          title: btnTitleAdd + ' Teams List ',
          size,
          content: <CoursesTeamsList course={menuItem} />
        });

      }
   
    }
    else  if (viewTitle6 === TITLE.TOPIC_EXERCISE) {
    
      bluePrint.showRecord(menuItem);
   navigate(`/topic-exercises`, {
          state: {
            relationalObject: menuItem,
           
          },
        });
    
    }
    else  if (viewTitle6 === TITLE.ACTIVITIES) {
    
      bluePrint.showRecord(menuItem);
      navigate(`/activities`, {
        state: {
          relationalObject: menuItem?.id,
          company: "chekc",
        },
      });
    
    }
    
    else {
      navigate("/get-team-users", {
        state: {
          relationalObject: menuItem,
          company_id: teamCompanyId,
        },
      });
    }
  }


  const handleClose = () => {
    setOpen(false);
    setOpenBuddies(false);
    setOpenBuddiesRandomly(false);

   };
  const deleteCourseUser = (menuItem) => {
 
    if (btnTitleAdd === TITLE.COURSE_TEAMS) {
      setCourseTeamId(menuItem?.id)
      setOpenSnackbar(false);
      setOpen(true);
   
    }else{
     
    showDialog({
      showLanguageSwitcher: false,
      variant: 'confirm',
      title: 'Are you sure about deleting this ' + btnTitleAdd + '?',
      content: "You won't be able to recover this " + btnTitleAdd + ' later',
      onYes: () => handleDeleteDialogue(menuItem?.id),
      onNo: hideDialog
    })
  }
  
  }

const handleUserProfile=(menuItem)=>{
// console.log(menuItem);
  navigate("/user/profile", {
    state: {
      user_id: menuItem.row,
      
    },

})
hideDialog();
};
  // handle action buttons


  const handleItemAction = (menuItem) => {

    switch (menuItem.action) {
      case 'view':
      
          if (btnTitleAdd === TITLE.COURSE) {
              // dispatch(setSelectedCourse(menuItem?.row))
              bluePrint.showRecord(menuItem.row);
              navigate("/modules", {
                state: {
                  relationalObject: menuItem.row,
                  company: "chekc",
                },
              });
            }else{
    
          bluePrint.showRecord(menuItem.row);
          showDialog({
            title: btnTitleAdd + ' Detail',
            content: <ViewModel company={menuItem?.row} />
          });
        }    
        break;

  
      case 'edit':
      bluePrint.showRecord(menuItem.row);
      showDialog({
              title: btnTitleAdd + ' Update',
              content: <ModalContent />
            });

        break;
      case 'delete':
    
        showDialog({
          showLanguageSwitcher: false,
          variant: 'confirm',
          title: 'Are you sure about deleting this ' + btnTitleAdd + '?',
          content: "You won't be able to recover this " + btnTitleAdd + ' later',
          onYes: () => handleDeleteDialogue(menuItem.row.id),
          onNo: hideDialog
        })
      
    }
    // handleActionButtons(menuItem, btnTitleAdd,  dispatch, navigate, bluePrint, showDialog, ModalContent)
  };

  const handleDeleteDialogue = (id) => {
    bluePrint.handleDelete(id);
    hideDialog();
  }

  const handleDeleteTeam = () => {

    deleteCourseTeam(courseTeamId)
    setOpenSnackbar(true)
    handleClose()

  }
  const handleRowClick = (params) => {
    console.log(params)
    onRow(params)
  };

  const size = "xl";
  const showAddDialog = React.useCallback(() => {
    showDialog({
      title: "Add " + btnTitleAdd,
      size,
      content: <ModalContent />
    });
  }, [showDialog]);



  const handleHeirarchy = (item, index) => {
  
    var name;
    if (index === 0) { name = "/companies" }
    else if (index === 1) { name = "/courses" }
    else if (index === 2) { name = "/modules" }
    else if (index === 3) { name = "/topics" }
    else if (index === 4) { name = "/learning-Journeys" }
 
    navigate(name, {
      state: {
        obj: item,
        company: item?.company
      }
    })
  }

  const handleCLoseIcon = () => {
    dispatch(resetSettingsReducer())
    if (btnTitleAdd === TITLE.COMPANY) {
      setRows(data);
    }
    else {
      navigate("/companies", {
        state: { obj: null }
      })
    }
  }
  // open random buddies form
  const handleClickOpenBuddiesRandom = (menuitem) => {
   console.log("object",menuitem?.team_id?.[0]);
setTeamId(menuitem?.team_id?.[0]);
    setOpenBuddiesRandomly(true);
    setOpenSnackbar(false);
  };
  // open buddies form
  const handleClickOpenBuddies = (menuitem) => {
   
setTeamId(menuitem?.team_id?.[0]);
    setOpenBuddies(true);
    setOpenSnackbar(false);
  };
// auto generate buddies
const AutoGenerateBuddies = () => {
  const formData = new FormData();

  formData.append("course_id", relationalId);
  formData.append("team_id", teamId);

  generateBuddies(formData)
    .unwrap()
    .then((payload) => console.log("fulfilled", payload))
    .catch((error) => console.error("rejected", error));
    setOpenBuddies(false)
};
// randomly generate buddies
const RandomlyGenerateBuddies = () => {
  const formData = new FormData();

  formData.append("course_id", relationalId);
  formData.append("team_id", teamId);

  generateBuddiesRandomly(formData)
    .unwrap()
    .then((payload) => console.log("fulfilled", payload))
    .catch((error) => console.error("rejected", error));
    setOpenBuddiesRandomly(false);
};
  return (
    <div>
      <StoreTokenCheck />
      <HeirarchyFlow heirarchyId={heirarchyId} onClickCloseButton={handleCLoseIcon} onClickItem={handleHeirarchy} selectedId={selectedId} />
      {openSnackbar ? <NoHideSnackbar responseAdd={responseDelete?.isSuccess === true ? responseDelete : ''} /> : ""}
      {responseAddBuddies?.isSuccess === true && (
        <NoHideSnackbar responseAdd={responseAddBuddies} />
      )}
      {responseAddBuddiesRandomly?.isSuccess === true && (
        <NoHideSnackbar responseAdd={responseAddBuddiesRandomly} />
      )}
      {/* AUto Generate Buddies */}
      <DZConfirmationDialog title={'Are you sure to generate auto buddies ?'}
        description={"Click On Yes To Generate"}
          openBox={openBuddies}
           handleClose={()=>handleClose()}
           handleDeleteFunctionCall={() => AutoGenerateBuddies()}/>
            {/* Randomly Generate Buddies */}
           <DZConfirmationDialog title={'Are you sure to generate  buddies randomly ?'}
        description={"Click On Yes To Generate"}
          openBox={openBuddiesRandomly}
           handleClose={()=>handleClose()}
           handleDeleteFunctionCall={() => RandomlyGenerateBuddies()}/>
    {/* delete course team */}
  <DZDeleteDialog title={'Are you sure about deleting this ' + btnTitleAdd + '?'}
        description={"You won't be able to recover this " + btnTitleAdd + ' later'}
          openBox={open}
           handleClose={()=>handleClose()}
           handleDeleteFunctionCall={() => handleDeleteTeam()}/>
      <div style={{ width: '100%' }}>
        <div style={{ marginLeft: '10px', marginRight: '10px', display: 'flex', flexDirection: 'row', width: '100%' }} >

          {(showAutoComplete && list) && (
            <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'flex-start' }}>
              <ComboBox
                btnTitleAdd={btnTitleAdd}
                data={list}
                onChange={(event, value) => { handleUpdateAllRows(value); setUndo(false) }}
                //checkUndo={undo}
                value={ddName ? ddName : TITLE.COMPANY}
                defValue={input}
                inputChange={(e, val, reason) => setInput(val)}
                width={'200%'}
              />
            </div>)
          }
          {/* for showing header ADD button  */}
          {(btnTitleAdd === TITLE.COMPANY || btnTitleAdd === TITLE.COURSE || btnTitleAdd === "User" || btnTitleAdd === "Admin") &&
            <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <HeaderButtons onClick={() => { showAddDialog(); bluePrint.addRecord(); }} />
            </div>
          }
       

        </div>
      </div>
     
      <div style={{ height: 600, width: '100%', padding: '0px 20px 0px 20px', marginTop: '2%' }}>
        {rows ?
          <>

            <DataGrid
              sx={{
                '& .MuiDataGrid-toolbarContainer': {
                  pb: 2
                },
                '& MuiDataGrid-toolbarContainer-toolbarQuickFilter': {
                  m: 20,
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              rows={rows?.map((obj) => bluePrint.translate(obj, selectedLanguage))}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onRowClick={handleRowClick}
              pagination
              checkBoxSelection
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </>
          :
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        }
      </div>
    </div>
  )
}

export default DataTable