import React from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const DZDropdownMultiple = ({ value, onChange = () => { }, sx, error, data = [],style,  fieldValueName = 'id', placeholder="Users", fieldTextName = 'name', }) => {

    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    ;
    return (

        <FormControl fullWidth sx={{ minWidth: 120 }}    style={style}>
            <InputLabel id="demo-multiple-chip-label">{placeholder}</InputLabel>
            <Select
         
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={value}
                onChange={onChange}
                // onChange={() => { handleChange(); onChange(); }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Div sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            console.log("checking", value),
                            <Chip key={value} label={value?.name} />
                        ))}
                    </Div>
                )}
                MenuProps={MenuProps}
            >
                {data.map((item) => (
                    <MenuItem
                        key={item.id}
                        value={item}
                        style={getStyles(item.name, personName, theme)}
                    >
                        <Checkbox checked={value.indexOf(item) > -1} />
                        {item.id +" \xa0\xa0\xa0"+item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

    );
};

export default DZDropdownMultiple;