import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { BLUE_PRINTS, useGetCompanyQuery, useDeleteCompanyMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector,TITLE } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Company from './Company';
import AddUpdateCourse from '../course/AddUpdateCourse';

import { setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import ShowCompany from './ShowCompany';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { reload } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
// import StoreTokenCheck from './../storetoken/StoreTokenCheck';
import useFetchCompany from './../../../@jumbo/dz-components/custom-hooks/useFetchCompany';

const CompanyList = () => {

    const location = useLocation();

    console.log("from course", location?.state?.company);
    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteCompany, responseDelete] = useDeleteCompanyMutation();
    const{res_get_all}=useFetchCompany();
 
    const [showMode, setShowMode] = useState(false);

    const bluePrint = BLUE_PRINTS.company
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCompany(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCompany(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCompany(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedCompany(row)); }
    bluePrint.handleDelete = (id) => { deleteCompany(id); }


    return (
        <>
            {/* <StoreTokenCheck /> */}
            <span>{showMode}</span>
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            <JumboDemoCard
                title="Companies"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        showAutoComplete={true}
                        relationalObject={location?.state?.relationalObject}
                        listData={res_get_all?.data?.companies}
                        ddTranslate={false}
                        showButton1={false}
                        showButton3={false}
                        showButton4={false}
                        heirarchyId={location?.state?.obj?.id}
                        translate={false}
                        companyName={location?.state?.company}
                        btnTitleAdd={TITLE.COMPANY}
                        data={res_get_all?.data?.companies}
                        bluePrint={bluePrint}
                        ViewModel={(company) => <ShowCompany modalTitle="Company Detail" company={company?.company?.id} />}
                        ModalContent={() => <Company onClose={hideDialog} />}
                        RelationalModalContent={() => <AddUpdateCourse onClose={hideDialog} />}
                        relationalTitle="Course"
                        viewTitle1="Courses"
                        viewTitle3="Teams"
                        viewTitle4={TITLE.COMPANY_USER}
                       
                    />
                    {/* <DataTable translate={false} companyName={location?.state?.company} btnTitleAdd="Company" data={res_get_all?.data?.companies} bluePrint={bluePrint} ViewModel={() => <ShowCompany modalTitle="Company Detail" />} ModalContent={() => <Company onClose={hideDialog} />} RelationalModalContent={() => <AddUpdateCourse onClose={hideDialog} />} /> */}
                    {/* <DataTable btnTitleAdd="company" data={res_get_all?.data?.companies} bluePrint={bluePrint} ModalContent={()=> showMode ? <ShowCompany/> : <Company onClose={hideDialog} />} /> */}

                </div>
            </JumboDemoCard>
        </>
    )
}

export default CompanyList