import React from 'react'
import Button from '@mui/material/Button'
import LanguageSwitcher from '../buttons/LanguageSwitcher';
import AddIcon from '@mui/icons-material/Add';

const HeaderButtons = ({ onClick = () => { }, size, Add = 'Add' }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '0px 20px 0px 20px', }}>
      {/* <LanguageSwitcher /> */}
      <Button
        size={size}
        variant="contained"
        endIcon={<AddIcon />}
        onClick={onClick}>
        {Add}
      </Button>
    </div>
  )
}

export default HeaderButtons;

export const SimpleButtons = ({ onClick = () => { }, size, title }) => {
  return (
    <div style={{ marginTop: '10px', marginRight: '0px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      {/* <LanguageSwitcher /> */}
      <Button
        size={size}
        variant="contained"
        // endIcon={<AddIcon />}
        onClick={onClick}>
        {title}
      </Button>
    </div>
  )
}