import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {useState } from 'react'
import {  useDeleteTeamUserMutation, useGetAllTeamUsersQuery, BLUE_PRINTS } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { setSelectedTeamUser } from 'app/rkt_query/SelectedStuffReducer';
import AddTeamUsers from './AddTeamUsers';
import {  Button } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import DzTable from '@jumbo/dz-components/table/DzTable';



const CourseUsersList = () => {

    const location = useLocation();
    const navigate = useNavigate();

    console.log("from course", location?.state?.relationalObject);

    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteTeamUser, responseDelete] = useDeleteTeamUserMutation();
    const { data: res_get_all, isLoading: isLoadingTeamUser, error: errorTeamUser } = useGetAllTeamUsersQuery(location?.state?.relationalObject?.id);


    const [showMode, setShowMode] = useState(false);

    const handleHideDialog = () => {
        hideDialog();

    }
    const bluePrint = BLUE_PRINTS.userCourse
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedTeamUser(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedTeamUser(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedTeamUser(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedTeamUser(row)); }
    bluePrint.handleDelete = (id) => { deleteTeamUser(id); }
    const handleTeamUserAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
    const size = "xl";
    const showAddDialog = React.useCallback(() => {
        showDialog({
            title: "Add Team Users",
            size,
            content: <AddTeamUsers selectedId={location?.state?.relationalObject?.id} onClose={() => handleHideDialog()} onSave={handleTeamUserAdd} />
        });
    }, [showDialog]);

    const handleBack = () => {

        navigate("/teams", {
            state: {
                company_id: location.state.company_id,
              },
        });
    }
 
    const columns = ['Id', 'Team'];
    const rows = [
      { id: res_get_all?.data?.team?.id, name: res_get_all?.data?.team?.name },
    ];
    const buttons = [ { label: 'Add Team Users', align:'center',icon: <AddOutlined />, onClick: showAddDialog }, ];
    return (
        <>
           
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button>

            <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Team"}  />

            <br />
            <JumboDemoCard
                title="Team Users"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        btnTitleAdd="Team Users"
                        onlyDeleteIcon={true}              
                        data={res_get_all?.data?.team_users}
                        bluePrint={bluePrint}                 
                        ModalContent={() => <AddTeamUsers onClose={hideDialog} />}                     
                        showAddButton={true}
                    />

                </div>
            </JumboDemoCard>
        </>
    )
}

export default CourseUsersList