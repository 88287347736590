import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedCourseSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetCourseQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { selectedIdSelector } from 'app/rkt_query/GlobalReducer';
import { DZText } from 'app/DZcomponents/DZText';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewCourse = ({ selectId }) => {

    const selectedCourse = useSelector(selectedCourseSelector);
    const [CourseId, setCourseId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingCourse, error: errorCourse } = useGetCourseQuery(CourseId);
    // const ViewCourse = ({showId}) => {
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.course?.image);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
        res_get?.data?.course?.image && setSelectedFile(res_get?.data?.course?.image)
        setCourseId(selectedCourse ? selectedCourse?.id : selectId)
    }, [])
    const courseArray =
        [
            { label: 'Company ', data: res_get?.data?.course?.company?.name },  
            { label: 'Order Type ', data: res_get?.data?.course?.order_type },
            { label: 'Title', data: res_get?.data?.course?.name[selectedLanguage] },
            { label: 'Is Purpose ', data: res_get?.data?.course?.is_purpose==1?"Active":"In Active" }, 
            { label: 'Buddy Feature ', data: res_get?.data?.course?.buddy_feature==1?"Active":"In Active" }, 
            { label: 'Buddy Limit', data: res_get?.data?.course?.buddy_limit }, 
            { label: 'Survey Feature ', data: res_get?.data?.course?.survey_feature==1?"Active":"In Active" }, 
            { label: 'Description', data: res_get?.data?.course?.description[selectedLanguage] },
        ]
    const { hideDialog } = useJumboDialog();
    return (
        <div>

            {errorCourse ? <div>{JSON.stringify(errorCourse)}</div>
                : isLoadingCourse ? <div>Loading Single Course.....</div>
                    : res_get?.data?.course ?
                        <DZGenericView obj={courseArray} imagePath={Config.AWSCourseOriginalImg} image={selectedFile} checkImage={true} closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewCourse


