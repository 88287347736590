import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedModuleSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetModuleQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Company from '../company/Company';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewModule = ({ selectId }) => {
    const selectedModule = useSelector(selectedModuleSelector);
    const [ModuleId, setModuleId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingModule, error: errorModule } = useGetModuleQuery(ModuleId);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const bluePrint = BLUE_PRINTS.module;
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.module?.image);
    useEffect(() => {
        res_get?.data?.module?.image && setSelectedFile(res_get?.data?.module?.image)
        setModuleId(selectedModule ? selectedModule?.id : selectId)
    }, [])
    const { hideDialog } = useJumboDialog();
    const moduleArray =
        [
            { label: 'Course Name', data: res_get?.data?.module?.course?.name[selectedLanguage] },
            { label: 'Module Name', data: res_get?.data?.module?.name[selectedLanguage] },
            { label: 'Module Description', data: res_get?.data?.module?.description[selectedLanguage] },
        ]


    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorModule ? <div>{JSON.stringify(errorModule)}</div>
                    : isLoadingModule ? <div>Loading Single Module.....</div>
                        : res_get?.data?.module ? <DZGenericView obj={moduleArray} imagePath={Config.AWSModuleOriginalImg} image={selectedFile} checkImage={true} closeBtn={true} /> : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewModule