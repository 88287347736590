import { createSlice } from '@reduxjs/toolkit';


export const slice = createSlice({
    name: 'SelectedStuffReducer',
    initialState: {
        selectedCompany: null,
        selectedCourseTeam: null,
        selectedTeam: null,
        selectedCourse: null,
        selectedCourseBuddyMatchQuestion: null,
        selectedUserCourse: null,
        selectedTeamUser: null,
        selectedModule: null,
        selectedTopic: null,
        selectedTopicExercise: null,
        selectedTopicSurvey: null,
        selectedLJ: null,
        selectedActivity: null,
        selectedAdmin: null,
        selectedUser: null,
    },
    reducers: {
        setSelectedCompany: (state, action) => {
            state.selectedCompany = action.payload;
        },
        setSelectedCourseTeam: (state, action) => {
            state.selectedCourseTeam = action.payload;
        },
        setSelectedTeam: (state, action) => {
            state.selectedTeam = action.payload;
        },

        setSelectedCourse: (state, action) => {
            state.selectedCourse = action.payload;
        },

        setSelectedCourseBuddyMatchQuestion: (state, action) => {
            state.selectedCourseBuddyMatchQuestion = action.payload;
        },

        setSelectedUserCourse: (state, action) => {
            state.selectedUserCourse = action.payload;
        },
        setSelectedTeamUser: (state, action) => {
            state.selectedTeamUser = action.payload;
        },
        setSelectedModule: (state, action) => {
            state.selectedModule = action.payload;
        },
        setSelectedTopic: (state, action) => {
            state.selectedTopic = action.payload;
        },
        setSelectedTopicExercise: (state, action) => {
            state.selectedTopicExercise = action.payload;
        },
        setSelectedTopicSurvey: (state, action) => {
            state.selectedTopicSurvey = action.payload;
        },
        setSelectedLearningJourney: (state, action) => {
            state.selectedLJ = action.payload;
        },
        setSelectedActivityType: (state, action) => {
            state.selectedActivityType = action.payload;
        },
        setSelectedActivity: (state, action) => {
            state.selectedActivity = action.payload;
        },
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload;
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        resetSelectedStuffReducer: (state) => {
            state.selectedCompany = null;
            state.selectedCourseTeam = null;
            state.selectedTeam = null;
            state.selectedCourse = null;
            state.selectedCourseBuddyMatchQuestion = null;
            state.selectedModule = null;
            state.selectedTopic = null;
            state.selectedTopicExercise = null;
            state.selectedTopicSurvey = null;
            state.selectedLJ = null;
            state.selectedActivityType = null;
            state.selectedActivity = null;
        },
    },
});

export const {
    setSelectedCompany,
    setSelectedTeam,
    setSelectedCourse,
    setSelectedCourseBuddyMatchQuestion,
    setSelectedUserCourse,
    setSelectedTeamUser,
    setSelectedCourseTeam,
    setSelectedModule,
    setSelectedTopic,
    setSelectedTopicExercise,
    setSelectedTopicSurvey,
    setSelectedLearningJourney,
    setSelectedActivityType,
    setSelectedActivity,
    setSelectedAdmin,
    setSelectedUser,
} = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const selectedCompanySelector = (state) => state.SelectedStuffReducer.selectedCompany;
export const selectedCourseTeamSelector = (state) => state.SelectedStuffReducer.selectedCourseTeam;
export const selectedTeamSelector = (state) => state.SelectedStuffReducer.selectedTeam;
export const selectedCourseSelector = (state) => state.SelectedStuffReducer.selectedCourse;
export const selectedCourseBuddyMatchQuestionSelector = (state) => state.SelectedStuffReducer.selectedCourseBuddyMatchQuestion;
export const selectedUserCourseSelector = (state) => state.SelectedStuffReducer.selectedUserCourse;
export const selectedTeamUserSelector = (state) => state.SelectedStuffReducer.selectedTeamUser;
export const selectedModuleSelector = (state) => state.SelectedStuffReducer.selectedModule;
export const selectedTopicSelector = (state) => state.SelectedStuffReducer.selectedTopic;
export const selectedTopicExerciseSelector = (state) => state.SelectedStuffReducer.selectedTopicExercise;
export const selectedTopicSurveySelector = (state) => state.SelectedStuffReducer.selectedTopicSurvey;
export const selectedLearningJourneySelector = (state) => state.SelectedStuffReducer.selectedLJ;
export const selectedActivityTypeSelector = (state) => state.SelectedStuffReducer.selectedActivityType;
export const selectedActivitySelector = (state) => state.SelectedStuffReducer.selectedActivity;
export const selectedAdminSelector = (state) => state.SelectedStuffReducer.selectedAdmin;
export const selectedUserSelector = (state) => state.SelectedStuffReducer.selectedUser;

export default slice.reducer;