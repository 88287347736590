import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { selectedLearningJourneySelector, setSelectedCourse } from 'app/rkt_query/SelectedStuffReducer';
import { useGetAllActivitiesQuery, BLUE_PRINTS, useGetActivityQuery, useDeleteActivityMutation, useGetAllLearningJourneysQuery, useGetLearningJourneyQuery } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import Activity from './Activity';
import { setSelectedActivity } from 'app/rkt_query/SelectedStuffReducer';
import ShowActivity from './ShowActivity';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import DZLabel, { DZTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import Grid from "@mui/material/Grid";
import HeaderButtons, { SimpleButtons } from './../../../@jumbo/dz-components/header-buttons/HeaderButtons';
import DZDropdown from '@jumbo/dz-components/dropdowns/DZDropdown';
import DZCard, { DZCardTranslatable } from '@jumbo/dz-components/card/DZCard';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import ShowLearningJourney from '../learning-journey/ShowLearningJourney';
import ShowCompany from './../company/ShowCompany';
import ViewCourse from '../course/ViewCourse';
import ViewModule from '../module/ViewModule';
import ViewTopic from '../topic/ViewTopic';
import { Button, IconButton } from '@mui/material';
import { boxSX } from '@jumbo/dz-components/data-table/GlobalFunctions';
// import Ordering from './Ordering';
import Ordering from './../../../@jumbo/dz-components/drag-drop-ordering/Ordering';
import Test from './Test';
import DzTable from '@jumbo/dz-components/table/DzTable';


const ActivityList = () => {

  const dispatch = useDispatch();
  //const params = useParams();
  const selectedLearningJourney = useSelector(selectedLearningJourneySelector);
  const location = useLocation();
  const [learningJourneyId, setLearningJourneyId] = useState(location?.state?.relationalObject)
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteActivity, responseDelete] = useDeleteActivityMutation();
  const { data: res_get, isLoading: isLoadingLearningJourney, error: errorLearningJourney } = useGetLearningJourneyQuery(learningJourneyId);
  const { data: res_get_all_learning_journeys, isLoading: isLoadingLearningJourneys, error: errorLearningJourneys } = useGetAllLearningJourneysQuery();
  const { data: res_get_learning_journey_activities, isLoading: isLoadingLearningJourneyActivities, error: errorLearningJourneyActivities } = useGetActivityQuery();
  const { data: res_get_all, isLoading: isLoadingActivities, error: errorActivities } = useGetAllActivitiesQuery(res_get?.data?.learning_journey?.id);
  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);


  const bluePrint = BLUE_PRINTS.activity
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedActivity(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedActivity(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedActivity(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedActivity(row)); }
  bluePrint.handleDelete = (id) => { deleteActivity(id); }
  console.log("tesssst", res_get?.data)


  const size = "xl";
  const ShowCardDialog = (menuItem, dialogName,) => {
    switch (dialogName) {
      case 'Company':
        showDialog({
          title: dialogName + ' Detail',
          size,
          content: <ShowCompany selectId={menuItem} />
        });
        break;
      case 'Course':
        showDialog({
          title: dialogName + ' Detail',
          size,
          content: <ViewCourse selectId={menuItem} />
        });
        break;
      case 'Module':
        showDialog({
          title: dialogName + ' Detail',
          size,
          content: <ViewModule selectId={menuItem} />
        });
        break;
      case 'Topic':
        showDialog({
          title: dialogName + ' Detail',
          size,
          content: <ViewTopic selectId={menuItem} />
        });
        break;
      case 'ActivityOrder':
        showDialog({
          title: 'Activity List',
          size,
          content: <Ordering dataList={res_get_all?.data?.activities} table={"activity"} />
        });
        break;
      default:
        console.error("No case Found");
        break;

    }
  }

  const ViewShowDialog = React.useCallback(() => {
    showDialog({
      title: "Learning Journey Detail",
      content: <ShowLearningJourney onClose={hideDialog} learning_journey_id={learningJourneyId} />
    });
  }, [showDialog]);
  
  const handleActivityAdd = React.useCallback(() => {
    hideDialog();
  }, [hideDialog]);
  const showAddDialog = React.useCallback(() => {
    const size = `"xl"`;
    showDialog({
      title: "Add Activity",
      size: size,
      content: <Activity onClose={hideDialog} onSave={handleActivityAdd}  learning_journey_id={learningJourneyId} />
    });
  }, [handleActivityAdd, showDialog]);
 

  const navigate = useNavigate();

  const hanldeBack = () => {
    dispatch(setSelectedCourse(res_get?.data?.learning_journey?.topic?.module?.course))
    //console.log("in navigation",   res_get?.data?.learning_journey?.topic?.module?.course)
    navigate("/modules", {
      state: {
        relationalObject: res_get?.data?.learning_journey?.topic?.module?.course,
        learningJourneyId: location?.state?.relationalObject,
        module: res_get?.data?.module,
      },
    });
  }

  // useEffect(() => {
  //     console.log(res_get_all_learning_journeys)
  // }, [res_get_all_learning_journeys])
  const learning_journeys = res_get_all_learning_journeys?.data?.learning_journeys.map((learningJourney) => (BLUE_PRINTS.learningJourney.translate(learningJourney, selectedLanguage)))
  const columns = ['Id', 'Name', 'Description'];
  const rows = [
    { id: res_get?.data?.learning_journey?.id, name: res_get?.data?.learning_journey?.name[selectedLanguage], description: res_get?.data?.learning_journey?.description[selectedLanguage] },
  ];
  const buttons = [
      { label: 'Add Activity', align:'center',icon: <ControlPointOutlinedIcon />, onClick: showAddDialog },
      { label: 'Action',align:'right', icon: <VisibilityOutlinedIcon />, onClick: ViewShowDialog },
    
    ];
  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      <Snackbar responseAdd={responseDelete} />

      <Button variant='contained' sx={{ width: '15%' }} onClick={hanldeBack} >Back</Button>

      <Grid container spacing={3.75}>
        {
          <>
            <Grid item xs={12} sm={6} lg={3}>
              <DZCard
                title={"Company"}
                data={res_get?.data?.learning_journey?.topic?.module?.course
                  ?.company}
                agentDetail={
                  res_get?.data?.learning_journey?.topic?.module?.course
                    ?.company?.name
                }
                onClickCallback={() =>
                  ShowCardDialog(
                    res_get?.data?.learning_journey?.topic?.module?.course
                      ?.company?.id,
                    "Company"
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <DZCardTranslatable
                title={"Course"}
                data={res_get?.data?.learning_journey?.topic?.module?.course?.company}
                agentDetail={
                  res_get?.data?.learning_journey?.topic?.module?.course?.name
                }
                onClickCallback={() =>
                  ShowCardDialog(
                    res_get?.data?.learning_journey?.topic?.module?.course
                      ?.id,
                    "Course"
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <DZCardTranslatable
                title={"Module"}
                data={res_get?.data?.learning_journey?.topic?.module}
                agentDetail={
                  res_get?.data?.learning_journey?.topic?.module?.name
                }
                onClickCallback={() =>
                  ShowCardDialog(
                    res_get?.data?.learning_journey?.topic?.module?.id,
                    "Module"
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <DZCardTranslatable
                title={"Topic"}
                data={res_get?.data?.learning_journey?.topic}
                agentDetail={res_get?.data?.learning_journey?.topic?.name}
                onClickCallback={() =>
                  ShowCardDialog(
                    res_get?.data?.learning_journey?.topic?.id,
                    "Topic"
                  )
                }
              />
            </Grid>
          </>
        }
      </Grid>

      <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Learning Journey"}  />

      {<SimpleButtons title={"Activity Ordering"} onClick={() =>
        ShowCardDialog(
          res_get_all?.data?.activities,
          "ActivityOrder"
        )
      } />}
      <JumboDemoCard
        title="Activities"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable
            heirarchyId={location?.state?.obj?.id}
            listData={res_get_all_learning_journeys?.data?.learning_journeys}
            ddTranslate={true}
            translate={true}
            relationalObject={location?.state?.relationalObject}
            btnTitleAdd="Activity"
            data={res_get_all?.data?.activities}
            bluePrint={bluePrint}
            ViewModel={() => <ShowActivity />}
            ModalContent={() => <Activity onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}
          />
       
        </div>
      </JumboDemoCard>
    </>
  );
}


export default ActivityList