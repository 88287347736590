import React, { useState, useEffect } from 'react'
import SnackbarAlert from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Portal } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Snackbar = ({ responseAdd }) => {
    const [open, setOpen] = useState(true);
    const { hideDialog } = useJumboDialog();
    useEffect(() => {
        handleClick()
        //console.log("click")
    }, [])


    const handleClick = () => {

        setOpen(true);

    };

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }
        hideDialog();
        setOpen(false);

    };
    //console.log(responseAdd);
    return (
        <>

            {responseAdd?.isSuccess &&

                <SnackbarAlert autoHideDuration={1500} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {responseAdd?.data?.message}
                    </Alert>
                </SnackbarAlert>

            }

            {/* {
                responseAdd?.isError && <SnackbarAlert autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {responseAdd?.data?.message}
                    </Alert>
                </SnackbarAlert>
            } */}
        </>
    )
}


export default Snackbar


export const NoHideSnackbar = ({ responseAdd }) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        handleClick()
        //console.log("click")
    }, [])


    const handleClick = () => {

        setOpen(true);

    };

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };
    //console.log(responseAdd);
    return (
        <>

            {responseAdd?.isSuccess &&

                <SnackbarAlert autoHideDuration={1500} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {responseAdd?.data?.message}
                    </Alert>
                </SnackbarAlert>

            }

            {/* {
                responseAdd?.isError && <SnackbarAlert autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {responseAdd?.data?.message}
                    </Alert>
                </SnackbarAlert>
            } */}
        </>
    )
}

// snackbar for general purpose
export const GeneralSnackbar = ({ response = "name", showError }) => {
    const [open, setOpen] = useState(true);
    const [response1, setResponse1] = useState(false);





    useEffect(() => {
        handleClick()
        setResponse1(response)

        //console.log("click")
    }, [])


    const handleClick = () => {

        setOpen(true);

    };
    //console.log(response)
    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };

    return (
        <>

            {response?.success && <SnackbarAlert autoHideDuration={30000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {response?.message}
                </Alert>
            </SnackbarAlert>

            }

            {
                response1 && <SnackbarAlert autoHideDuration={60000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {"response"}
                    </Alert>
                </SnackbarAlert>
            }
        </>
    )
}

///
export const LoginSnackbar = ({ response, message }) => {
    const [open, setOpen] = useState(true);
    const [response1, setResponse1] = useState(true);
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        handleClick()
        setResponse1(message)
        setSuccess(response)
        //console.log("click")
    }, [])


    const handleClick = () => {

        setOpen(true);

    };

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };

    return (
        <>
            {/* 
            {response && <SnackbarAlert autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </SnackbarAlert>

            } */}

            {
                success && <SnackbarAlert autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} onClose={handleClose}  >

                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {response1}
                    </Alert>
                </SnackbarAlert>
            }
        </>
    )
}


