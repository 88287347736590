import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab, Box } from "@mui/material";
import {
  selectedLearningJourneySelector,
  setSelectedLearningJourney,
  selectedTopicSelector,
  setSelectedTopic,
  selectedCourseSelector,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddLearningJourneyMutation,
  useGetLearningJourneyQuery,
  useUpdateLearningJourneyMutation,
  useGetAllTopicsQuery,
  useGetTopicQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, { DZSimpleDropDown } from "./../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "./../../../@jumbo/dz-components/textboxes/DZTextField";
import ComboBox from "../../../@jumbo/dz-components/autocomplete-cb/ComboBox";
import { useJumboDialog } from "./../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { DzNoBackgroundTranslatable } from "@jumbo/dz-components/labels/DZLabel";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";

const LearningJourney = ({ onClose, topic, learningJourney }) => {

  const duration_types = ['hour', 'day', 'week'];
  const dispatch = useDispatch();
  const selectedLearningJourney = useSelector(selectedLearningJourneySelector);
  const selectedTopic = useSelector(selectedTopicSelector);
  const selectedCourse = useSelector(selectedCourseSelector);
  const {
    data: res_get_all,
    isLoading: isLoadingTopics,
    error: errorTopics,
  } = useGetAllTopicsQuery();
  const {
    data: res_get,
    isLoading: isLoadingLearningJourney,
    error: errorLearningJourney,
  } = useGetLearningJourneyQuery(
    learningJourney?.id ?? selectedLearningJourney?.id
  );
  const {
    data: res_get1,
    isLoading: isLoadingTopic,
    error: errorTopic,
  } = useGetTopicQuery(topic?.id ?? selectedTopic?.id);

  const [updateLearningJourney, responseUpdate] =
    useUpdateLearningJourneyMutation();
  const [addLearningJourney, responseAdd] = useAddLearningJourneyMutation();
  const bluePrint = BLUE_PRINTS.learningJourney;
  const [learningJourneyState, setLearningJourneyState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [disable, setDisabled] = useState([true]);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(
    res_get?.data?.learning_journey?.image
  );

  const addUpdateLearningJourneyData = (action) => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("id", res_get?.data?.learning_journey?.id);
    formData.append("name", JSON.stringify(learningJourneyState.name));
    formData.append("topic_id", learningJourneyState?.topic_id);
    formData.append("duration", learningJourneyState?.duration);
    formData.append("duration_minutes", learningJourneyState?.duration_minutes);
    formData.append("duration_hours", learningJourneyState?.duration_hours );
    formData.append("duration_week", learningJourneyState?.duration_week );
    formData.append("duration_days", learningJourneyState?.duration_days );
    formData.append("duration_minutes_type", learningJourneyState?.duration_minutes_type );
    formData.append( "description", JSON.stringify(learningJourneyState.description) );
    action === "add"
      ? addLearningJourney(formData)
      : updateLearningJourney(formData);
  };
  //this code is use to control infinity loops and backend required error.
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);



  useEffect(() => {
    res_get?.data?.learning_journey?.image &&
      setSelectedFile(res_get?.data?.learning_journey?.image);
      const { hours, days, weeks, minutes }=  minutesToHourDayWeek(res_get?.data?.learning_journey?.duration_minutes,res_get?.data?.learning_journey?.duration_minutes_type)
    setLearningJourneyState(
      selectedLearningJourney || learningJourney?.id
        ? { ...res_get?.data?.learning_journey, course_id: selectedCourse?.id, duration_hours: hours,duration_minutes: minutes,duration_days: days,duration_week: weeks}
        : { ...bluePrint, topic_id: res_get1?.data?.topic?.id ?? "" }
    );

 
  }, [res_get, res_get1]);

  function minutesToHourDayWeek(minutes, type) {
    let hours, days, weeks;
    if (type === 'hour') {
      hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return { hours, days: 0, weeks: 0, minutes: remainingMinutes };
    } else if (type === 'day') {
      days = Math.floor(minutes / 1440);
      return { hours: 0, days, weeks: 0, minutes: 0 };
    } else if (type === 'week') {
      days = Math.floor(minutes / 1440);
      weeks = Math.floor(days / 7);
      return { hours: 0, days: 0, weeks, minutes: 0 };
    }
  
    return { hours: 0, days: 0, weeks: 0, minutes: 0 };
  }
  

  const topics = res_get_all?.data?.topics.map((topic) =>
    BLUE_PRINTS.topic.translate(topic, selectedLanguage)
  );

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const learningJourneyFields = [
    { label: "Name ", key: "name", multilingual: true, errorKey: ["0"] },
    // { label: "Duration Hours", key: "duration_hours",  type: "number" },
    // { label: "Duration Minutes", key: "duration_minutes",  type: "number" },
    // { label: "Duration Days", key: "duration_days",  type: "number" },
    // { label: "Duration Weeks", key: "duration_week",  type: "number" },
    // { label: "Duration", key: "duration",  type: "number" },
    { label: "Description", key: "description", multilingual: true, rows: 3 },
  ];

  const renderLearningJourneyFields = () => {
    return learningJourneyFields.map((field) => {
      if (field?.type === "number") {
        return (
          
          <TextField
            key={field.label}
            type="number"
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: 1 }}
            {...(error?.[field.key] && {
              error: true,
              helperText: error?.[field.key],
            })}
            label={field.label}
            variant="outlined"
            value={learningJourneyState?.[field.key]}
            onChange={(e) => {
              setLearningJourneyState((prevState) => ({
                ...prevState,
                [field.key]: e.target.value,
              }));
            }}
          />
        );
      } else {
        return (
          <DZTextValidation
            key={field.label}
            label={field.label}
            variant="outlined"
            noOfRows={field?.rows}
            value={
              field.multilingual
                ? learningJourneyState?.[field.key]?.[selectedLanguage]
                : learningJourneyState?.[field.key]
            }
            error={error?.[field?.errorKey]}
            onChange={(e) => {
              if (field?.multilingual) {
                const updatedData = {
                  ...learningJourneyState[field.key],
                  [selectedLanguage]: e.target.value,
                };
                setLearningJourneyState((prevState) => ({
                  ...prevState,
                  [field.key]: updatedData,
                }));
              } else {
                setLearningJourneyState((prevState) => ({
                  ...prevState,
                  [field.key]: e.target.value,
                }));
              }
            }}
          />
        );
      }
    });
  };
  console.log("object",learningJourneyState);
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {learningJourneyState ? (
        <div style={addUpdateDivStyleGlobal}>
          {imagePreview ? (
            <img
              src={imagePreview}
              alt={res_get?.data?.learning_journey?.image}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          ) : (
            <DZImageCircle
              image={selectedFile}
              link={Config.AWSLearningJourneyOriginalImg}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>
          {topic?.id ? (
            <DzNoBackgroundTranslatable
              title={"Topic"}
              children={res_get1?.data?.topic?.name}
            />
          ) : (
            <DzNoBackgroundTranslatable
              title={"Topic"}
              children={res_get?.data?.learning_journey?.topic?.name}
            />
          )}
        
         {learningJourneyState?.duration_minutes_type && <DZSimpleDropDown value={learningJourneyState?.duration_minutes_type} data={duration_types} name="duration_minutes_type"
            placeholder="Select Duration Type" onChange={(e) => { setLearningJourneyState(x => ({ ...x, duration_minutes_type: e.target.value, })) }} />}
           
                    
              {  
                learningJourneyState?.duration_minutes_type=='hour'&& 
                <>
                <TextField type='number'   InputLabelProps={{ shrink: true }}  inputProps={{ min: 0,max:23 }}  label="Duration Hours" variant="outlined" value={learningJourneyState?.duration_hours}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, duration_hours: e.target.value })) }}
                    />
                  

               <TextField type='number' InputLabelProps={{ shrink: true }}  inputProps={{ min: 0,max:59 }} label="Duration Minutes" variant="outlined" value={learningJourneyState?.duration_minutes}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, duration_minutes: e.target.value })) }}
                    /></>}
              {  
                learningJourneyState?.duration_minutes_type=='day'&& 
                <>
                <TextField type='number'   InputLabelProps={{ shrink: true }} inputProps={{ min: 0, }}  label="Duration In Days" variant="outlined" value={learningJourneyState?.duration_days}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, duration_days: e.target.value })) }}
                    />
                  

             </>}
              {  
                learningJourneyState?.duration_minutes_type=='week'&& 
                <>
                <TextField type='number'   InputLabelProps={{ shrink: true }} inputProps={{ min: 0 }}  label="Duration In Weeks" variant="outlined" value={learningJourneyState?.duration_week}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, duration_week: e.target.value })) }}
                    />
                  

             </>}
                    
          {/* <DZDropdown error={error?.topic_id} disabled={disable} value={learningJourneyState?.topic_id} data={topics}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, topic_id: e.target.value, })) }} placeholder="Select Topic" /> */}
          {renderLearningJourneyFields()}
          {/* <DZTextValidation error={error?.["0"]} label="Name" variant="outlined" value={learningJourneyState?.name?.[selectedLanguage]}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })) }}
                    />
                    <TextField type='number' InputLabelProps={{ shrink: true }} inputProps={{ min: 1 }} {...(error?.duration && { error: true, helperText: error?.duration })} label="Duration" variant="outlined" value={learningJourneyState?.duration}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, duration: e.target.value })) }}
                    />

                    <DZTextField label="Description" variant="outlined" noOfRows={3} value={learningJourneyState?.description?.[selectedLanguage]}
                        onChange={(e) => { setLearningJourneyState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
                    /> */}
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedLearningJourney(null));
              }}
            >
              Cancel
            </Button>
            {selectedLearningJourney?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLearningJourneyData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLearningJourneyData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLearningJourney(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedLearningJourney(null)); */}
          {/* <div style={{ width: '10%' }}>{JSON.stringify(learningJourneyState)}</div> */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
      {/* {!selectedLearningJourney &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}
    </div>
  );
};

export default LearningJourney;
