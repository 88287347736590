
import { Button, TextField } from '@mui/material';
import { selectedTeamSelector, setSelectedTeam } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddTeamMutation, useGetTeamQuery, useUpdateTeamMutation, useGetAllTeamsQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import DZTextField from '../../../@jumbo/dz-components/textboxes/DZTextField';

import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';
import Div from '@jumbo/shared/Div';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';

const Team = ({ company_id }) => {

    const dispatch = useDispatch();
    const selectedTeam = useSelector(selectedTeamSelector);
    const { data: res_get_all, isLoading: isLoadingTeams, error: errorTeams } = useGetAllTeamsQuery();
    const { data: res_get, isLoading: isLoadingTeam, error: errorTeam } = useGetTeamQuery(selectedTeam?.id);
    const [updateTeam, responseUpdate,] = useUpdateTeamMutation();
    const [addTeam, responseAdd,] = useAddTeamMutation();
    const bluePrint = BLUE_PRINTS.team;
    const [teamState, setTeamState] = useState(null);
    const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);




      //this code is use to control infinity loops and backend required error. 
      const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);
    // const countZero = () => {
    //     setCount(true)
    // }

    // if (responseAdd?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }

    // if (responseUpdate?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseUpdate?.error?.data?.errors)
    // }

    //console.log(error)
    useEffect(() => {


        setTeamState(selectedTeam ? res_get?.data?.team : { ...bluePrint, company_id: company_id ?? "" });
        // setActivityState(selectedActivity ? res_get?.data?.activity : { ...bluePrint, learning_journey_id: learning_journey_id ?? '' });
    }, [res_get])



    console.log('selectedTeam out of useEffect: ', res_get?.data?.team);

    const teams = res_get_all?.data?.teams.map((team) => (team))
    return (
        <Div>
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />

            {teamState ?
                <Div style={addUpdateDivStyleGlobal}>
                    {/* <LanguageSwitcher /> */}
                    <DZTextValidation label="Team" variant="outlined" value={teamState?.name}
                        onChange={(e) => { setTeamState(x => ({ ...x, name: e.target.value })) }}
                        error={error?.name}
                    />

                    <Div style={dialogButtonsStyle} >
                        <Button variant="outlined" onClick={() => { hideDialog() }}>Cancel</Button>
                        {
                            selectedTeam ? <Button variant="contained" onClick={() => { updateTeam(teamState); setShowBackDrop(true); resetCount(); }}>Update</Button>

                                : <Button variant="contained" onClick={() => { addTeam(teamState); setShowBackDrop(true); resetCount(); }}>Add</Button>
                        }
                        {/* dispatch(setSelectedCompany(null)); */}
                    </Div>
                    {/* <Div style={{ width: '10%' }}>{JSON.stringify(companyState)}</Div> */}
                </Div>
                : <Div>Loading....</Div>
            }
            {/* {!selectedCompany &&
        (responseAdd.isLoading?<Div>Loading.....</Div>
        : responseAdd.isError?<Div>{JSON.stringify(responseAdd.error)}</Div>
        : responseAdd.isSuccess?<Div>{JSON.stringify(responseAdd.data)}.</Div>
        : <Div>Click Add Button .....</Div>)
      } */}

        </Div>
    )
}

export default Team