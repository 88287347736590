import React from 'react'
import ListItemComponent from './ListItemComponent'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {Button, ListItem, ListItemAvatar,List,ListItemIcon, ListItemText, Typography} from "@mui/material";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from 'react-redux';

const BuddyItem = ({buddy_group,index}) => {

    console.log(buddy_group?.group?.buddy_group_users[0]?.user?.name,"sdfdsf");
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

    };
    const selectedLanguage = useSelector(selectedLanguageSelector);
  return (
    <JumboCardQuick
    title={""}
    
    headerSx={{
        borderBottom: 1, borderColor: 'divider'
    }}
    sx={{mb: 3.75}}

>
  <Accordion expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)}  >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{width: '100%' }}
                    >
                        {/* <Typography sx={{width: '33%', flexShrink: 0}}>
                            {"Buddy Group"+index}
                        </Typography> */}
        <ListItemComponent data={"Group "+index} label={ "Buddy "}   />
        <ListItemComponent data={buddy_group?.group?.course?.name[selectedLanguage]} label={"Course"}   />
        <ListItemComponent data={buddy_group?.group?.team?.name} label={"Team"}   />
 

                       
                        
                    </AccordionSummary>
                    <AccordionDetails  >
                    <List
                disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, -2),
                }}
            >
                        {buddy_group?.group?.buddy_group_users?.map((buddy_user,index)=>(
            <>
            <ListItemComponent data={buddy_user?.user?.id} label={"Id"}   />
            <ListItemComponent data={buddy_user?.user?.name} label={"Name"}   />
            <ListItemComponent data={buddy_user?.user?.email} label={"Email"}   />
</>
        ))}
        </List>
                        {/* </Typography> */}
                    </AccordionDetails>
                </Accordion>
    {/* <List
        disablePadding
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme => theme.spacing(0, -2),
        }}
    > */}
    
        {/* <ListItemComponent data={"sadsd"} label={"Course"} icon={<MenuBookOutlinedIcon fontSize={"inherit"}/>}  /> */}
        {/* <ListItemComponent data={user_course?.course?.order_type} label={"Order Type"} icon={<BorderColorOutlinedIcon fontSize={"inherit"}/>}   />
        <ListItemComponent data={user_course?.course?.is_purpose==1?'Yes':'No'} label={"Is Purpose"} icon={<CrisisAlertOutlinedIcon fontSize={"inherit"}/>}   />
        <ListItemComponent data={user_course?.course?.buddy_feature==1?'Active':'In Active'} label={"Buddy Feature"} icon={<SubtitlesOutlinedIcon fontSize={"inherit"}/>}   />
        <ListItemComponent data={user_course?.course?.survey_feature==1?'Active':'In Active'} label={"Survey Feature"} icon={<PollOutlinedIcon fontSize={"inherit"}/>}   />
        <ListItemComponent data={formattedDate??"No"} label={"Course Start Date"} icon={<AccessTimeOutlinedIcon fontSize={"inherit"}/>}   /> */}

    {/* </List> */}
 </JumboCardQuick>
  )
}

export default BuddyItem