import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllCoursesQuery, BLUE_PRINTS, useGetCourseQuery, useDeleteCourseMutation, useGetAllGlobalSettingsQuery, useAddGlobalSettingsMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
//import Course from './AddUpdateCourse';
import { setSelectedCourse } from 'app/rkt_query/SelectedStuffReducer';
//import ViewCourse from './ViewCourse';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import StoreTokenCheck from '../storetoken/StoreTokenCheck';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';
import DZDropdown from './../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import Grid from "@mui/material/Grid";


const GlobalSettings = () => {

  const [addGlobalSettings, responseAdd,] = useAddGlobalSettingsMutation();
  const [globalSettingState, setGlobalSettingState] = useState(null);
  // const dispatch = useDispatch();

  const { data: res_get_all, isLoading: isLoadingCourses, error: errorCourses } = useGetAllGlobalSettingsQuery();
  const [showMode, setShowMode] = useState(false);

  const selectedLanguage = useSelector(selectedLanguageSelector);

  const bluePrint = BLUE_PRINTS.globalSettings
  // bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedCourse(null)); }
  // bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedCourse(row)); }
  // bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedCourse(row)); }
  // bluePrint.deleteRecord = (row) => { dispatch(setSelectedCourse(row)); }


  console.log('data', res_get_all?.data?.globalSettings[0]);

  useEffect(() => {
    setGlobalSettingState(res_get_all?.data?.globalSettings[0] ?? bluePrint);
    console.log("mutation",)
  }, [res_get_all])

  // const reloadPage = () => {
  //    window.location.reload()
  // }

  //if (responseDelete?.isSuccess) { reloadPage() }

  return (
    <>
      <StoreTokenCheck />
      <span>{showMode}</span>
      {responseAdd.isLoading === true && <SimpleBackdrop responseAdd={responseAdd} />}
      {responseAdd?.isSuccess === true && <Snackbar responseAdd={responseAdd} />}

      {globalSettingState ?

        <>
          {/* <div style={{ width: '10%' }}>{JSON.stringify(globalSettingState)}</div> */}

          <JumboDemoCard
            title={"Global Settings"}
            wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
            <div style={{ width: '100%', height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ width: '50%', height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 12 }}>

                <input type="hidden" id="hdnGlobalSetting" name="id" value={globalSettingState?.id} onChange={(e) => { setGlobalSettingState(x => ({ ...x, id: e.target.value })) }} />
                <Grid container spacing={3.75}>
                  <Grid item xs={12} md={6} lg={6}>
                    <DZTextField sx={{
                      width: { sm: 200, md: 500 },

                    }} noOfRows={1} label="Welcome Title" multiline maxRows={1} fullWidth value={globalSettingState?.welcome_title[selectedLanguage]}
                      onChange={(e) => { setGlobalSettingState(x => ({ ...x, welcome_title: { ...x.welcome_title, [selectedLanguage]: e.target.value } })) }} />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>

                    <DZTextField sx={{
                      width: { sm: 200, md: 500 },

                    }} label="Welcome Detail" variant="outlined" noOfRows={3} value={globalSettingState?.welcome_detail[selectedLanguage]}
                      onChange={(e) => { setGlobalSettingState(x => ({ ...x, welcome_detail: { ...x.welcome_detail, [selectedLanguage]: e.target.value } })) }}
                    />
                  </Grid>
                </Grid>


                <div style={{ width: '46%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <Button variant='contained' onClick={() => { addGlobalSettings(globalSettingState); }} >save</Button>
                </div>
              </div>
            </div>
          </JumboDemoCard>
        </>
        : <div>Loading...</div>
      }

    </>
  )
}


export default GlobalSettings