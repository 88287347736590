import React from 'react';
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {List,Link, ListItem, ListItemIcon, ListItemText, Typography,Tab} from "@mui/material";
import ManageHistoryOutlinedIcon from '@mui/icons-material/ManageHistoryOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import JumboScrollbar from '@jumbo/components/JumboScrollbar/JumboScrollbar';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CourseItem from './CourseItem';
import ListItemComponent from './ListItemComponent';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import BuddyItem from './BuddyItem';


const StyledListItemIcon = styled(ListItemIcon)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
    height: 48,
    width: 48,
    borderRadius: '50%',
    minWidth: 42,
    marginRight: 16,
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    border: `solid 1px ${theme.palette.divider}`
}));


const UserProfileTab = ({user,buddy_groups}) => {

// console.log(buddy_groups?.group[1]);
    const [value, setValue] = React.useState("profile");
    const selectedLanguage = useSelector(selectedLanguageSelector);

    return (
        <Grid container spacing={3.75}>
         <Grid item xs={12} md={8} lg={12}>
         <JumboCardQuick title={"Profile"} noWrapper>

<TabContext value={value}>
       <TabList
           sx={{borderBottom: 1, borderColor: 'divider'}}
           onChange={(event, newValue) => setValue(newValue)}
       >
           <Tab label={"Profile"} value={"profile"} sx={{flex: '1 1 auto'}}/>
           <Tab label={"Teams"} value={"team"} sx={{flex: '1 1 auto'}}/>
           <Tab label={"Courses"} value={"course"} sx={{flex: '1 1 auto'}}/>
           <Tab label={"Buddy Groups"} value={"buddy"} sx={{flex: '1 1 auto'}}/>
           {/* <Tab label={"Feeds"} value={"feed"} sx={{flex: '1 1 auto'}}/> */}
       </TabList>
       <TabPanel value="profile" sx={{p: 0}}>
           <JumboScrollbar
               autoHeight
               autoHeightMin={448}
               autoHide
               autoHideDuration={200}
               autoHideTimeout={500}
           >
             <List disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, 1),
                }}>
                <ListItemComponent data={user?.name} label={"Name"} icon={<BadgeOutlinedIcon fontSize={"inherit"}/>}  />
                <ListItemComponent data={user?.email} label={"Email"} icon={<EmailOutlinedIcon fontSize={"inherit"}/>}   />
                <ListItemComponent data={user?.company?.name} label={"Company"} icon={<LocationCityOutlinedIcon fontSize={"inherit"}/>}   />
                <ListItemComponent data={user?.title} label={"Title"} icon={<SubtitlesOutlinedIcon fontSize={"inherit"}/>}   />
                <ListItemComponent data={user?.age} label={"Age"} icon={<ManageHistoryOutlinedIcon fontSize={"inherit"}/>}   />
                               
   </List>
           </JumboScrollbar>
       </TabPanel>
       <TabPanel value="team" sx={{p: 0}}>
           <JumboScrollbar
               autoHeight
               autoHeightMin={448}
               autoHide
               autoHideDuration={200}
               autoHideTimeout={500}
           >
                 <List disablePadding sx={{mb: 2}}>
                        <List disablePadding
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: theme => theme.spacing(0, 1),
                }}>
                 {
                    user?.teams?.map((team, index) => (
                <ListItemComponent data={team?.team?.name} label={"Team"} icon={<Diversity2OutlinedIcon fontSize={"inherit"}/>}  />
            
                      
                    ))
                }
   </List>
   </List>
           </JumboScrollbar>
       </TabPanel>
       <TabPanel value="course" sx={{p: 0}}>
           <JumboScrollbar
               autoHeight
               autoHeightMin={448}
               autoHide
               autoHideDuration={200}
               autoHideTimeout={500}
           >
                 <List disablePadding sx={{mb: 2}}>
                 {
                    user?.user_courses?.map((course, index) => (
                        <>
                        <CourseItem user_course={course} key={index}/>
                        </>
                    ))
                }
   </List>
           </JumboScrollbar>
       </TabPanel>

       <TabPanel value="buddy" sx={{p: 0}}>
           <JumboScrollbar
               autoHeight
               autoHeightMin={448}
               autoHide
               autoHideDuration={200}
               autoHideTimeout={500}
           >
                 <List disablePadding
                sx={{
                 mb:2
                }}>
                 {
                    buddy_groups?.map((buddy_group, index) => (
                        <>
                        
                        <BuddyItem buddy_group={buddy_group} index={index+1}  />
                        </>
                    ))
                }
                  {/* <ListItemComponent data={buddy_group?.course?.name[selectedLanguage]} label={"Course"} icon={<MenuBookOutlinedIcon fontSize={"inherit"}/>}  /> */}
                        {/* <ListItemComponent data={buddy_group?.team?.name} label={"Team"} icon={<Diversity2OutlinedIcon fontSize={"inherit"}/>}  /> */}
   </List>
           </JumboScrollbar>
       </TabPanel>
    
   </TabContext>

</JumboCardQuick>
            

         </Grid>
    
        
    </Grid>
    );
};

export default UserProfileTab;
