import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'
import { useDeleteUserCourseMutation, useGetAllUserCoursesQuery, BLUE_PRINTS } from 'app/rkt_query/storeApis';
import { useDispatch} from 'react-redux';
import { TITLE } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { setSelectedUserCourse } from 'app/rkt_query/SelectedStuffReducer';
import AddCourseUsers from './AddCourseUsers';
import Paper from '@mui/material/Paper';
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableBody } from '@mui/material';
import { DZText } from 'app/DZcomponents/DZText';
import TableContainer from '@mui/material/TableContainer';
import {  IconButton, Button,  } from '@mui/material';
import ViewCourse from './../course/ViewCourse';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { AddOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';



const CourseUsersList = () => {

    const location = useLocation();
    const navigate = useNavigate();

   
    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteUserCourse, responseDelete] = useDeleteUserCourseMutation();
    const { data: res_get_all, isLoading: isLoadingUserCourse, error: errorUserCourse } = useGetAllUserCoursesQuery(location?.state?.relationalObject?.id);
    const [showMode, setShowMode] = useState(false);

    const handleHideDialog = () => {
        hideDialog();

    }
    const bluePrint = BLUE_PRINTS.userCourse
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedUserCourse(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedUserCourse(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedUserCourse(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedUserCourse(row)); }
    bluePrint.handleDelete = (id) => { deleteUserCourse(id); }
    const handleUserCourseAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
    const size = "xl";
    const showAddDialog = React.useCallback(() => {
        showDialog({
            title: "Add Course Users",
            size,
            content: <AddCourseUsers selectedId={location?.state?.relationalObject?.id} onClose={() => handleHideDialog()} onSave={handleUserCourseAdd} />
        });
    }, [showDialog]);
    const handleCourseView = () => {
        showDialog({
            title: 'Course Detail',
            size,
            content: <ViewCourse selectId={res_get_all?.data?.course?.id} />
        });
    }
    const handleBack = () => {

        navigate("/courses", {

        });
    }
    console.log(res_get_all?.data?.user_courses[0]?.course?.users)


    return (
        <>
         
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button>

            <TableContainer component={Paper} >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <h2 style={{ marginLeft: '12px', fontWeight: 'normal' }}>Course Users</h2>
                        <TableRow>
                            <TableCell align="left">Course</TableCell>
                            <TableCell align="left">Company</TableCell>
                            <TableCell align="center">Add Course Users</TableCell>
                            <TableCell align="right">View Course</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {


                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                                <TableCell component="th" scope="row">
                                    <DZText>{res_get_all?.data?.course?.name}</DZText>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <DZText>{res_get_all?.data?.course?.company?.name}</DZText>
                                </TableCell>
                             

                                <TableCell align="center">
                                    <IconButton
                                        onClick={() => showAddDialog()}
                                        aria-label="add User Course" component="label"
                                    >
                                        <AddOutlined />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="right">
                                    {
                                        <IconButton onClick={() => handleCourseView()}>
                                            <VisibilityOutlinedIcon onClick={() => handleCourseView()} />
                                        </IconButton>
                                    }
                                </TableCell>
                            </TableRow>

                        }


                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <JumboDemoCard
                title="Course Users"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        
                        btnTitleAdd={TITLE.COURSE_USERS}
                 
                        onlyDeleteIcon={true}
                        data={res_get_all?.data?.user_courses}
                        bluePrint={bluePrint}
                     
                        ModalContent={() => <AddCourseUsers onClose={hideDialog} />}
                    
                        showAddButton={true}
                    />

                </div>
            </JumboDemoCard>
        </>
    )
}

export default CourseUsersList