import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField, Fab, Box } from '@mui/material';
import { selectedTopicSurveySelector, setSelectedTopicSurvey, selectedTopicSelector, setSelectedTopic, selectedCourseSelector } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddTopicSurveyMutation,  useGetTopicSurveyQuery, useUpdateTopicSurveyMutation, useGetTopicQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown, { DZSimpleDropDown } from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField from '../../../@jumbo/dz-components/textboxes/DZTextField';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar, { NoHideSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { DzNoBackgroundTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';

import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';


const AddUpdateTopicSurvey = ({ topic }) => {
    const reply_types = [ 'scale'];

    const dispatch = useDispatch();
    const selectedTopicSurvey = useSelector(selectedTopicSurveySelector);
    const selectedTopic = useSelector(selectedTopicSelector);



    const { data: res_get, isLoading: isLoadingTopicSurvey, error: errorTopicSurvey } = useGetTopicSurveyQuery(selectedTopicSurvey?.id);
    const { data: res_get1, isLoading: isLoadingTopic, error: errorTopic } = useGetTopicQuery(topic?.id ?? selectedTopic?.id);

    const [updateTopicSurvey, responseUpdate,] = useUpdateTopicSurveyMutation();
    const [addTopicSurvey, responseAdd,] = useAddTopicSurveyMutation();


    const bluePrint = BLUE_PRINTS.topicSurvey;
    const [topicSurveyState, setTopicSurveyState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);

    const [showBackDrop, setShowBackDrop] = useState(false);
    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);
    const [disable, setDisabled] = useState([true]);

  

    const { showDialog, hideDialog } = useJumboDialog();

    

    const addUpdateTopicSurveyData = (action) => {
        const formData = new FormData();
       
        formData.append('id', res_get?.data?.topic_survey?.id);
        formData.append('title', JSON.stringify(topicSurveyState.title));
        formData.append('topic_id', topicSurveyState?.topic_id);
        formData.append('type', topicSurveyState?.type);
        // formData.append('min_options', topicSurveyState.min_options);
        // formData.append('max_options', topicSurveyState.max_options);
        formData.append('order', topicSurveyState.order);

       

        action === "add" ? addTopicSurvey(formData) : updateTopicSurvey(formData);

    }


  //this code is use to control infinity loops and backend required error. 
  const {error,resetCount}= useResponseError(responseAdd,responseUpdate);

 


    useEffect(() => {

        
        setTopicSurveyState(selectedTopicSurvey ? res_get?.data?.topic_survey : { ...bluePrint, topic_id: topic?.id ?? '' });
      

        setDisabled(selectedTopicSurvey ? false : true);

        if (res_get1?.data?.topic?.id) {

        }
    }, [res_get, res_get1])






    return (
        <div>
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />
 

            {topicSurveyState ?
                <div style={addUpdateDivStyleGlobal}>
                    
                
                    {topic?.id ? <DzNoBackgroundTranslatable title={"Topic"} children={res_get1?.data?.topic?.name} /> : <DzNoBackgroundTranslatable title={"Topic"} children={res_get?.data?.topic_survey?.topic?.name} />}
                    {/* <DZSimpleDropDown error={error?.type}  data={reply_types}  value={topicSurveyState?.type}
                                                        onChange={(e) => { setTopicSurveyState(x => ({ ...x, type: e.target.value, })) }} placeholder="Select Survey Type" />
                  */}
                  {/* <DZTextValidation  label="Type" variant="outlined" value={'scale'} /> */}

                    <DZTextValidation error={error?.["0"]} label="Title" variant="outlined" value={topicSurveyState?.title?.[selectedLanguage]}
                        onChange={(e) => { setTopicSurveyState(x => ({ ...x, title: { ...x.title, [selectedLanguage]: e.target.value } })) }}
                    />
             
                 <TextField fullWidth label="Order" type="number" inputProps={{ min: 1 }} variant="outlined" noOfRows={1} value={topicSurveyState?.order}
                                    onChange={(e) => { setTopicSurveyState(x => ({ ...x, order: e.target.value })) }}
                                />

                  


                    <div style={dialogButtonsStyle} >
                        <Button variant="outlined" onClick={() => { hideDialog(); dispatch(setSelectedTopicSurvey(null)); }}>Cancel</Button>
                        {
                            selectedTopicSurvey?.id? <Button variant="contained" onClick={() => { addUpdateTopicSurveyData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                                : <Button variant="contained" onClick={() => { addUpdateTopicSurveyData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedTopicSurvey(null)); }}>Add</Button>
                        }

                    </div>

                 
                </div>
                : <div>Loading....</div>
            }
        


        </div>
    )
}

export default AddUpdateTopicSurvey