import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedCourseBuddyMatchQuestionSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetCourseBuddyMatchQuestionQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Card, CardContent, } from "@mui/material";
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZLabel, { DZDataLabel, DZLabelDescription, DZTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';


const ShowCourseBuddyQuestion = () => {

    const selectedBuddyQuestion = useSelector(selectedCourseBuddyMatchQuestionSelector);
    const [buddyMatchQuestion, setBuddyQuestion] = useState();
console.log(selectedBuddyQuestion);
    const { data: res_get, isLoading: isLoadingBuddyQuestion, error: errorBuddyQuestion } = useGetCourseBuddyMatchQuestionQuery(selectedBuddyQuestion?.id);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const bluePrint = BLUE_PRINTS.CourseBuddyMatchQuestion;
    const { hideDialog } = useJumboDialog();
 
    console.log("selected buddyMatchQuestion", res_get?.data?.buddyMatchQuestion?.options);
    useEffect(() => {
       
        setBuddyQuestion(res_get?.data?.buddyMatchQuestion)
    }, [res_get])

    const buddyMatchQuestionArray =
        [
            { label: 'BuddyQuestion Type', data: buddyMatchQuestion?.type },
     
        { label: 'Question', data: buddyMatchQuestion?.title[selectedLanguage] },
        { label: 'Max Options', data: buddyMatchQuestion?.max_options},
   
    ]





    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorBuddyQuestion ? <div>{JSON.stringify(errorBuddyQuestion)}</div>
                    : isLoadingBuddyQuestion ? <div>Loading Single BuddyQuestion.....</div>
                        : res_get?.data?.buddyMatchQuestion ?
                            <>
                                <DZGenericView obj={buddyMatchQuestionArray}   />


                                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                                        {res_get?.data?.buddyMatchQuestion?.options.length > 0 ? <DZLabel title={"BuddyQuestion Options"} /> : <></>}
                                        <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                                                    <CardContent>
                                        {res_get?.data?.buddyMatchQuestion?.options.map(function (option, i) {

                                            return (
                                                
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                                        <DZLabelDescription title={`Option ${i+1}`} children={option?.title[selectedLanguage]} />

                                                        </div>
                                                   )

                                        })}
                                        </CardContent>
                                                </Card>

                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                        <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                    </div>
                                    {/* {JSON.stringify(bluePrint.translate(res_get?.data?.buddyMatchQuestion, selectedLanguage))}
                 */}
                                </div>
                            </>

                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ShowCourseBuddyQuestion