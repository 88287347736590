import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'
import {  setSelectedCourse } from 'app/rkt_query/SelectedStuffReducer';
import { useGetAllTopicExercisesQuery, BLUE_PRINTS,  useDeleteTopicExerciseMutation, useGetAllLearningJourneysQuery, useGetTopicQuery } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import AddUpdateTopicExercise from './AddUpdateTopicExercise';
import { setSelectedTopicExercise } from 'app/rkt_query/SelectedStuffReducer';
import ViewTopicExercise from './ViewTopicExercise';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import ViewTopic from '../topic/ViewTopic';
import Ordering from './../../../@jumbo/dz-components/drag-drop-ordering/Ordering';
import DzTable from '@jumbo/dz-components/table/DzTable';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';




const TopicExerciseList = () => {

    const dispatch = useDispatch();
  
    const location = useLocation();
    const [topic, setTopic] = useState(location?.state?.relationalObject)
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteTopicExercise, responseDelete] = useDeleteTopicExerciseMutation();
    const { data: res_get, isLoading: isLoadingTopic, error: errorTopic } = useGetTopicQuery(topic?.id);
    const { data: res_get_all_learning_journeys, isLoading: isLoadingLearningJourneys, error: errorLearningJourneys } = useGetAllLearningJourneysQuery();
    const { data: res_get_all, isLoading: isLoadingTopicExercises, error: errorTopicExercises } = useGetAllTopicExercisesQuery(res_get?.data?.topic?.id);
    const [showMode, setShowMode] = useState(false);
    const selectedLanguage = useSelector(selectedLanguageSelector);

 
    const bluePrint = BLUE_PRINTS.topicExercise
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedTopicExercise(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedTopicExercise(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedTopicExercise(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedTopicExercise(row)); }
    bluePrint.handleDelete = (id) => { deleteTopicExercise(id); }

    const size = "xl";
    const ViewShowDialog = React.useCallback(() => {
        showDialog({
            title: "Topic Detail",
            size,
            content: <ViewTopic onClose={hideDialog} selectId={topic?.id} />
        });
    }, [showDialog]);
    const handleCourseAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);

    const showAddDialog = React.useCallback(() => {
        const size = `"xl"`;
        showDialog({
            title: "Add TopicExercise",
            size: size,
            content: <AddUpdateTopicExercise onClose={hideDialog} onSave={handleCourseAdd} topic={topic} />
        });
    }, [handleCourseAdd, showDialog]);
   
const OrderTable=()=>{
    showDialog({
        title: 'Topic Exercise List',
        size,
        content: <Ordering dataList={res_get_all?.data?.topic_exercises} table={"TopicExercise"} modalBluePrint={bluePrint}/>
      });
}
   



    const columns = ['Id', 'Name', 'Description'];
    const rows = [
      { id: res_get?.data?.topic?.id, name: res_get?.data?.topic?.name[selectedLanguage], description: res_get?.data?.topic?.description[selectedLanguage] },
    ];
    const buttons = [
        { label: 'Add Topic Exercise', align:'center',icon: <ControlPointOutlinedIcon />, onClick: showAddDialog },
        { label: 'Action',align:'right', icon: <VisibilityOutlinedIcon />, onClick: ViewShowDialog },
      
      ];

    return (
        <>
      
            <span>{showMode}</span>
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
            <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Topic"}  />
            {<SimpleButtons title={"Topic Exercise Ordering"} onClick={() => OrderTable()} />}
            <JumboDemoCard
                title="Topic Exercise"
                wrapperSx={{
                    p: 0,
                    backgroundColor: "background.paper",
                    "&:last-child": { pb: 0 },
                }}
                sx={{ mt: 5 }}
            >

                <div style={{ width: "100%" }}>
                    <DataTable
                        heirarchyId={location?.state?.obj?.id}
                        listData={res_get_all_learning_journeys?.data?.learning_journeys}
                        ddTranslate={true}
                        translate={true}
                        relationalObject={location?.state?.topicExercise}
                        btnTitleAdd="TopicExercise"
                        data={res_get_all?.data?.topic_exercises}
                        bluePrint={bluePrint}
                        ViewModel={() => <ViewTopicExercise />}
                        ModalContent={() => <AddUpdateTopicExercise onClose={hideDialog} fullscreen={true} />}
                        showAddButton={true}
                    />
                   
                </div>
            </JumboDemoCard>
        </>
    );
}


export default TopicExerciseList