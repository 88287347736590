import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedTopicExerciseSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetTopicExerciseQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZLabel, { DZDataLabel, DZLabelDescription, DZTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { Config } from 'constant';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewTopicExercise = () => {

    const labels = ['exercise', 'video', 'order']
    const selectedTopicExercise = useSelector(selectedTopicExerciseSelector);
    const { data: res_get, isLoading: isLoadingTopicExercise, error: errorTopicExercise } = useGetTopicExerciseQuery(selectedTopicExercise?.id);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const bluePrint = BLUE_PRINTS.topic_exercise;
    const { hideDialog } = useJumboDialog();
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.topic_exercise?.image);
    console.log("selected topic_exercise", res_get?.data?.topic_exercise);
    useEffect(() => {
        res_get?.data?.topic_exercise?.image && setSelectedFile(res_get?.data?.topic_exercise?.image)
    }, [])

    const topicExerciseArray =
        [
            { label: 'Type', data: res_get?.data?.topic_exercise?.type?.name[selectedLanguage] },
            { label: 'Topic', data: res_get?.data?.topic_exercise?.topic?.name[selectedLanguage] },
            { label: 'Name', data: res_get?.data?.topic_exercise?.name[selectedLanguage] },
            { label: 'Duration Minutes', data: res_get?.data?.topic_exercise?.duration_minutes },
            { label: 'Video', data: res_get?.data?.topic_exercise?.video },
            { label: 'Description', data: res_get?.data?.topic_exercise?.description[selectedLanguage] },
            { label: 'Repeatable', data: res_get?.data?.topic_exercise?.repeatable==1?"Yes":"No" },
        ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorTopicExercise ? <div>{JSON.stringify(errorTopicExercise)}</div>
                    : isLoadingTopicExercise ? <div>Loading Single TopicExercise.....</div>
                        : res_get?.data?.topic_exercise ?
                            <>
                                <DZGenericView obj={topicExerciseArray} imagePath={Config.AWSTopicExerciseOriginalImg} image={selectedFile} checkImage={true} />


                                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                                        {res_get?.data?.topic_exercise?.exercise_engagements?.length > 0 ? <DZLabel title={"TopicExercise Engagements"} /> : <></>}
                                        {res_get?.data?.topic_exercise?.exercise_engagements.map(function (engagement, i) {

                                            return (
                                                <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                                                    <CardContent>
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                                            <DZLabelDescription title={'Engagement Type'} children={engagement?.reply_type} />

                                                            {
                                                                engagement?.reply_type === 'redirect_exercise' ? <>
                                                                    <DZLabelDescription title={'Reply Exercise id'} children={engagement?.reply_exercise_id} />

                                                                </> : ""
                                                            }
                                                            {
                                                                engagement?.reply_type === 'redirect_activity' ? <>
                                                                    <DZLabelDescription title={'Reply TopicExercise id'} children={engagement?.reply_activity_id} />


                                                                </> : ""
                                                            }
                                                            <DZLabelDescription title={'Question'} children={engagement?.question[selectedLanguage]} />
                                                            <DZLabelDescription title={'Reply'} children={engagement?.reply[selectedLanguage]} />


                                                        </div>
                                                    </CardContent>
                                                </Card>)

                                        })}


                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                        <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                    </div>
                                    {/* {JSON.stringify(bluePrint.translate(res_get?.data?.activity, selectedLanguage))}
                 */}
                                </div>
                            </>

                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewTopicExercise