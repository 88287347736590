import React from 'react'
import { useNavigate } from "react-router-dom";
import { Config } from './../../../constant/index';
import PP2Service from './../../services/index';


const StoreTokenCheck = () => {
    const navigate = useNavigate();
    //console.log('Token checkaaa');
    React.useEffect(() => {

        const response = PP2Service.fetch("admins/admin-login-check", "POST", {}).then((result) => {
            if (result?.success) {




            } else {
                localStorage.removeItem(Config.adminApiTokenName)
                navigate("/");
            }
        }).catch();

        const storedToken = localStorage.getItem(Config.adminApiTokenName)
        if (!storedToken) {
            navigate('/')
        }




    }, []);
    // const handler = async () => {


    //     let response = await PP2Service.fetch("admin-login-check");
    //     console.log("response", response)
    //     if (response?.success) {

    //         localStorage.removeItem(Config.adminApiTokenName)
    //         navigate("/");


    //     }

    // }
    // handler();

    return (
        <>

        </>
    )
}

export default StoreTokenCheck