import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField, Fab, Box, Checkbox, FormControlLabel } from '@mui/material';
import { selectedCourseSelector, setSelectedCourse, selectedCompanySelector, setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddCourseMutation, useGetCourseQuery, useUpdateCourseMutation, useGetCompanyQuery, useGetAllTeamsQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown, { DZSimpleDropDown } from '../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField, { DZDateField } from '../../../@jumbo/dz-components/textboxes/DZTextField';
import { useJumboDialog } from '../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import { DzNoBackgroundTranslatable, DzNoBackgroundTranslatableModule } from '@jumbo/dz-components/labels/DZLabel';
import useFetchCompany from '@jumbo/dz-components/custom-hooks/useFetchCompany';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';



const Course = ({ onClose }) => {
  const order_type = ['none', 'order-seq', 'order-preset'];
  const dispatch = useDispatch();
  const selectedCourse = useSelector(selectedCourseSelector);
  const selectedCompany = useSelector(selectedCompanySelector);
  console.log("selected course", selectedCourse);
  const { res_get_all } = useFetchCompany();

  const { data: res_get, isLoading: isLoadingCourse, error: errorCourse } = useGetCourseQuery(selectedCourse?.id);
  const { data: res_get1, isLoading: isLoadingCompany, error: errorCompany } = useGetCompanyQuery(selectedCompany?.id);
  const [inputFields, setInputFields] = useState([
    { id: "", name: LANGUAGES.defaultObject, start_datetime: '' }
  ])
  const [updateCourse, responseUpdate,] = useUpdateCourseMutation();
  const [addCourse, responseAdd,] = useAddCourseMutation();
  const bluePrint = BLUE_PRINTS.course;
  console.log(bluePrint);
  const [company_id, setCompanyId] = useState(null);


  const { data: res_get_all_team, isLoading: isLoadingTeams, error: errorTeams } = useGetAllTeamsQuery(company_id ? company_id : res_get?.data?.course?.company?.id);


  const [disable, setDisabled] = useState([true]);

  const [courseState, setCourseState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  // const [error, setError] = useState([]);
  // const [count, setCount] = useState(true);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.course?.image);



  const addUpdateCourseData = (action) => {
    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', res_get?.data?.course?.id);
    formData.append('name', JSON.stringify(courseState.name));
    formData.append('company_id', courseState?.company_id);
    formData.append('team_id', courseState?.team_id ? courseState?.team_id : '');
    formData.append('order_type', courseState?.order_type);
    formData.append('is_purpose', courseState?.is_purpose == true ? 1 : 0);
    formData.append('buddy_feature', courseState?.buddy_feature == true ? 1 : 0);
    formData.append('buddy_limit', courseState?.buddy_limit ? courseState?.buddy_limit : '');
    formData.append('survey_feature', courseState?.survey_feature == true ? 1 : 0);
    formData.append('date', courseState?.date ? courseState?.date : '');
    formData.append('description', JSON.stringify(courseState.description));
    formData.append('modules', JSON.stringify(inputFields));
    console.log(formData.get('modules'));
    // return false;
    action === "add" ? addCourse(formData) : updateCourse(formData);

  }
  //this code is use to control infinity loops and backend required error. custom hook
  const {error,resetCount}= useResponseError(responseAdd,responseUpdate);



  const modules = res_get?.data?.course?.modules?.reduce((acc, object) => [...acc, { id: object.id, name: object.name, start_datetime: '' }], []);

  useEffect(() => {

    res_get?.data?.course?.image && setSelectedFile(res_get?.data?.course?.image)

    setCompanyId(res_get1?.data?.company?.id)
    setCourseState(selectedCourse ? res_get?.data?.course : { ...bluePrint, company_id: res_get1?.data?.company?.id ?? '' });
    setInputFields(modules)




  }, [res_get, res_get1])

  console.log('update response', responseUpdate)
  const companies = res_get_all?.data?.companies?.map((company) => (company))
  // const callCompanyTeams = () => {
  //   setCompanyId(courseState?.company_id)
  // }
  // console.log(companies, "companies")

  const filehandle = (e) => {
    if (e.target.files.length !== 0) {

      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);

    }



  };

  const handleFormChange = (index, event) => {
    console.log('event: ', event.target.value);
    setInputFields(inputFields?.map((eng, i) => i === index ? {
      ...eng,
      [event.target.name]: (
        event.target.value)
    } : eng))
    // const values = [...inputFields];
    // values[index].value = event.target.value;
    // setInputFields(values);

    //console.log('event target value: ', event.target.value);
    //document.getElementsByName("reply_exercise_id")''
    // setInputFields(inputFields.map((eng, i) => i === index ? {
    //   ...eng,
    //   [event.target.name]: (
    //     event.target.value
    //       : { ...eng?.[event.target.name], [selectedLanguage]: event.target.value }
    // )
    // } : eng));                                                                               

  }
  // console.warn(inputFields);
  // const company_teams = res_get_all_team?.data?.teams?.map((team) => (team));
  // console.log(company_teams, "company_teams")

  return (
    <div>

      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
      <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />


      {courseState ?
        <div style={addUpdateDivStyleGlobal}>

          {/* image start */}
          {imagePreview ? (
            <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
          ) : (

            <DZImageCircle image={selectedFile} link={Config.AWSCourseOriginalImg} style={{
              height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            />

          )}

          <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
            <label htmlFor="upload-photo">
              <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>

            </label>
          </Box>
          <DZDropdown error={error?.company_id} placeholder={'Select Company'} value={courseState?.company_id} data={companies}
            onChange={(e) => { setCourseState(x => ({ ...x, company_id: e.target.value, })); }} />
          <Grid container spacing={3.75}>
            <Grid item xs={4} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <FormControlLabel control={<Checkbox checked={courseState?.is_purpose == 0 ? false : true} onChange={(e) => { setCourseState(x => ({ ...x, is_purpose: e.target.checked })) }} />} label="Is Purpose" />
              </Div>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <FormControlLabel control={<Checkbox checked={courseState?.buddy_feature == 0 ? false : true} onChange={(e) => { setCourseState(x => ({ ...x, buddy_feature: e.target.checked })) }} />} label="Buddy Feature" />
              </Div>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <FormControlLabel control={<Checkbox checked={courseState?.survey_feature == 0 ? false : true} onChange={(e) => { setCourseState(x => ({ ...x, survey_feature: e.target.checked })) }} />} label="Survey Feature" />
              </Div>
            </Grid>
          </Grid>
          {/* onChange={(e) => { setCourseState(x => ({ ...x, company_id: e.target.value, })); callCompanyTeams(); }} /> */}

          <TextField label="Buddies Limit" type="number" variant="outlined" noOfRows={1} value={courseState?.buddy_limit}
            onChange={(e) => { const value = parseInt(e.target.value, 10); setCourseState(x => ({ ...x, buddy_limit: value,})); }}
            placeholder="Buddies Limit"
          />

          <DZSimpleDropDown value={courseState?.order_type} data={order_type} name="reply_type"
            placeholder="Select Order Type" onChange={(e) => { setCourseState(x => ({ ...x, order_type: e.target.value, })) }} />
          {/* {(company_teams?.length > 0) ? (<DZDropdown placeholder={'Select Team'} value={courseState?.team_id} data={company_teams}
            onChange={(e) => { setCourseState(x => ({ ...x, team_id: e.target.value, })) }} />) : ''
          } */}

          <DZTextValidation error={error?.["0"]} label="Name" variant="outlined" value={courseState?.name[selectedLanguage]}
            onChange={(e) => { setCourseState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })); }}
            placeholder="Name"

          />
          {/* {courseState?.order_type === "order-seq" ? <TextField label="Start Date" type="date" variant="outlined" value={courseState?.date}
            onChange={(e) => { setCourseState(x => ({ ...x, date: e.target.value })) }}

          /> : ''} */}
          {
            (courseState?.order_type === "order-preset" && inputFields) ? <form>
              <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                <CardContent>
                  {inputFields?.map((input, index) => {
                    return (
                      <div key={index} >

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DzNoBackgroundTranslatableModule children={input?.name} />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>

                            <input type="hidden" name='id' value={input?.id} onChange={event => handleFormChange(index, event)} />

                            <TextField fullWidth label="Start Time" InputLabelProps={{ shrink: true }} type='date' name='start_datetime' variant="outlined" noOfRows={1} value={input.start_datetime}
                              onChange={event => handleFormChange(index, event)}
                            />
                          </Div>
                        </Grid>


                      </div>

                    )
                  })}
                </CardContent>
              </Card>
            </form> : ''

          }
          {/* <Checkbox    value={courseState?.is_purpose} label="Is Purpose"
            onChange={(e) => { setCourseState(x => ({ ...x, is_purpose: e.target.value })) }} /> */}

          <DZTextField label="Description" variant="outlined" noOfRows={3} value={courseState?.description[selectedLanguage]}
            onChange={(e) => { setCourseState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
          />
          <div style={dialogButtonsStyle} >
            <Button variant="outlined" onClick={() => { onClose(); dispatch(setSelectedCourse(null)); }}>Cancel</Button>
            {
              selectedCourse?.id ? <Button variant="contained" onClick={() => { addUpdateCourseData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                : <Button variant="contained" onClick={() => { addUpdateCourseData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedCourse(null)); }}>Add</Button>
            }
            {/* dispatch(setSelectedCourse(null)); */}
          </div>
          {/* <div style={{ width: '10%' }}>{JSON.stringify(courseState)}</div> */}
        </div>
        : <div>Loading....</div>
      }
      {/* {!selectedCourse &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}


    </div>
  )
}

export default Course