import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField, Fab, Box, Checkbox, FormControlLabel } from '@mui/material';
import { selectedTopicExerciseSelector, setSelectedTopicExercise, selectedTopicSelector, setSelectedTopic, selectedCourseSelector } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddTopicExerciseMutation, useDeleteExerciseEngagementMutation, useGetTopicExerciseQuery, useUpdateTopicExerciseMutation, useGetTopicQuery, useGetAllExerciseTypesQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown, { DZSimpleDropDown } from './../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';
import ComboBox from '../../../@jumbo/dz-components/autocomplete-cb/ComboBox';
import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar, { NoHideSnackbar } from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { DzNoBackgroundTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import DZLabel from './../../../@jumbo/dz-components/labels/DZLabel';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
import DZDeleteDialog from '@jumbo/dz-components/dialog/DZDeleteDialog';
import { FastRewind } from '@mui/icons-material';

const AddUpdateTopicExercise = ({ onClose, topic, topicExercise }) => {
    // const reply_types = ['smileys', 'scale', 'note', 'buddy', 'redirect_activity', 'redirect_exercise'];
    const reply_types = ['note'];
    const dispatch = useDispatch();
    const selectedTopicExercise = useSelector(selectedTopicExerciseSelector);
    const selectedTopic = useSelector(selectedTopicSelector);
    const selectedCourse = useSelector(selectedCourseSelector);

    const { data: res_get_all_exercise_types, isLoading: isLoadingActivityTypes, error: errorActivityTypes } = useGetAllExerciseTypesQuery();
    const { data: res_get, isLoading: isLoadingTopicExercise, error: errorTopicExercise } = useGetTopicExerciseQuery(topicExercise?.id ?? selectedTopicExercise?.id);
    const { data: res_get1, isLoading: isLoadingTopic, error: errorTopic } = useGetTopicQuery(topic?.id ?? selectedTopic?.id);

    const [updateTopicExercise, responseUpdate,] = useUpdateTopicExerciseMutation();
    const [addTopicExercise, responseAdd,] = useAddTopicExerciseMutation();
    const [deleteEngagement, responseDelete,] = useDeleteExerciseEngagementMutation();

    const bluePrint = BLUE_PRINTS.topicExercise;
    const [topicExerciseState, setTopicExerciseState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);

    const [showBackDrop, setShowBackDrop] = useState(false);
    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);
    const [disable, setDisabled] = useState([true]);
    const [imagePreview, setImagePreview] = useState("");
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.topic_exercise?.image);
    const [open, setOpen] = React.useState(false);
    const [index1, setIndex] = React.useState(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const { showDialog, hideDialog } = useJumboDialog();

    const [inputFields, setInputFields] = useState([
        // { id: "", reply_type: 'smileys', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', }
        { id: "", reply_type: 'note', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', }
    ])

    const addUpdateTopicExerciseData = (action) => {

        const formData = new FormData();
        formData.append('image', selectedFile);
        formData.append('id', res_get?.data?.topic_exercise?.id);
        formData.append('name', JSON.stringify(topicExerciseState.name));
        formData.append('topic_id', topicExerciseState?.topic_id);
        formData.append('type_id', topicExerciseState?.type_id);
        formData.append('video', topicExerciseState.video);
        formData.append('duration_minutes', topicExerciseState.duration_minutes);
        formData.append('description', JSON.stringify(topicExerciseState.description));
        formData.append('repeatable', topicExerciseState?.repeatable);
        formData.append('engagements', JSON.stringify(inputFields));

        action === "add" ? addTopicExercise(formData) : updateTopicExercise(formData);

    }


    //this code is use to control infinity loops and backend required error. 
    const { error, resetCount } = useResponseError(responseAdd, responseUpdate);



    useEffect(() => {

        res_get?.data?.topic_exercise?.image && setSelectedFile(res_get?.data?.topic_exercise?.image)
        // setTopicExerciseState((selectedTopicExercise || topicExercise?.id) ? { ...res_get?.data?.learning_journey, course_id: selectedCourse?.id } : { ...bluePrint, topic_id: res_get1?.data?.topic?.id ?? '' });
        setTopicExerciseState(selectedTopicExercise ? res_get?.data?.topic_exercise : { ...bluePrint, topic_id: topic?.id ?? '' });
        // setInputFields(res_get?.data?.topic_exercise?.exercise_engagements ?? [{ id: "", reply_type: 'smileys', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', }])
        setInputFields(res_get?.data?.topic_exercise?.exercise_engagements ?? [{ id: "", reply_type: 'note', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', }])
        
        setDisabled(selectedTopicExercise ? false : true);

        if (res_get1?.data?.topic?.id) {

        }
    }, [res_get, res_get1])



    const filehandle = (e) => {
        if (e.target.files.length !== 0) {

            setSelectedFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);

        }
    }
    const handleFormChange = (index, event) => {
        console.log('event: ', event);
        //console.log('event target value: ', event.target.value);
        //document.getElementsByName("reply_exercise_id")''
        setInputFields(inputFields.map((eng, i) => i === index ? {
            ...eng,
            [event.target.name]: (
                eng?.[event.target.name].en === undefined ? event.target.value
                    : { ...eng?.[event.target.name], [selectedLanguage]: event.target.value }
            )
        } : eng));

    }
    const addFields = () => {
        // setInputFields([...inputFields, { id: "", reply_type: 'smileys', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', }])
        setInputFields([...inputFields, { id: "", reply_type: 'note', question: LANGUAGES.defaultObject, reply: LANGUAGES.defaultObject, reply_exercise_id: '', reply_activity_id: '', }])
    }
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const deleteExerciseEngagement = (id, index) => {

        deleteEngagement(id);
        removeFields(index)
        setOpenSnackbar(true)
        handleClose()

    }
    const handleClose = () => {
        setOpen(false);

    };
    const handleClickOpen = (index) => {
        setOpen(true);
        setIndex(index)
        setOpenSnackbar(false)
    };
    // const handleDurationChange = (event) => {
    //     const minutes = parseInt(event.target.value);
    //     const seconds = minutes * 60;
    //     setTopicExerciseState((prevState) => ({
    //       ...prevState,
    //       duration_minutes: seconds,
    //     }));
    //   };
    const exercise_types = res_get_all_exercise_types?.data?.exercise_types.map((exerciseType) => (BLUE_PRINTS.exerciseType.translate(exerciseType, selectedLanguage)))
    return (
        <div>
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />
            {openSnackbar ? <NoHideSnackbar responseAdd={responseDelete?.isSuccess === true ? responseDelete : ''} /> : ""}

            {topicExerciseState ?
                <div style={addUpdateDivStyleGlobal}>
                    {imagePreview ? (
                        <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
                    ) : (

                        <DZImageCircle image={selectedFile} link={Config.AWSTopicExerciseOriginalImg} style={{
                            height: 100, width: 100, borderRadius: 50, alignSelf: "center",
                        }}
                        />

                    )}

                    <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
                        <label htmlFor="upload-photo">
                            <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
                            />
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                            >
                                Upload photo
                            </Fab>

                        </label>
                    </Box>
                    {topic?.id ? <DzNoBackgroundTranslatable title={"Topic"} children={res_get1?.data?.topic?.name} /> : <DzNoBackgroundTranslatable title={"Topic"} children={res_get?.data?.topic_exercise?.topic?.name} />}

                    <DZDropdown error={error?.type_id} value={topicExerciseState?.type_id} data={exercise_types}
                        onChange={(e) => { setTopicExerciseState(x => ({ ...x, type_id: e.target.value, })) }} placeholder="Select Exercise Type" />



                    <DZTextValidation error={error?.["0"]} label="Name" variant="outlined" value={topicExerciseState?.name?.[selectedLanguage]}
                        onChange={(e) => { setTopicExerciseState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })) }}
                    />
                       <Grid item xs={12} md={6} lg={6}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>

                                <TextField fullWidth label="Duration in Minutes" type='number' variant="outlined" noOfRows={1} value={topicExerciseState?.duration_minutes}
                                    onChange={(e) => { setTopicExerciseState(x => ({ ...x, duration_minutes: e.target.value })) }}
                                />
                             
                            </Div>
                        </Grid>
                    <DZTextValidation error={error?.video} label="Video Link" variant="outlined" noOfRows={1} value={topicExerciseState?.video}
                        onChange={(e) => { setTopicExerciseState(x => ({ ...x, video: e.target.value })) }}
                    />

                    <DZTextField label="Description" variant="outlined" noOfRows={3} value={topicExerciseState?.description?.[selectedLanguage]}
                        onChange={(e) => { setTopicExerciseState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
                    />

                    <FormControlLabel control={<Checkbox checked={topicExerciseState?.repeatable == 0 ? false : true} onChange={(e) => { setTopicExerciseState(x => ({ ...x, repeatable: e.target.checked ? 1 : 0 })) }} />} label="Repeatable" />

                    {topicExerciseState?.repeatable ?
                        <div>
                            <div style={{ width: '100%' }}>

                                <DZLabel title={"Exercise Engagements"} />

                                {/* <ControlPointOutlinedIcon onClick={addFields} /> */}

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', }} >
                                    <Button size="small" variant="outlined" startIcon={<ControlPointOutlinedIcon />} onClick={addFields} >
                                        Add Engagement
                                    </Button>
                                </div>
                            </div>

                            <form>
                                {inputFields.map((input, index) => {
                                    return (
                                        <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                                            <CardContent>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', width: '100%' }}>


                                                    {index1 === index ? <DZDeleteDialog title={"Are you sure about deleting this Engagement?"}
                                                        description={" You won't be able to recover this engagement later"}
                                                        openBox={open}
                                                        handleClose={() => handleClose()}
                                                        handleDeleteFunctionCall={() => deleteExerciseEngagement(input?.id, index)}

                                                    /> : ""}
                                                    {/* {index1 === index ? <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                index={index1}
                                            >
                                                <DialogTitle id="alert-dialog-title">
                                                    {" Are you sure about deleting this Engagement?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        You won't be able to recover this engagement later
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>No</Button>
                                                    <Button onClick={() => deleteExerciseEngagement(input?.id, index)} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog> : ""} */}



                                                    {
                                                        input?.id ?

                                                            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={() => handleClickOpen(index)} >
                                                                Delete
                                                            </Button>

                                                            : <Button variant="outlined" startIcon={<DeleteIcon />} sx={{ color: 'red' }} onClick={() => removeFields(index)} >
                                                                Remove
                                                            </Button>
                                                    }
                                                </div>
                                                <div key={index} >

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Div sx={{ mb: 0.5, mt: 1 }}>
                                                            <DZSimpleDropDown value={input?.reply_type} data={reply_types} name="reply_type"
                                                                placeholder="Select Type" onChange={event => handleFormChange(index, event)} />
                                                        </Div>
                                                    </Grid>
                                                    {
                                                        input?.reply_type === 'redirect_exercise'
                                                            ?
                                                            <Grid item xs={12} md={6} lg={6}>
                                                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                                                    <TextField required fullWidth type='number' label="Redirect Exercise" name='reply_exercise_id' variant="outlined" noOfRows={1} value={input?.reply_exercise_id}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                    />
                                                                </Div>
                                                            </Grid> : ''
                                                    } {
                                                        input?.reply_type === 'redirect_activity'
                                                            ?
                                                            <Grid item xs={12} md={6} lg={6}>
                                                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                                                    <TextField required fullWidth label="Redirect Activity" type='number' name='reply_activity_id' variant="outlined" noOfRows={1} value={input?.reply_activity_id}
                                                                        onChange={event => handleFormChange(index, event)}
                                                                    />
                                                                </Div>
                                                            </Grid> : ''
                                                    }

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Div sx={{ mb: 0.5, mt: 1 }}>

                                                            <input type="hidden" name='id' value={input?.id} onChange={event => handleFormChange(index, event)} />
                                                            <TextField fullWidth label="Question" type='text' name='question' variant="outlined" noOfRows={1} value={input.question[selectedLanguage]}
                                                                onChange={event => handleFormChange(index, event)}
                                                            />
                                                        </Div>
                                                    </Grid>

                                                    <Grid item xs={12} md={6} lg={6}>
                                                        <Div sx={{ mb: 0.5, mt: 1 }}>

                                                            <TextField fullWidth label="Reply" type='text' name='reply' variant="outlined" noOfRows={1} value={input.reply[selectedLanguage]}
                                                                onChange={event => handleFormChange(index, event)}
                                                            />

                                                        </Div>
                                                    </Grid>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    )
                                })}
                            </form>
                        </div> : <div></div>
                    }
                    <div style={dialogButtonsStyle} >
                        <Button variant="outlined" onClick={() => { hideDialog(); dispatch(setSelectedTopicExercise(null)); }}>Cancel</Button>
                        {
                            selectedTopicExercise?.id ? <Button variant="contained" onClick={() => { addUpdateTopicExerciseData(); setShowBackDrop(true); resetCount() }}>Update</Button>
                                : <Button variant="contained" onClick={() => { addUpdateTopicExerciseData("add"); setShowBackDrop(true); resetCount(); dispatch(setSelectedTopicExercise(null)); }}>Add</Button>
                        }

                    </div>


                </div>
                : <div>Loading....</div>
            }

        </div>
    )
}

export default AddUpdateTopicExercise