import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { selectedLearningJourneySelector, setSelectedCourse } from 'app/rkt_query/SelectedStuffReducer';
import { useGetAllTopicSurveysQuery, BLUE_PRINTS, useGetTopicSurveyQuery, useDeleteTopicSurveyMutation, useGetAllLearningJourneysQuery, useGetTopicQuery } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import AddUpdateTopicSurvey from './AddUpdateTopicSurvey';
import { setSelectedTopicSurvey } from 'app/rkt_query/SelectedStuffReducer';
import ViewTopicSurvey from './ViewTopicSurvey';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';

import ViewTopic from '../topic/ViewTopic';
import { Button, IconButton } from '@mui/material';
import { boxSX } from '@jumbo/dz-components/data-table/GlobalFunctions';
import Ordering from './../../../@jumbo/dz-components/drag-drop-ordering/Ordering';
import DzTable from '@jumbo/dz-components/table/DzTable';
import { SimpleButtons } from '@jumbo/dz-components/header-buttons/HeaderButtons';




const TopicSurveyList = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const [topic, setTopic] = useState(location?.state?.relationalObject)
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteTopicSurvey, responseDelete] = useDeleteTopicSurveyMutation();
    const { data: res_get, isLoading: isLoadingTopic, error: errorTopic } = useGetTopicQuery(topic?.id);

    const { data: res_get_all, isLoading: isLoadingTopicSurveys, error: errorTopicSurveys } = useGetAllTopicSurveysQuery(res_get?.data?.topic?.id);
    const [showMode, setShowMode] = useState(false);
    const selectedLanguage = useSelector(selectedLanguageSelector);

    const bluePrint = BLUE_PRINTS.topicSurvey
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedTopicSurvey(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedTopicSurvey(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedTopicSurvey(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedTopicSurvey(row)); }
    bluePrint.handleDelete = (id) => { deleteTopicSurvey(id); }




    const size = "xl";
    const ViewShowDialog = React.useCallback(() => {
        showDialog({
            title: "Topic Detail",
            size,
            content: <ViewTopic onClose={hideDialog} selectId={topic?.id} />
        });
    }, [showDialog]);
    const handleCourseAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
    const showAddDialog = React.useCallback(() => {
        const size = `"xl"`;
        showDialog({
            title: "Add TopicSurvey",
            size: size,
            content: <AddUpdateTopicSurvey onClose={hideDialog} onSave={handleCourseAdd} topic={topic} />
        });
    }, [handleCourseAdd, showDialog]);
   


    const columns = ['Id', 'Name', 'Description'];
    const rows = [
      { id: res_get?.data?.topic?.id, name: res_get?.data?.topic?.name[selectedLanguage], description: res_get?.data?.topic?.description[selectedLanguage] },
    ];
   const buttons = [
      { label: 'Add Topic Survey', align:'center',icon: <ControlPointOutlinedIcon />, onClick: showAddDialog },
      { label: 'Action',align:'right', icon: <VisibilityOutlinedIcon />, onClick: ViewShowDialog },
    
    ];


  
    return (
        <>
            {/* <StoreTokenCheck /> */}
            <span>{showMode}</span>
            
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

            {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '5%' }} onClick={hanldeBack} >Back</Button> */}

           

       

            <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Topic"}  />

          
            <SimpleButtons title={"Topic Survey Ordering"} onClick={() => showDialog({
                title: 'Topic Survey List',
                size,
                content: <Ordering dataList={res_get_all?.data?.topic_surveys} table={"TopicSurvey"} modalBluePrint={bluePrint} />
            })} />
            <JumboDemoCard
                title="Topic Survey"
                wrapperSx={{
                    p: 0,
                    backgroundColor: "background.paper",
                    "&:last-child": { pb: 0 },
                }}
                sx={{ mt: 5 }}
            >

                <div style={{ width: "100%" }}>
                    <DataTable
                        heirarchyId={location?.state?.obj?.id}                    
                        ddTranslate={true}
                        translate={true}
                        relationalObject={location?.state?.relationalObject}
                        btnTitleAdd="TopicSurvey"
                        data={res_get_all?.data?.topic_surveys}
                        bluePrint={bluePrint}
                        ViewModel={() => <ViewTopicSurvey />}
                        ModalContent={() => <AddUpdateTopicSurvey onClose={hideDialog} fullscreen={true} />}
                        showAddButton={true}
                     
                    />
                   
                </div>
            </JumboDemoCard>
        </>
    );
}


export default TopicSurveyList