import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField } from '@mui/material';
import { selectedAdminSelector, setSelectedAdmin } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddAdminMutation, useGetAdminQuery, useUpdateAdminMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';

import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';
import useFetchCompany from './../../../@jumbo/dz-components/custom-hooks/useFetchCompany';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';

const AddUpdateAdmin = ({ onClose }) => {

   
    const selectedAdmin = useSelector(selectedAdminSelector);
    const{res_get_all}=useFetchCompany();
  
    const { data: res_get, isLoading: isLoadingAdmin, error: errorAdmin } = useGetAdminQuery(selectedAdmin?.id);
    const [updateAdmin, responseUpdate,] = useUpdateAdminMutation();
    const [addAdmin, responseAdd,] = useAddAdminMutation();
    const bluePrint = BLUE_PRINTS.admin;
    const [adminState, setAdminState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);

      //this code is use to control infinity loops and backend required error. 
      const {error,resetCount}= useResponseError(responseAdd,responseUpdate);

    // const countZero = () => {
    //     setCount(true)
    // }
    // console.log(responseUpdate?.error?.data?.errors)
    // if (responseAdd?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }

    // if (responseUpdate?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseUpdate?.error?.data?.errors)
    // }

    //console.log(error)

    useEffect(() => {
        setAdminState(selectedAdmin ? res_get?.data?.admin : bluePrint);
        console.log('selectedAdmin: ', res_get);
    }, [res_get])

    console.log('selectedAdmin out of useEffect: ', res_get?.data?.admin);

    const companies = res_get_all?.data?.companies.map((admin) => (admin))
    return (
        <div>
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />

            {adminState ?
                <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25 }}>
                    {/* <LanguageSwitcher /> */}
                    <DZTextValidation label="Admin" variant="outlined" value={adminState?.name}
                        onChange={(e) => { setAdminState(x => ({ ...x, name: e.target.value })) }}
                        error={error?.name}
                    />

                    <DZTextValidation label="Email" variant="outlined" value={adminState?.email}
                        onChange={(e) => { setAdminState(x => ({ ...x, email: e.target.value })) }}
                        error={error?.name}
                    />

                    <DZTextValidation label="Password" variant="outlined" value={adminState?.password}
                        onChange={(e) => { setAdminState(x => ({ ...x, password: e.target.value })) }}
                        error={error?.name}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }} >
                        <Button variant="outlined" onClick={() => { onClose() }}>Cancel</Button>
                        {
                            selectedAdmin ? <Button variant="contained" onClick={() => { updateAdmin(adminState); setShowBackDrop(true); resetCount(); }}>Update</Button>

                                : <Button variant="contained" onClick={() => { addAdmin(adminState); setShowBackDrop(true); resetCount(); }}>Add</Button>
                        }
                        {/* dispatch(setSelectedAdmin(null)); */}
                    </div>
                    {/* <div style={{ width: '10%' }}>{JSON.stringify(adminState)}</div> */}
                </div>
                : <div>Loading....</div>
            }
            {/* {!selectedAdmin &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}

        </div>
    )
}

export default AddUpdateAdmin