import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import React, { useEffect, useState } from "react";
import {
  useGetAllTopicsQuery,
  BLUE_PRINTS,
  useGetTopicQuery,
  useDeleteTopicMutation,
  useGetAllModulesQuery,
} from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import DataTable from "../../../@jumbo/dz-components/data-table/DataTable";
import AppUpdateTopic from "./AppUpdateTopic";
import { setSelectedTopic } from "app/rkt_query/SelectedStuffReducer";
import ViewTopic from "./ViewTopic";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useLocation } from "react-router-dom";
import LearningJourney from "./../learning-journey/LearningJourney";
// import StoreTokenCheck from './../storetoken/StoreTokenCheck';

const TopicList = () => {
  const location = useLocation();
  console.log("from modules", location?.state?.heirarchy);

  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteTopic, responseDelete] = useDeleteTopicMutation();
  const {
    data: res_get_all,
    isLoading: isLoadingTopics,
    error: errorTopics,
  } = useGetAllTopicsQuery();
  const {
    data: res_get_all_modules,
    isLoading: isLoadingModules,
    error: errorModules,
  } = useGetAllModulesQuery();
  const [showMode, setShowMode] = useState(false);

  const bluePrint = BLUE_PRINTS.topic;
  bluePrint.addRecord = () => {
    setShowMode(false);
    dispatch(setSelectedTopic(null));
  };
  bluePrint.showRecord = (row) => {
    setShowMode(true);
    dispatch(setSelectedTopic(row));
  };
  bluePrint.editRecord = (row) => {
    setShowMode(false);
    dispatch(setSelectedTopic(row));
  };
  bluePrint.deleteRecord = (row) => {
    dispatch(setSelectedTopic(row));
  };
  bluePrint.handleDelete = (id) => {
    deleteTopic(id);
  };

  return (
    <>
      <span>{showMode}</span>
      {responseDelete.isLoading === true && (
        <SimpleBackdrop responseAdd={responseDelete} />
      )}
      {responseDelete?.isSuccess === true && (
        <Snackbar responseAdd={responseDelete} />
      )}
      <JumboDemoCard
        title={"Topics"}
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
      >
        <div style={{ width: "100%" }}>
          <DataTable
            heirarchyId={location?.state?.obj?.id}
            listData={res_get_all_modules?.data?.modules}
            ddTranslate={true}
            translate={true}
            companyName={location?.state?.company}
            relationalObject={location?.state?.relationalObject}
            btnTitleAdd="Topic"
            data={res_get_all?.data?.topics}
            bluePrint={bluePrint}
            ViewModel={() => <ViewTopic />}
            ModalContent={() => <AppUpdateTopic onClose={hideDialog} />}
            relationalTitle="Learning Journey"
            RelationalModalContent={() => (
              <LearningJourney onClose={hideDialog} />
            )}
          />
        </div>
      </JumboDemoCard>
    </>
  );
};

export default TopicList;
