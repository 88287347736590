/* eslint-disable no-lone-blocks */
/* eslint-disable default-case */

import { setSelectedCompany, setSelectedCourse, setSelectedLearningJourney } from 'app/rkt_query/SelectedStuffReducer';
import { setselectedId } from "app/rkt_query/GlobalReducer";
import AppUpdateTopic from 'app/pages/topic/AppUpdateTopic';
import LearningJourney from 'app/pages/learning-journey/LearningJourney';
import ViewModule from 'app/pages/module/ViewModule';
import Module from 'app/pages/module/AddUpdateModule';
import Activity from 'app/pages/activity/Activity';
import AddUpdateTopicExercise from './../../../app/pages/topic-exercise/AddUpdateTopicExercise';
import ViewTeam from 'app/pages/team/ViewTeam';
const { default: styled } = require("@emotion/styled");
const { TextField, Link } = require("@mui/material");


export const handleLanguage = (text, selectedLanguage) => {
  if (text) {
    if (text?.[selectedLanguage] === undefined) {
      console.log("global", text)
    }
    else { console.log("global", text?.dk) }
    console.log("check", text?.[selectedLanguage] === undefined ? console.log("global", text) : text?.dk)
  }
  else {
    console.log("Nullllll")
  }
}

export const handleVisibilityAndAddIcon = (menuItem, navigate, dispatch, btnTitleAdd, showDialog, relationalTitle) => {
  switch (btnTitleAdd) {
    case 'Company':
      navigate("/courses", {
        state: {
          relationalObject: menuItem,
        },
      });
      break;
    case 'Course':
      navigate("/get-course-users", {
        state: {
          relationalObject: menuItem,
        },
      });
      break;
    case 'Module':
      showDialog({
        title: "Add " + relationalTitle,
        content: <AppUpdateTopic module={menuItem} />
      });
      break;
    case 'Topic':
      dispatch(setSelectedLearningJourney(null));
      showDialog({
        title: "Add " + relationalTitle,
        content: <LearningJourney topic={menuItem} />
      });
      break;
    case 'Learning Journey':
      showDialog({
        title: "Add " + relationalTitle,
        content: <LearningJourney learningJourney={menuItem} />
      });
      break;
      case 'Course Teams':
        showDialog({
          showLanguageSwitcher: false,
          variant: 'confirm',
          title: 'Are you sure to generate auto buddies ?',
          content: "Click On Yes To Generate",
          onYes: () => AutoGenrateBuddies(),
          // onNo: hideDialog
        })
      break;
  }
}
const AutoGenrateBuddies=(course_id)=>{

  const formData = new FormData();
  
  formData.append('course_id', course_id);

  // generateBuddies(formData).unwrap(

  // )
  // .then((payload) => console.log('fulfilled', payload))
  // .catch((error) => console.error('rejected', error));


}

export const handleSelectedDDItem = (
  menuItem,
  btnTitleAdd,
  selectedId,
  data,
  relationalObject,
  dispatch,
  navigate
) => {
  console.log("handleSelectTesting", selectedId);
  console.log("menuItem", menuItem);

  switch (btnTitleAdd) {
    case "Company":

      var filtering = data?.filter((data) => data?.id === menuItem.company_id);
      console.log("filter data", filtering);
      dispatch(setselectedId(filtering[0]));
      navigate("/courses", {
        state: {
          relationalObject: menuItem,
          company: filtering[0],
        },
      });
      break;
    case "Course":
      console.log("checkng companies", menuItem);
      var ch = data?.filter((data) => data?.id === menuItem?.course_id);
      // eslint-disable-next-line no-lone-blocks
      {
        selectedId.length > 0
          ? console.log("yssss")
          : dispatch(setselectedId(ch[0].company));
      }
      var filtering = data?.filter((data) => data?.id === menuItem.course_id);
      console.log("filter data", filtering);
      dispatch(setselectedId(filtering[0]));
      navigate("/modules", {
        state: {
          relationalObject: menuItem,
        },
      });
      break;
    case "Module":
      var filtering = data?.filter((data) => data?.id === menuItem.module_id);
      console.log("filter data", filtering);
      dispatch(setselectedId(filtering[0]));
      navigate("/topics", {
        state: {
          relationalObject: menuItem,
        },
      });
      break;
    case "Topic":
      var filtering = data?.filter((data) => data?.id === menuItem.topic_id);
      console.log("filter data", filtering);
      dispatch(setselectedId(filtering[0]));

      navigate("/learning-journeys", {
        state: {
          relationalObject: menuItem,
        },
      });
      break;
    case "learningJourney":


      var filtering = data?.filter(
        (data) => data?.id === menuItem.learning_journey_id
      );
      console.log("filter data", filtering);
      dispatch(setselectedId(filtering[0]));
      dispatch(setSelectedLearningJourney(filtering[0]));
      navigate("/activities", {
        state: {
          relationalObject: menuItem,
        },
      });
      break;
  }
};

export const handleDDOptionChange = (btnTitleAdd, e, ddTranslate, data, selectedLanguage, getAllData) => {
  console.log("e", data, e, data?.filter((item) => item?.name == e?.name))
  var ch;
  var result;
  if (e) {
    ch = ddTranslate ? data?.filter((item) => item?.company?.name?.[selectedLanguage] == e?.name) : data?.filter((item) => item?.company?.name == e?.name)
    if (btnTitleAdd === "Course") { ch = ddTranslate ? data?.filter((item) => item?.company?.name?.[selectedLanguage] == e?.name) : data?.filter((item) => item?.company?.name == e?.name) }
    else if (btnTitleAdd === "Module") { ch = ddTranslate ? data?.filter((item) => item?.course?.name?.[selectedLanguage] == e?.name) : data?.filter((item) => item?.course?.name == e?.name) }
    // else if (btnTitleAdd === "Company") { ch = data?.filter((item) => item?.company?.name == e?.name) }
    else if (btnTitleAdd === "Company") { ch = data?.filter((item) => item?.name == e?.name) }
    else if (btnTitleAdd === "Topic") { ch = ddTranslate ? getAllData?.filter((item) => item?.module?.name?.[selectedLanguage] == e?.name) : data?.filter((item) => item?.module?.name == e?.name) }
    else if (btnTitleAdd === "learningJourney") { ch = data?.filter((item) => item?.topic_id == e?.id) }
    else if (btnTitleAdd === "activity") { ch = ddTranslate ? data?.filter((item) => item?.learning_journey?.name?.[selectedLanguage] == e?.name) : data?.filter((item) => item?.learning_journey?.name == e?.name) }
    result = ch?.map((val) => val)
  }
  else {

    result = data
  }

  return result;
}

export const selectDDHeader = (btnTitleAdd) => {
  var value;
  if (btnTitleAdd === "Course") {
    value = "Modules";
  } else if (btnTitleAdd === "Module") {
    value = "Topics";
  } else if (btnTitleAdd === "Company") {
    value = "Courses";
  } else if (btnTitleAdd === "Topic") {
    value = "Learning Journeys";
  } else if (btnTitleAdd === "learningJourney") {
    value = "Activities";
  }
  return value;
};

export const hoverEffect = {
  "&:hover": {
    color: "#5E3BB7",
    cursor: "pointer",
  },
};

export const boxSX = {
  "&:hover": {
    cursor: 'pointer',
  },
};

export const StyledLink = styled(Link)`
  label.Mui-focused {
    color: green;
  }
  .MuiOutlinedInput-input {
    cursor: pointer;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: red;
    }
    &:hover fieldset {
      border-color: blue;
      cursor: pointer;
    }
    &.Mui-focused fieldset {
      border-color: green;
    }
  }
`;
const size = "xl";
// handle action buttons function
export const handleActionButtons = (menuItem, btnTitleAdd, ViewModel, dispatch, navigate, bluePrint, showDialog, ModalContent) => {
  switch (menuItem.action) {
    case 'view':
      // if (btnTitleAdd === "Company" || btnTitleAdd === "User" || btnTitleAdd === "Admin") {
      //   bluePrint.showRecord(menuItem.row);
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     size,
      //     content: <ViewModel company={menuItem?.row} />
      //   });
      //   // navigate("/courses", {
      //   //   state: {
      //   //     relationalObject: menuItem.row,
      //   //     company: "chekc",
      //   //   },
      //   // });
      // } else if (btnTitleAdd === "Course") {
      //   dispatch(setSelectedCourse(menuItem?.row))
      //   navigate("/modules", {
      //     state: {
      //       relationalObject: menuItem.row,
      //       company: "chekc",
      //     },
      //   });
      // } else if (btnTitleAdd === "Module") {
      //   //  console.log("inview", menuItem?.row)
      //   // bluePrint.showRecord(menuItem.row);
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     size,
      //     content: <ViewModule selectId={menuItem?.row?.id} />
      //   });
      // }
      // else if (btnTitleAdd === "Topic") {
      //   // console.log(menuItem.row)
      //   bluePrint.showRecord(menuItem.row);
      //   // navigate(`/topic-exercises`, {
      //   //   state: {
      //   //     relationalObject: menuItem.row,
      //   //     company: "chekc",
      //   //   },
      //   // });

      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     content: <ViewModel />
      //   });
      // }
      // else if (btnTitleAdd === "TopicExercise") {
      //   // console.log(menuItem.row)
      //   bluePrint.showRecord(menuItem.row);
      //   const size = "xl";
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     size,
      //     content: <ViewModel />
      //   });
      // }
      // else if (btnTitleAdd === "Team") {
      //   //  console.log("inview", menuItem?.row)
      //   // bluePrint.showRecord(menuItem.row);
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     content: <ViewTeam selectId={menuItem?.row?.id} />
      //   });
      // }
      // else if (btnTitleAdd === "learningJourney") {
      //   // console.log(menuItem.row)
      //   // navigate(`/activities`, {
      //   //   state: {
      //   //     relationalObject: menuItem.row?.id,
      //   //     company: "chekc",
      //   //   },
      //   // });
      // }
      // else if (btnTitleAdd === "Activity") {
      //   const size = btnTitleAdd === "Activity" ? '' : "xl";
      //   bluePrint.showRecord(menuItem.row);
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     size,
      //     content: <ViewModel />
      //   });

      // }
      // else if (btnTitleAdd === "Activity Type") {
      //   bluePrint.showRecord(menuItem.row);
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',

      //     content: <ViewModel />
      //   });

      // }else{
      //   console.log(menuItem.row);
      //   bluePrint.showRecord(menuItem.row);
      //   showDialog({
      //     title: btnTitleAdd + ' Detail',
      //     content: <ViewModel />
      //   });
      // }
      break;

    // case 'view':
    //   bluePrint.showRecord(menuItem.row);
    //   showDialog({
    //     title: btnTitleAdd + ' Detail',
    //     content: <ViewModel />
    //   });
    //   break;

    case 'edit':
      if (btnTitleAdd === "Module") {
        //bluePrint.showRecord(menuItem.row);
        showDialog({
          title: btnTitleAdd + ' Detail',
          size,
          content: <Module selectedId={menuItem?.row?.id} />
        });
      }
      else if (btnTitleAdd === "Topic") {
        //bluePrint.showRecord(menuItem.row);
        showDialog({
          title: btnTitleAdd + ' Detail',
          size,
          content: <AppUpdateTopic selectedId={menuItem?.row?.id} />
        });
      }
      else if (btnTitleAdd === "Company") {
        //bluePrint.showRecord(menuItem.row);
        dispatch(setSelectedCompany(menuItem?.row))
        showDialog({
          title: btnTitleAdd + ' Update',
          size,
          content: <ModalContent />
        });
      }
      else if (btnTitleAdd === "Course") {
        // console.log("zzzzzzzzzzzzzzzzzzzz", menuItem?.row)
        dispatch(setSelectedCourse(menuItem?.row))
        //bluePrint.showRecord(menuItem.row);
        showDialog({
          title: btnTitleAdd + ' Detail',
          size,
          content: <ModalContent selectedId={menuItem?.row?.id} />
        });
      }
      else if (btnTitleAdd === "learningJourney") {
        showDialog({
          title: btnTitleAdd + 'Update',
          size,
          content: <LearningJourney learningJourney={menuItem?.row} />
        });
      }
      else if (btnTitleAdd === "Activity") {
        const size = btnTitleAdd === "Activity" ? '' : "xl";
        bluePrint.editRecord(menuItem.row);
        showDialog({
          title: 'Update ' + btnTitleAdd,
          size,
          content: <Activity selectedId={menuItem?.row?.id} />
        });
      } else if (btnTitleAdd === "TopicExercise") {
        const size = "xl";
        bluePrint.editRecord(menuItem.row);
        showDialog({
          title: 'Update ' + btnTitleAdd,
          size,
          content: <AddUpdateTopicExercise topicExercise={menuItem?.row} />
        });
      }
      break;
    case 'delete':
      showDialog({
        showLanguageSwitcher: false,
        variant: 'confirm',
        title: 'Are you sure about deleting this ' + btnTitleAdd + '?',
        content: "You won't be able to recover this " + btnTitleAdd + ' later',
        onYes: () => handleDeleteDialogue(menuItem.row.id, bluePrint),
        //onNo: hideDialog
      })
  }
}

const handleDeleteDialogue = (id, bluePrint) => {
  bluePrint.handleDelete(id);
  // hideDialog();
}


export const handleDDData = (ddTranslate, listData, bluePrint, selectedLanguage) => {
  let dataList = !ddTranslate ?
    listData?.map((nonTranslatableObj) => (
      {
        name: nonTranslatableObj.name
      })) :
    listData?.map((translatableObj) => {
      let obj = bluePrint.translate(translatableObj, selectedLanguage)
      return { name: obj.name, id: obj?.id }
    })

  return dataList;
}


export const filterNavigationData = (relationalObject, data, btnTitleAdd) => {
  console.log('dat filreredddddddda', data?.filter((item) => item?.id == relationalObject?.id))
  let filteredData = data;
  if (relationalObject) {
    btnTitleAdd === "Company" && (filteredData = (data?.filter((item) => item?.id == relationalObject?.id)))
    btnTitleAdd === "Course" && (filteredData = (data?.filter((item) => item?.company_id == relationalObject?.id)))
  }
  else {
    console.log("no dataaaa filtereddd")
  }
  return filteredData;

}