import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedTopicSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetTopicQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Company from '../company/Company';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { Config } from 'constant';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';

const ViewTopic = ({ selectId }) => {
    const selectedTopic = useSelector(selectedTopicSelector);
    const [TopicId, setTopicId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingTopic, error: errorTopic } = useGetTopicQuery(TopicId);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.topic?.image);
    useEffect(() => {
        res_get?.data?.topic?.image && setSelectedFile(res_get?.data?.topic?.image)
        setTopicId(selectedTopic ? selectedTopic?.id : selectId)
    }, [])
    const bluePrint = BLUE_PRINTS.topic;
    const { hideDialog } = useJumboDialog();
    const topicArray =
        [
            { label: 'Module Name', data: res_get?.data?.topic?.module?.name[selectedLanguage] },
            { label: 'Topic Name', data: res_get?.data?.topic?.name[selectedLanguage] },
            { label: 'Topic Description', data: res_get?.data?.topic?.description[selectedLanguage] },
        ]
    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorTopic ? <div>{JSON.stringify(errorTopic)}</div>
                    : isLoadingTopic ? <div>Loading Single Topic.....</div>
                        : res_get?.data?.topic ?
                            <DZGenericView obj={topicArray} imagePath={Config.AWSTopicOriginalImg} image={selectedFile} checkImage={true} closeBtn={true} /> : <div>Bad Code</div>
            }
        </div>
    )
}

export default ViewTopic