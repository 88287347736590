import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedActivitySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetActivityQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import DZLabel, { DZDataLabel, DZLabelDescription, DZTranslatable } from '@jumbo/dz-components/labels/DZLabel';
import { Config } from 'constant';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';


const ShowActivity = () => {
  
    const selectedActivity = useSelector(selectedActivitySelector);
    const [activity, setActivity] = useState();

    const { data: res_get, isLoading: isLoadingActivity, error: errorActivity } = useGetActivityQuery(selectedActivity?.id);
    const selectedLanguage = useSelector(selectedLanguageSelector);

    const { hideDialog } = useJumboDialog();
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.activity?.image);

    useEffect(() => {
        res_get?.data?.activity?.image && setSelectedFile(res_get?.data?.activity?.image)
        setActivity(res_get?.data?.activity)
    }, [res_get])

    const activityArray =
        [
            { label: 'Activity Type', data: activity?.type?.name[selectedLanguage] },
        { label: 'Learning Journey', data: activity?.learning_journey?.name[selectedLanguage] },
        { label: 'Name', data: activity?.name[selectedLanguage] },
        // { label: 'Exercise', data: activity?.exercise },
        { label: 'Video', data: activity?.video },
        { label: 'Order', data: activity?.order },
        { label: 'Duration Minutes', data: activity?.duration_minutes },
        { label: 'Reminder Days', data: activity?.reminder_days },
        { label: 'Description', data: activity?.description[selectedLanguage] },
        // { label: 'Unit of Reminder', data: activity?.unit_of_reminder },
        // { label: 'Unit of Duration', data: activity?.unit_of_duration },
    ]




    return (
        <div>
            {/* <LanguageSwitcher /> */}

            {
                errorActivity ? <div>{JSON.stringify(errorActivity)}</div>
                    : isLoadingActivity ? <div>Loading Single Activity.....</div>
                        : res_get?.data?.activity ?
                            <>
                                <DZGenericView obj={activityArray} imagePath={Config.AWSActivityOriginalImg} image={selectedFile} checkImage={true} />


                                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', marginTop: "5%" }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, }} >
                                        {res_get?.data?.activity?.engagements.length > 0 ? <DZLabel title={"Activity Engagements"} /> : <></>}
                                        {res_get?.data?.activity?.engagements?.map(function (engagement, i) {

                                            return (
                                                <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                                                    <CardContent>
                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, backgroundColor: "rgb(245 241 248)", borderRadius: 12, paddingLeft: 12, padding: 12, marginTop: "2%" }}>
                                                            <DZLabelDescription title={'Engagement Type'} children={engagement?.reply_type} />
                                                          
                                                            {
                                                                engagement?.reply_type === 'redirect_exercise' ? <>
                                                                    <DZLabelDescription title={'Reply Exercise id'} children={engagement?.reply_exercise_id} />
                                                           
                                                                </> : ""
                                                            }
                                                            {
                                                                engagement?.reply_type === 'redirect_activity' ? <>
                                                                    <DZLabelDescription title={'Reply Activity id'} children={engagement?.reply_activity_id} />
                                                            
                                                                </> : ""
                                                            }
                                                            <DZLabelDescription title={'Question'} children={engagement?.question[selectedLanguage]} />
                                                            {
                                                                engagement?.reply_type === 'mcq' && <>
                                                                
                                                                {engagement?.options?.map(function (option, i) {   
                                                                   return <DZLabelDescription title={`Option-${i+1}`} children={option?.title[selectedLanguage]} />

                                                                })}
                                                                </> 
                                                            }
                                                            {engagement?.reply_type != 'mcq' ?<DZLabelDescription title={'Reply'} children={engagement?.reply[selectedLanguage]} />:''}

                                                         
                                                        </div>
                                                    </CardContent>
                                                </Card>)

                                        })}


                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'flex-end', marginTop: '5%' }} >
                                        <Button variant="contained" onClick={() => { hideDialog() }}>Close</Button>

                                    </div>
                  
                                </div>
                            </>

                            : <div>Bad Code</div>
            }
        </div>
    )
}

export default ShowActivity