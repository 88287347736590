import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Box, Button, Fab, TextField } from '@mui/material';
import { selectedCompanySelector, setSelectedCompany } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddCompanyMutation, useGetCompanyQuery, useUpdateCompanyMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';

import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';
import Div from '@jumbo/shared/Div';
import { addUpdateDivStyleGlobal, dialogButtonsStyle } from '@jumbo/dz-components/styles/styles';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';
import { DZImageCircle } from '@jumbo/dz-components/imageCircle/DZImage';
import { Config } from 'constant';

const Company = ({ onClose }) => {

    const dispatch = useDispatch();
    const selectedCompany = useSelector(selectedCompanySelector);
  
    const { data: res_get, isLoading: isLoadingCompany, error: errorCompany } = useGetCompanyQuery(selectedCompany?.id);
    const [updateCompany, responseUpdate,] = useUpdateCompanyMutation();
    const [addCompany, responseAdd,] = useAddCompanyMutation();
    const bluePrint = BLUE_PRINTS.company;
    const [companyState, setCompanyState] = useState(null);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const { hideDialog } = useJumboDialog();
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [imagePreview, setImagePreview] = useState("");
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.topic_exercise?.image);
  //this code is use to control infinity loops and backend required error. 
  const {error,resetCount}= useResponseError(responseAdd,responseUpdate);



  const addUpdateCompanyData = (action) => {

    const formData = new FormData();
    formData.append('image', selectedFile);
    formData.append('id', res_get?.data?.company?.id);
    formData.append('name', companyState.name);
    formData.append('welcome_title', JSON.stringify(companyState.welcome_title));
    formData.append('welcome_detail', JSON.stringify(companyState.welcome_detail));


    action === "add" ? addCompany(formData) : updateCompany(formData);

}


    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);
    // const countZero = () => {
    //     setCount(true)
    // }
   
    // if (responseAdd?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }

    // if (responseUpdate?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseUpdate?.error?.data?.errors)
    // }

    //console.log(error)

    useEffect(() => {
        res_get?.data?.company?.image && setSelectedFile(res_get?.data?.company?.image)
        setCompanyState(selectedCompany ? res_get?.data?.company : bluePrint);
       
    }, [res_get])

    const filehandle = (e) => {
        if (e.target.files.length !== 0) {

            setSelectedFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);

        }
    }
   
        const companyFields = [
            { label: 'Company ', key: 'name', multilingual: false,errorKey:'name' },
            { label: 'Welcome Title', key: 'welcome_title', multilingual: true },
            { label: 'Welcome Detail', key: 'welcome_detail', multilingual: true },
          ];
          
          const renderCompanyFields = () => {
            return companyFields.map((field) => (
              <DZTextValidation
                key={field.label}
                label={field.label}
                variant="outlined"
                value={field.multilingual ? companyState?.[field.key]?.[selectedLanguage] : companyState?.[field.key]}
                error={error?.[field?.errorKey]}
                onChange={(e) => {
                  if (field.multilingual) {
                    const updatedData = {
                      ...companyState[field.key],
                      [selectedLanguage]: e.target.value,
                    };
                    setCompanyState((prevState) => ({
                      ...prevState,
                      [field.key]: updatedData,
                    }));
                  } else {
                    setCompanyState((prevState) => ({
                      ...prevState,
                      [field.key]: e.target.value,
                    }));
                  }
                }}
              />
            ));
          };
          
    return (
        <Div>
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />

            {companyState ?
                <Div style={addUpdateDivStyleGlobal}>

                {imagePreview ? (
                        <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
                    ) : (

                        <DZImageCircle image={selectedFile} link={Config.AWSCompanyLogoImg} style={{
                            height: 100, width: 100, borderRadius: 50, alignSelf: "center",
                        }}
                        />

                    )}

                    <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
                        <label htmlFor="upload-photo">
                            <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
                            />
                            <Fab
                                color="primary"
                                size="small"
                                component="span"
                                aria-label="add"
                                variant="extended"
                            >
                                Upload photo
                            </Fab>

                        </label>
                    </Box>
                    {/* all textfield */}
                     {renderCompanyFields()}
                    {/* <LanguageSwitcher /> */}
                    {/* <DZTextValidation label="Company" variant="outlined" value={companyState?.name}
                        onChange={(e) => { setCompanyState(x => ({ ...x, name: e.target.value })) }}
                        error={error?.name}
                    />
                    <DZTextField label="Welcome Title" variant="outlined" value={companyState?.welcome_title[selectedLanguage]}
                        onChange={(e) => { setCompanyState(x => ({ ...x, welcome_title: { ...x.welcome_title, [selectedLanguage]: e.target.value } })) }}
                    />
                    <DZTextField label="Welcome Detail" variant="outlined" noOfRows={3} value={companyState?.welcome_detail[selectedLanguage]}
                        onChange={(e) => { setCompanyState(x => ({ ...x, welcome_detail: { ...x.welcome_detail, [selectedLanguage]: e.target.value } })) }}
                    /> */}

                    <Div style={dialogButtonsStyle} >
                        <Button variant="outlined" onClick={() => { hideDialog() }}>Cancel</Button>
                        {
                            selectedCompany ? <Button variant="contained" onClick={() => { addUpdateCompanyData(); setShowBackDrop(true); resetCount(); }}>Update</Button>

                                : <Button variant="contained" onClick={() => { addUpdateCompanyData("add"); setShowBackDrop(true); resetCount(); }}>Add</Button>
                        }
                     
                    </Div>
                   
                </Div>
                : <Div>Loading....</Div>
            }
     

        </Div>
    )
}

export default Company