import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField } from '@mui/material';
import { selectedUserSelector, setSelectedUser } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddUserMutation, useGetUserQuery, useUpdateUserMutation } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';

import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';
import DZDropdown from './../../../@jumbo/dz-components/dropdowns/DZDropdown';
import useFetchCompany from '@jumbo/dz-components/custom-hooks/useFetchCompany';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';

const AddUpdateUser = ({ onClose }) => {

  
    const selectedUser = useSelector(selectedUserSelector);
    const{res_get_all}=useFetchCompany();

    const { data: res_get, isLoading: isLoadingUser, error: errorUser } = useGetUserQuery(selectedUser?.id);
    const [updateUser, responseUpdate,] = useUpdateUserMutation();
    const [addUser, responseAdd,] = useAddUserMutation();
    const bluePrint = BLUE_PRINTS.user;
    const [userState, setUserState] = useState(null);
    const [showBackDrop, setShowBackDrop] = useState(false);
    

      //this code is use to control infinity loops and backend required error. 
      const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
    // const [error, setError] = useState([]);
    // const [count, setCount] = useState(true);
    // const countZero = () => {
    //     setCount(true)
    // }
    // console.log(responseUpdate?.error?.data?.errors)
    // if (responseAdd?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseAdd?.error?.data?.errors)
    // }

    // if (responseUpdate?.error?.data?.errors && count) {
    //     setCount(false)
    //     setError(responseUpdate?.error?.data?.errors)
    // }

    //console.log(error)

    useEffect(() => {
        setUserState(selectedUser ? res_get?.data?.user : bluePrint);
        console.log('selectedUser: ', res_get);
    }, [res_get])

    console.log('selectedUser out of useEffect: ', res_get?.data?.user);

    const companies = res_get_all?.data?.companies.map((company) => (company))
    return (
        <div>
            {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
            <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />

            {userState ?
                <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25 }}>
                    {/* <LanguageSwitcher /> */}
                    <DZDropdown error={error?.company_id} placeholder={'Select Company'} value={userState?.company_id} data={companies}
            onChange={(e) => { setUserState(x => ({ ...x, company_id: e.target.value, }));  }} />
                    <DZTextValidation label="User" variant="outlined" value={userState?.name}
                        onChange={(e) => { setUserState(x => ({ ...x, name: e.target.value })) }}
                        error={error?.name}
                    />

                    <DZTextValidation label="Email" variant="outlined" value={userState?.email}
                        onChange={(e) => { setUserState(x => ({ ...x, email: e.target.value })) }}
                        error={error?.name}
                    />

                    <DZTextValidation label="Password" variant="outlined" value={userState?.password}
                        onChange={(e) => { setUserState(x => ({ ...x, password: e.target.value })) }}
                        error={error?.name}
                    />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }} >
                        <Button variant="outlined" onClick={() => { onClose() }}>Cancel</Button>
                        {
                            selectedUser ? <Button variant="contained" onClick={() => { updateUser(userState); setShowBackDrop(true); resetCount(); }}>Update</Button>

                                : <Button variant="contained" onClick={() => { addUser(userState); setShowBackDrop(true); resetCount(); }}>Add</Button>
                        }
                        {/* dispatch(setSelectedUser(null)); */}
                    </div>
                    {/* <div style={{ width: '10%' }}>{JSON.stringify(userState)}</div> */}
                </div>
                : <div>Loading....</div>
            }
            {/* {!selectedUser &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}

        </div>
    )
}

export default AddUpdateUser