import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { selectedUserSelector } from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import { BLUE_PRINTS, useGetUserQuery } from "app/rkt_query/storeApis";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import DZGenericView from "./../../../@jumbo/dz-components/dzgeneric/DZGenericView";
import { DZLabelDescription } from "@jumbo/dz-components/labels/DZLabel";

const ViewUser = ({ selectId }) => {
  const selectedUser = useSelector(selectedUserSelector);
  const [UserId, setUserId] = useState(selectId);
  console.log(selectId);
  useEffect(() => {
    setUserId(selectedUser ? selectedUser?.id : selectId);
  }, []);
  const {
    data: res_get,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useGetUserQuery(UserId);

  const { hideDialog } = useJumboDialog();

  const userArray = [
    { label: "Company", data: res_get?.data?.user?.company?.name },
    { label: "Name", data: res_get?.data?.user?.name },
    { label: "User", data: res_get?.data?.user?.email },
  ];
  return (
    <div>
      {/* <LanguageSwitcher /> */}

      {errorUser ? (
        <div>{JSON.stringify(errorUser)}</div>
      ) : isLoadingUser ? (
        <div>Loading Single User.....</div>
      ) : res_get?.data?.user ? (
        <>
          <DZGenericView obj={userArray} />
          <br></br>
          {res_get?.data?.user?.teams?.map(function (teamUser, i) {
            return (
              <DZLabelDescription
                title={`Team-${i + 1}`}
                children={teamUser?.team?.name}
              />
            );
          })}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "flex-end",
              marginTop: "5%",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                hideDialog();
              }}
            >
              Close
            </Button>
          </div>
        </>
      ) : (
        <div>Bad Code</div>
      )}
    </div>
  );
};

export default ViewUser;
