import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useLocation } from 'react-router-dom';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useSelector } from 'react-redux';

const ComboBox = ({ data, onChange = () => { }, value, defValue, showFirst, inputChange, btnTitleAdd, width }) => {

    const selectedLanguage= useSelector(selectedLanguageSelector);
    const location = useLocation();
    console.log("def value", data, defValue);

    // const flatProps = {
    //        options: (!showFirst && data) ?  data.map((option) => option.name) : null,
    //   };

    //value = (value === 'company' || value === 'activity') ? value.substring(0, value.length - 1) + 'ies' : value+'s';
    //console.log("from combobox", value);
    return (
        <div>
            <Autocomplete
             //  {...flatProps}
                disablePortal
                id="combo-box-demo"
                onInputChange={inputChange}
               // defaultValue={(!showFirst && data) ? flatProps.options[0] : null}
               // value={defValue ? {name: defValue} : ((btnTitleAdd === "Topic" || btnTitleAdd === "LearningJourneys") && data) ? data[0] : null}
              // value={defValue ? {name: defValue} : btnTitleAdd ? null : data[0] }
                value={defValue ? {name: defValue} : !showFirst ? null : data?.[0] }
               // value={checkUndo ? { name: location?.state?.relationalObject?.name[selectedLanguage]} : null}
                getOptionLabel={(option) => option?.name ? option?.name : ''}
                options={data ? data : ''}
                onChange={onChange}
                sx={{ width: width ?? '100%' }}
                renderInput={(params) => <TextField {...params} label={value} />}
            />
        </div>
    );
};
export default ComboBox;
