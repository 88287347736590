import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import { useGetAllTeamsQuery, BLUE_PRINTS, useGetTeamQuery, useDeleteTeamMutation } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
//import Company from './Company';
import AddUpdate from '../team/AddUpdate';

import { setSelectedTeam } from 'app/rkt_query/SelectedStuffReducer';
//import ShowCompany from './ShowCompany';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { reload } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
// import StoreTokenCheck from '../storetoken/StoreTokenCheck';


import {  Button, } from '@mui/material';

import { AddOutlined, ControlPointDuplicateRounded, PhotoCamera } from '@mui/icons-material';
import ShowCompany from './../company/ShowCompany';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ViewTeam from './ViewTeam';
import { TITLE } from 'app/rkt_query/SettingsReducer';
import DzTable from '@jumbo/dz-components/table/DzTable';



const TeamList = () => {

    const location = useLocation();
    const navigate = useNavigate();


    console.log("from team", location?.state?.relationalObject?.id);
    console.log("from compnay id", location?.state?.company_id);

    const dispatch = useDispatch();
    const { showDialog, hideDialog } = useJumboDialog();
    const [deleteTeam, responseDelete] = useDeleteTeamMutation();
    const { data: res_get_all, isLoading: isLoadingTeams, error: errorTeams } = useGetAllTeamsQuery(location?.state?.relationalObject?.id ? location?.state?.relationalObject?.id : location?.state?.company_id);

    console.log("from team ass", res_get_all);
    const [showMode, setShowMode] = useState(false);

    const bluePrint = BLUE_PRINTS.team
    bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedTeam(null)); }
    bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedTeam(row)); }
    bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedTeam(row)); }
    bluePrint.deleteRecord = (row) => { dispatch(setSelectedTeam(row)); }
    bluePrint.handleDelete = (id) => { deleteTeam(id); }
    const size = "xl";
    const showAddDialog = React.useCallback(() => {
        showDialog({
            title: "Add Team",
            content: <AddUpdate company_id={location?.state?.relationalObject?.id ? location?.state?.relationalObject?.id : location?.state?.company_id} onClose={() => handleHideDialog()} onSave={handleCompanyTeamAdd} />
        });
    }, [showDialog]);

    const handleCompanyView = () => {
        showDialog({
            title: 'Company Detail',
            size,
            content: <ShowCompany selectId={location?.state?.relationalObject?.id ? location?.state?.relationalObject?.id : location?.state?.company_id} />
        });
    }
    const handleHideDialog = () => {
        hideDialog();

    }
    const handleCompanyTeamAdd = React.useCallback(() => {
        hideDialog();
    }, [hideDialog]);
    const handleBack = () => {

        //console.log("in navigation",   res_get?.data?.learning_journey?.topic?.module?.course)
        navigate("/companies", {

        });
    }
    const columns = ['Id', 'Company'];
    const rows = [
      { id: res_get_all?.data?.company?.id, name: res_get_all?.data?.company?.name },
    ];
    const buttons = [
        { label: 'Add Company Team', align:'center',icon: <AddOutlined />, onClick: showAddDialog },
        { label: 'Action',align:'right', icon: <VisibilityOutlinedIcon />, onClick: handleCompanyView },
      
      ];

    return (
        <>
            {/* <StoreTokenCheck /> */}
            <span>{showMode}</span>
            {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
            {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

            <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button>
            <DzTable columns={columns} rows={rows} buttons={buttons} mainTitle={"Company Teams"}  />

           
            <br />
            <JumboDemoCard
                title="Teams"
                wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
                <div style={{ width: '100%' }}>
                    <DataTable
                        
                        btnTitleAdd={TITLE.TEAM}
                        data={res_get_all?.data?.teams}
                        bluePrint={bluePrint}
                        ModalContent={() => <AddUpdate onClose={hideDialog} />}
                        ViewModel={() => <ViewTeam />}
                        teamCompanyId={res_get_all?.data?.company?.id}
                        viewTitle3="Assign User"
                         showButton3={false}
                    />
                    

                </div>
            </JumboDemoCard>
        </>
    )
}

export default TeamList
