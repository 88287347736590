import React, { useState } from 'react'
import { TextField } from '@mui/material';

const DZTextValidation = ({ error, type = 'number', sx = '', errorValue = '', label = '', noOfRows = 1, variant = "outlined", value = "", onChange = (e) => { } }) => {

  return (
    <TextField fullWidth id="outlined-basic" sx={sx} inputProps={{ type:'text' }} {...(error && { error: true, helperText: error })} label={label} variant={variant} value={value} onChange={onChange} multiline maxRows={3} minRows={noOfRows} />
    //  error={redErr} helperText={redErr? 'Required*' : ''}
  )
}

export default DZTextValidation

export const DZTextPasswordValidation = ({ error, errorValue = '', label = '', noOfRows = 1, variant = "outlined", value = "", onChange = (e) => { } }) => {

  return (
    <TextField id="outlined-basic"  {...(error && { error: true, helperText: error })} label={label} variant={variant} value={value} onChange={onChange} multiline maxRows={3} minRows={noOfRows} />
    //  error={redErr} helperText={redErr? 'Required*' : ''}
  )
}