import React from "react";
import { useSelector } from "react-redux";
import { selectedIdSelector } from "app/rkt_query/GlobalReducer";
import { Box, Link } from "@mui/material";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import CancelIcon from "@mui/icons-material/Cancel";
import { hoverEffect } from "./GlobalFunctions";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const HeirarchyFlow = ({
  selectedId,
  heirarchyId,
  onClickItem,
  onClickCloseButton = () => {},
}) => {
  const selectedLanguage = useSelector(selectedLanguageSelector);

  const handleItemClick = (item, index) => {
    console.log("menu item click", item, index);
    if (typeof onClickItem === "function") onClickItem(item, index);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          marginLeft: "21px",
          marginBottom: "15px",
          display: "flex",
          flexDirection: {sm: 'column', md: 'row'},
          gap: 1,
          alignItems: "center",
        }}
      >
        {selectedId &&
          selectedId.map((item, index) => {
            var name;
            if (index === 0) {
              name = "Company";
            } else if (index === 1) {
              name = "Course";
            } else if (index === 2) {
              name = "Module";
            } else if (index === 3) {
              name = "Topic";
            } else if (index === 4) {
              name = "Learning-Journey";
            }
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {sm: 'column', md: 'row'},
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <div onClick={() => handleItemClick(item, index)}>
                  <Link
                    underline="hover"
                    sx={[hoverEffect, { color: "#7352C7" }]}
                  >
                    <span
                      style={{
                        textDecoration: item?.id === heirarchyId && "underline",
                      }}
                    >
                      <span style={{ fontWeight: 440, fontSize: "14px" }}>
                        {name}
                      </span>
                      :
                      <span>
                        {index !== 0
                          ? item?.name[selectedLanguage]
                          : item?.name}
                      </span>
                    </span>
                  </Link>
                </div>
                {index === selectedId.length - 1 ? (
                  <CancelIcon onClick={onClickCloseButton} sx={hoverEffect} />
                ): <ArrowRightIcon /> }
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default HeirarchyFlow;
