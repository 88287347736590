import React from 'react'
import { Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableBody } from '@mui/material';
import { DZText } from 'app/DZcomponents/DZText';
import {  IconButton, } from '@mui/material';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';


const DzTable = ({columns,rows,mainTitle,buttons, }) => {
  return (
    <JumboDemoCard
    title={mainTitle}
    wrapperSx={{
      p: 0,
      backgroundColor: "background.paper",
      "&:last-child": { pb: 0, mb: 5 },
    }}
  >
   <TableContainer component={Paper} >
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
    <TableHead>
   
      <TableRow>
        {columns.map((column) => (
          <TableCell key={column} align="left">{column}</TableCell>
        ))}
        {buttons && buttons.map((column) => (
              <TableCell key={column} align="center">{column.label}</TableCell>
            ))}
       

      </TableRow>
    </TableHead>
    <TableBody>
          {rows.map((row, index) => (
             <TableRow key={index}>
              {Object.values(row).map((value, index) => (
                <TableCell key={index} component="th" scope="row">
                  <DZText>{value}</DZText>
                </TableCell>
              ))}
              {buttons && (
                  buttons.map((button, index) => (
                <TableCell align={button.align}>
                    <IconButton
                      key={index}
                      onClick={() => button.onClick(row)}
                      aria-label={button.label}
                      component="label"
                    >
                      {button.icon}
                    </IconButton>
                </TableCell>
                  ))
              )}
            </TableRow>
            
          ))}
        </TableBody>
        
    </Table>
    </TableContainer>

    </JumboDemoCard>


  )
}

export default DzTable


// 

