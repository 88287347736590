import React from "react";
import Home from "../pages/home";
import Company from "../pages/company/index";
import Team from "../pages/team/index";
import CourseBuddyMatchQuestionList from "../pages/course-buddy-question/index";
import Course from "../pages/course";
import UserCourse from "../pages/course_users/index";
import TeamUser from "../pages/team_users/index";

import Module from "../pages/module";
import LearningJourney from "../pages/learning-journey";
import Activity from "../pages/activity";
import Topic from "../pages/topic";
import TopicExercise from "../pages/topic-exercise";
import TopicSurvey from "../pages/topic-survey";
import Admin from "../pages/admin";
import User from "../pages/user";
import Login2 from "../pages/auth/login2/index"
import Dashboard from "app/pages/dashboard/Dashboard";
import ContactsApp from './../pages/apps/contacts/ContactsApp';
import { Navigate } from "react-router-dom";
import { Config } from './../../constant/index';
import Login from './../pages/auth/login/Login';


import ForgotPassword from './../pages/auth/forgot-password/ForgotPassword';
import ForgotPasswordCode from './../pages/auth/forgot-password-code/ForgotPasswordCode';
import ChangePassword from './../pages/auth/forgot-password-code/ChangePassword';
import ActivityType from './../pages/activity-type';
import GlobalSettings from './../pages/global-settings/index';
import Profile from './../pages/profile/index';
import UserProfile from "app/pages/user-profile";





const routes = [

    {
        path: "/",
        element: <Login />
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "verify-forgot-password",
        element: <ForgotPasswordCode />
    },
    {
        path: "change-password",
        element: <ChangePassword />
    },
    {
        path: "/login",
        element: <Login2 />
    },
    {
        path: "/profile",
        element: <Profile />
    },
    // {
    //     path: "/dashboard",
    //     element: localStorage.getItem(Config.adminApiTokenName) ? <Navigate to={"/dashboard"} /> : <Navigate to={"/"} />
    //     // element: <Navigate to={"/dashboard"} />

    // },
    {
        path: "/dashboard",


        element: <Dashboard />
    },
    {
        path: "/contacts",
        element: <ContactsApp />
    },
    {
        path: "/companies",
        element: <Company />
    }, {
        path: "/teams",
        element: <Team />
    }, {
        path: "/courses",
        element: <Course />
    }, {
        path: "/get-course-users",
        element: <UserCourse />
    },
    {
        path: "/get-team-users",
        element: <TeamUser />
    }, {
        path: "/course-buddy-match-question",
        element: <CourseBuddyMatchQuestionList />
    },{
        path: "/modules",
        element: <Module />
    }, {
        path: "/learning-journeys",
        element: <LearningJourney />
    },
    {
        path: "/activity-types",
        element: <ActivityType />
    },
    {
        path: "/activities",
        element: <Activity />
    },
    {
        path: "/topics",
        element: <Topic />
    },
    {
        path: "/topic-exercises",
        element: <TopicExercise />
    },
    {
        path: "/topic-surveys",
        element: <TopicSurvey />
    },
    {
        path: "/admins",
        element: <Admin />
    },
    {
        path: "/users",
        element: <User />
    },
    {
        path: "/global-settings",
        element: <GlobalSettings />
    },
    {
        path: "/user/profile",
        element: <UserProfile/>
    
    },

];

export default routes;