// //ForLocalHost
// const serverUrl = 'http://127.0.0.1:8000/';
// //ForGreenGeeks
// const serverUrl = 'https://danzeetech.com/minders/public/';
// ForAWS
const serverUrl = 'https://backend.minders.ai/';
// //ForStagingAWS
// const serverUrl = 'https://staging-backend.minders.ai/';

export const Config = {

  serverUrl: serverUrl,
  serverApiUrl: serverUrl + 'api/',
  serverUrlImages: serverUrl + 'public/images/',
  serverUrlCategoryImages: serverUrl + 'images/categories/',
  serverUrlProductImages: serverUrl + 'public/images/products/',
  serverUrlUserImages: serverUrl + 'public/images/users/',
  adminApiTokenName: 'mindcap-admin-token',
  email: 'email',
  verificationCode: 'verifiactionCode',

  userApiTokenName: 'mindcap-user-token',
  googleApiKey: 'AIzaSyD4BUDTEpTPIuhhJ2MyQ4AiQ0u3CyFlWOo',

  // Used in whole app
  currency_symbol: "DKK",
  currency: "Kr.",

  appName: "MindCap",

  pushTokenName: 'device-push-token',

  // Here add langues and then add transaltion file for languages
  languages: [
    { shortName: 'en', icon: '', longName: 'English' },
    { shortName: 'da', icon: '', longName: 'Danish' }
  ],
  firstVisitDone: "firstVisitDone",
  directory: "web-dzfood",
  defaultActivityImg: "../select-image.jpg",
  defaultImg: "select-image.jpg",
  defaultProductImageURI: 'https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg',
  //https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/activities/large/18-1667395958-main-bhi-chowkidar-icu.jpg
  AWSActivityLargeImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/activities/large/",
  AWSActivityMediumImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/activities/medium/",
  AWSActivityOriginalImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/activities/original/",
  AWSActivitySmallImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/activities/small/",

  // course
  AWSCourseLargeImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/courses/large/",
  AWSCourseMediumImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/courses/medium/",
  AWSCourseOriginalImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/courses/original/",
  AWSCourseSmallImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/courses/small/",


  // module
  AWSModuleLargeImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/modules/large/",
  AWSModuleMediumImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/modules/medium/",
  AWSModuleOriginalImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/modules/original/",
  AWSModuleSmallImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/modules/small/",

  // topic
  AWSTopicLargeImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topics/large/",
  AWSTopicMediumImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topics/medium/",
  AWSTopicOriginalImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topics/original/",
  AWSTopicSmallImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topics/small/",
  // topic
  AWSTopicExerciseLargeImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topic_exercises/large/",
  AWSTopicExerciseMediumImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topic_exercises/medium/",
  AWSTopicExerciseOriginalImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topic_exercises/original/",
  AWSTopicExerciseSmallImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/topic_exercises/small/",
  // learning journey
  AWSLearningJourneyLargeImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/learning_journeys/large/",
  AWSLearningJourneyMediumImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/learning_journeys/medium/",
  AWSLearningJourneyOriginalImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/learning_journeys/original/",
  AWSLearningJourneySmallImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/learning_journeys/small/",
  // user
  AWSUserLargeImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/users/avatars/large/",
  //company
  AWSCompanyLogoImg: "https://mindcap-images.s3.eu-central-1.amazonaws.com/images/mindcap/companies/logos/",


}

