import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { Button, TextField } from '@mui/material';
import { selectedActivityTypeSelector, setSelectedActivityType } from 'app/rkt_query/SelectedStuffReducer';
import { LANGUAGES, selectedLanguageSelector, setSelectedLanguage } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useAddActivityTypeMutation, useGetActivityTypeQuery, useUpdateActivityTypeMutation, useGetAllTopicsQuery } from 'app/rkt_query/storeApis';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DZDropdown from './../../../@jumbo/dz-components/dropdowns/DZDropdown';
import DZTextField from './../../../@jumbo/dz-components/textboxes/DZTextField';
import ComboBox from '../../../@jumbo/dz-components/autocomplete-cb/ComboBox';
import { useJumboDialog } from './../../../@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import DZTextValidation from '@jumbo/dz-components/textboxes/DZTextValidation';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import useResponseError from '@jumbo/dz-components/custom-hooks/useResponseError';

const AddUpdateActivityType = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedActivityType = useSelector(selectedActivityTypeSelector);
  const { data: res_get_all, isLoading: isLoadingTopics, error: errorTopics } = useGetAllTopicsQuery();
  const { data: res_get, isLoading: isLoadingActivityType, error: errorActivityType } = useGetActivityTypeQuery(selectedActivityType?.id);
  const [updateActivityType, responseUpdate,] = useUpdateActivityTypeMutation();
  const [addActivityType, responseAdd,] = useAddActivityTypeMutation();
  const bluePrint = BLUE_PRINTS.activityType;
  const [activityTypeState, setActivityTypeState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
    //this code is use to control infinity loops and backend required error. 
    const {error,resetCount}= useResponseError(responseAdd,responseUpdate);
  // const [error, setError] = useState([]);
  // const [count, setCount] = useState(true);

  // const countZero = () => {
  //   setCount(true)
  // }
  // console.log(responseUpdate?.error?.data?.errors)
  // if (responseAdd?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseAdd?.error?.data?.errors)
  // }

  // if (responseUpdate?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseUpdate?.error?.data?.errors)
  // }


  useEffect(() => {
    setActivityTypeState(selectedActivityType ? res_get?.data?.activity_type : bluePrint);
    // activityTypeState && activityTypeState.map((data) => console.log('mapping', data.id))
    console.log('activityType', activityTypeState);
  }, [res_get])



  return (
    <div>
      {showBackDrop && <SimpleBackdrop responseAdd={responseAdd} responseUpdate={responseUpdate} />}
      <Snackbar responseAdd={responseAdd?.isSuccess === true ? responseAdd : responseUpdate} />

      {activityTypeState ?
        <div style={{ marginTop: 25, display: 'flex', flexDirection: 'column', gap: 25 }}>
          {/* <LanguageSwitcher /> */}

          <DZTextValidation error={error?.["0"]} label="Name" variant="outlined" value={activityTypeState?.name[selectedLanguage]}
            onChange={(e) => { setActivityTypeState(x => ({ ...x, name: { ...x.name, [selectedLanguage]: e.target.value } })) }}
          />
          <DZTextField label="Icon" variant="outlined" value={activityTypeState?.icon}
            onChange={(e) => { setActivityTypeState(x => ({ ...x, icon: e.target.value })) }}

          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }} >
            <Button variant="outlined" onClick={() => { onClose() }}>Cancel</Button>
            {
              selectedActivityType ? <Button variant="contained" onClick={() => { updateActivityType(activityTypeState); setShowBackDrop(true); resetCount() }}>Update</Button>
                : <Button variant="contained" onClick={() => { addActivityType(activityTypeState); setShowBackDrop(true); resetCount() }}>Add</Button>
            }

          </div>
          {/* dispatch(setSelectedActivityType(null)); */}
          {/* <div style={{ width: '10%' }}>{JSON.stringify(activityTypeState)}</div> */}
        </div>
        : <div>Loading....</div>
      }
      {/* {!selectedActivityType &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}


    </div>
  )
}

export default AddUpdateActivityType