import LanguageSwitcher from '@jumbo/dz-components/buttons/LanguageSwitcher';
import { selectedCompanySelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { BLUE_PRINTS, useGetCompanyQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Company from './../company/Company';
import { Button } from '@mui/material';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import { DZText } from 'app/DZcomponents/DZText';
import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
import { Config } from 'constant';

const ShowCompany = ({ company, selectId }) => {

    const { data: res_get, isLoading: isLoadingCompany, error: errorCompany } = useGetCompanyQuery(company ?? selectId);
    const selectedLanguage = useSelector(selectedLanguageSelector);
    const [selectedFile, setSelectedFile] = useState(res_get?.data?.company?.image);
    useEffect(() => {
        res_get?.data?.company?.image && setSelectedFile(res_get?.data?.company?.image)
    
    }, [res_get])
    const companyArray =
        [
            { label: 'Company Name', data: res_get?.data?.company?.name },
            { label: 'Welcome Title', data: res_get?.data?.company?.welcome_title[selectedLanguage] },
            { label: 'Welcome Detail', data: res_get?.data?.company?.welcome_detail[selectedLanguage] },
        ]

    return (
        <div>
            {res_get?.data && <DZGenericView obj={companyArray} imagePath={Config.AWSCompanyLogoImg} image={selectedFile} checkImage={true} closeBtn={true} />}
        </div>
    )
}

export default ShowCompany